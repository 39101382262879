var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { ApiResourceName } from '@shared/types/api/api';
import ContractCard from '@backoffice/components/contract/ContractCard.vue';
import ModelInfo from '@backoffice/components/claim/ModelInfo.vue';
import { userFields } from '@shared/user/userFields';
import { ModelFieldsProjection } from '@shared/modelField/modelField';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { fetchResource, fetchResourceCollection } from '@backoffice/utils/api';
const claim = namespace('claim');
let User = class User extends Vue {
    get title() {
        return `${this.user.firstname} ${this.user.lastname}`;
    }
    get modelFieldsContext() {
        return this.modelFieldsContextGetter(this.$i18n.tc.bind(this.$i18n), false);
    }
    get userFieldsData() {
        var _a;
        const context = {
            edition: false,
            projection: ModelFieldsProjection.Backoffice,
            translate: this.$tc.bind(this),
            type: ClaimTypeTrigram.Empty,
        };
        return userFields((_a = this.user) !== null && _a !== void 0 ? _a : {}, context);
    }
    cancel() {
        this.$router.push(this.localePath({ name: 'utilisateurs' }));
    }
    createContract() {
        this.$router.push(this.localePath({
            name: 'utilisateurs-id-nouveau-contrat',
            params: { id: this.$route.params.id },
        }));
    }
};
__decorate([
    claim.Getter('modelFieldsContext')
], User.prototype, "modelFieldsContextGetter", void 0);
User = __decorate([
    Component({
        middleware: 'hasAccess',
        components: {
            ModelInfo,
            ContractCard,
        },
        async asyncData({ params, $axios }) {
            const userRequest = fetchResource(ApiResourceName.Users, params.id, $axios).then(({ data }) => data);
            const contractsRequest = fetchResourceCollection(ApiResourceName.Contracts, $axios, { user: params.id }).then(({ data }) => data);
            const [user, contracts] = await Promise.all([userRequest, contractsRequest]);
            return { user, contracts };
        },
    })
], User);
export default User;
