var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import PdfPreview from '@declaration/components/PdfPreview.vue';
import { fileObjectUrl } from '@shared/files/fileObjectUrl';
import { isImageFile, isPdfFile } from '@shared/files/mimeTypes';
let FileViewer = class FileViewer extends Vue {
    constructor() {
        super(...arguments);
        this.isDownloading = false;
        this.objectUrl = null;
    }
    mounted() {
        this.$watch('file', async (file) => {
            if (!file || !file.url) {
                this.objectUrl = null;
                return;
            }
            if (file.url.startsWith('data:')) {
                this.objectUrl = file.url;
                return;
            }
            this.objectUrl = await fileObjectUrl(file.url, this.$axios);
        }, { immediate: true });
    }
    get isImage() {
        if (!this.file) {
            return false;
        }
        return isImageFile(this.file);
    }
    get isPdf() {
        if (!this.file) {
            return false;
        }
        return isPdfFile(this.file);
    }
};
FileViewer = __decorate([
    Component({
        components: {
            PdfPreview,
        },
        props: {
            file: {
                type: Object,
                default: null,
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
        },
    })
], FileViewer);
export default FileViewer;
