var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ModelInfo from '@backoffice/components/claim/ModelInfo.vue';
const claim = namespace('claim');
let EditableModelInfo = class EditableModelInfo extends Vue {
    get modelFieldsContext() {
        return this.modelFieldsContextGetter(this.$i18n.tc.bind(this.$i18n), this.isEditingClaim);
    }
};
__decorate([
    Prop({ type: Object, default: null })
], EditableModelInfo.prototype, "modelFields", void 0);
__decorate([
    claim.State('isEditingClaim')
], EditableModelInfo.prototype, "isEditingClaim", void 0);
__decorate([
    claim.State('isUpdatingDraft')
], EditableModelInfo.prototype, "isUpdatingDraft", void 0);
__decorate([
    claim.Getter('modelFieldsContext')
], EditableModelInfo.prototype, "modelFieldsContextGetter", void 0);
EditableModelInfo = __decorate([
    Component({ components: { ModelInfo } })
], EditableModelInfo);
export default EditableModelInfo;
