var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import moment from 'moment';
import ExpertAppointmentTable from '@backoffice/components/claim/ManagementBar/ExpertAppointmentTable.vue';
import { ExitTypes, ExpertAppointmentMode, EXPERT_APPOINTMENT_TRAD_KEYS, } from '@shared/types/api/claim';
const expertAppointment = namespace('expertAppointment');
const claim = namespace('claim');
let ExpertAppointmentForm = class ExpertAppointmentForm extends Vue {
    constructor() {
        super(...arguments);
        this.selectedExit = null;
        this.showDays = 5;
    }
    get title() {
        switch (this.selectedExit) {
            case ExitTypes.OnSiteAppointment:
                return 'RDV Expertise sur site';
            case ExitTypes.VisioDistanceAppointment:
                return 'RDV Expertise en visioconférence';
            case ExitTypes.PhoneDistanceAppointment:
                return 'RDV Expertise téléphonique';
            default:
                return 'RDV Expertise';
        }
    }
    get appointmentMode() {
        switch (this.selectedExit) {
            case ExitTypes.OnSiteAppointment:
                return ExpertAppointmentMode.OnSite;
            case ExitTypes.PhoneDistanceAppointment:
                return ExpertAppointmentMode.Distance;
            case ExitTypes.VisioDistanceAppointment:
                return ExpertAppointmentMode.Visio;
            default:
                return null;
        }
    }
    set appointmentMode(mode) {
        switch (mode) {
            case ExpertAppointmentMode.OnSite:
                this.selectedExit = ExitTypes.OnSiteAppointment;
                break;
            case ExpertAppointmentMode.Distance:
                this.selectedExit = ExitTypes.PhoneDistanceAppointment;
                break;
            case ExpertAppointmentMode.Visio:
                this.selectedExit = ExitTypes.VisioDistanceAppointment;
                break;
            default:
                this.selectedExit = null;
                break;
        }
        this.fetchAppointments();
    }
    get phoneNumber() {
        return this.payload.userContact.phoneNumber;
    }
    set phoneNumber(phoneNumber) {
        this.updatePayload({ userContact: { phoneNumber } });
    }
    get email() {
        return this.payload.userContact.email;
    }
    set email(email) {
        this.updatePayload({ userContact: { email } });
    }
    get addressDetails() {
        return this.payload.userContact.addressDetails;
    }
    set addressDetails(addressDetails) {
        this.updatePayload({ userContact: { addressDetails } });
    }
    get appointmentModeChoices() {
        return Object.entries(EXPERT_APPOINTMENT_TRAD_KEYS).map(([value, tradKey]) => {
            return {
                value,
                label: this.$tc(tradKey),
                icon: {
                    [ExpertAppointmentMode.OnSite]: 'home',
                    [ExpertAppointmentMode.Distance]: 'phone',
                    [ExpertAppointmentMode.Visio]: 'laptop-account',
                }[value],
            };
        });
    }
    get isLoading() {
        return this.isFindingAppointments || this.isConfirmingAppointment || this.isClaimUpdating;
    }
    get isValid() {
        return this.validation.isValid;
    }
    get canShowMoreAppointments() {
        return this.showDays < this.sortedAppointments.length;
    }
    get expertAppointment() {
        var _a;
        return (_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.expertAppointment;
    }
    get appointmentDate() {
        var _a;
        return moment((_a = this.expertAppointment) === null || _a === void 0 ? void 0 : _a.startDateTime).format('LLLL');
    }
    get appointmentCompany() {
        var _a;
        return (_a = this.expertAppointment) === null || _a === void 0 ? void 0 : _a.expertSociety.name;
    }
    mounted() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        this.resetState();
        if (this.initialClaim) {
            this.updatePayload({
                userContact: {
                    phoneNumber: (_f = (_c = (_b = (_a = this.initialClaim.contract) === null || _a === void 0 ? void 0 : _a.contact.landlinePhone) === null || _b === void 0 ? void 0 : _b.trim()) !== null && _c !== void 0 ? _c : (_e = (_d = this.initialClaim.contract) === null || _d === void 0 ? void 0 : _d.contact.mobilePhone) === null || _e === void 0 ? void 0 : _e.trim()) !== null && _f !== void 0 ? _f : null,
                    email: (_k = (_j = (_h = (_g = this.initialClaim.contract) === null || _g === void 0 ? void 0 : _g.contact) === null || _h === void 0 ? void 0 : _h.email) === null || _j === void 0 ? void 0 : _j.trim()) !== null && _k !== void 0 ? _k : null,
                    addressDetails: [
                        (_o = (_m = (_l = this.initialClaim.contract) === null || _l === void 0 ? void 0 : _l.contact) === null || _m === void 0 ? void 0 : _m.address) === null || _o === void 0 ? void 0 : _o.trim(),
                        (_r = (_q = (_p = this.initialClaim.contract) === null || _p === void 0 ? void 0 : _p.contact) === null || _q === void 0 ? void 0 : _q.addressAffix) === null || _r === void 0 ? void 0 : _r.trim(),
                        (_u = (_t = (_s = this.initialClaim.contract) === null || _s === void 0 ? void 0 : _s.contact) === null || _t === void 0 ? void 0 : _t.zipcode) === null || _u === void 0 ? void 0 : _u.trim(),
                        (_x = (_w = (_v = this.initialClaim.contract) === null || _v === void 0 ? void 0 : _v.contact) === null || _w === void 0 ? void 0 : _w.city) === null || _x === void 0 ? void 0 : _x.trim(),
                    ]
                        .filter(Boolean)
                        .join(', '),
                },
            });
            if (this.initialClaim.forcedExpertise) {
                this.selectedExit = this.initialClaim.forcedExpertise;
            }
            else if (this.initialClaim.exit &&
                [
                    ExitTypes.PhoneDistanceAppointment,
                    ExitTypes.VisioDistanceAppointment,
                    ExitTypes.OnSiteAppointment,
                ].includes(this.initialClaim.exit)) {
                this.selectedExit = this.initialClaim.exit;
            }
        }
        if (this.selectedExit) {
            this.fetchAppointments();
        }
    }
    showMoreAppointments() {
        var _a, _b;
        this.showDays += 2;
        if (this.showDays > ((_a = this.sortedAppointments.length) !== null && _a !== void 0 ? _a : 0)) {
            this.showDays = (_b = this.sortedAppointments.length) !== null && _b !== void 0 ? _b : 0;
        }
    }
    revealValidation() {
        this.validatePayload();
    }
    async validate() {
        var _a, _b;
        this.addDraftChanges({ forcedExpertise: (_a = this.selectedExit) !== null && _a !== void 0 ? _a : undefined });
        await this.confirmAppointment((_b = this.selectedExit) !== null && _b !== void 0 ? _b : undefined);
        await this.saveChanges();
    }
    async fetchAppointments() {
        this.showDays = 5;
        if (this.selectedExit) {
            await this.fetchExpertAppointments(this.selectedExit);
            if (this.appointmentFindError !== null)
                return this.noTimeSlot();
            const appointmentFindResult = this.appointmentFindResult;
            if (appointmentFindResult === null || appointmentFindResult === void 0 ? void 0 : appointmentFindResult.appointments) {
                if (Object.keys(appointmentFindResult.appointments).length === 0) {
                    await this.fetchExpertAppointments(this.selectedExit);
                    if (Object.keys(appointmentFindResult.appointments).length === 0)
                        this.noTimeSlot();
                }
            }
        }
    }
    async noTimeSlot(noSuitableExpertTimeSlotReason) {
        try {
            const payload = {
                forcedExpertise: this.selectedExit,
                noSuitableExpertTimeSlot: true,
            };
            if (noSuitableExpertTimeSlotReason) {
                payload.noSuitableExpertTimeSlotReason = noSuitableExpertTimeSlotReason;
            }
            this.addDraftChanges(payload);
            await this.saveChanges();
            this.$emit('close');
        }
        catch (err) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
    }
};
__decorate([
    claim.State('initialClaim')
], ExpertAppointmentForm.prototype, "initialClaim", void 0);
__decorate([
    claim.Action('addDraftChanges')
], ExpertAppointmentForm.prototype, "addDraftChanges", void 0);
__decorate([
    claim.Action('saveChanges')
], ExpertAppointmentForm.prototype, "saveChanges", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], ExpertAppointmentForm.prototype, "isClaimUpdating", void 0);
__decorate([
    expertAppointment.State('isFindingAppointments')
], ExpertAppointmentForm.prototype, "isFindingAppointments", void 0);
__decorate([
    expertAppointment.State('isConfirmingAppointment')
], ExpertAppointmentForm.prototype, "isConfirmingAppointment", void 0);
__decorate([
    expertAppointment.State('appointmentFindResult')
], ExpertAppointmentForm.prototype, "appointmentFindResult", void 0);
__decorate([
    expertAppointment.State('appointmentFindError')
], ExpertAppointmentForm.prototype, "appointmentFindError", void 0);
__decorate([
    expertAppointment.State('payload')
], ExpertAppointmentForm.prototype, "payload", void 0);
__decorate([
    expertAppointment.Getter('validation')
], ExpertAppointmentForm.prototype, "validation", void 0);
__decorate([
    expertAppointment.Getter('sortedAppointments')
], ExpertAppointmentForm.prototype, "sortedAppointments", void 0);
__decorate([
    expertAppointment.Action('fetchExpertAppointments')
], ExpertAppointmentForm.prototype, "fetchExpertAppointments", void 0);
__decorate([
    expertAppointment.Action('updatePayload')
], ExpertAppointmentForm.prototype, "updatePayload", void 0);
__decorate([
    expertAppointment.Action('validatePayload')
], ExpertAppointmentForm.prototype, "validatePayload", void 0);
__decorate([
    expertAppointment.Action('confirmAppointment')
], ExpertAppointmentForm.prototype, "confirmAppointment", void 0);
__decorate([
    expertAppointment.Action('resetState')
], ExpertAppointmentForm.prototype, "resetState", void 0);
ExpertAppointmentForm = __decorate([
    Component({ components: { ExpertAppointmentTable } })
], ExpertAppointmentForm);
export default ExpertAppointmentForm;
