var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import Cookies from 'js-cookie';
import { namespace } from 'vuex-class';
import TimeLineItem from '@backoffice/components/claim/TimeLineItem.vue';
import { getTimeLineItems } from '@backoffice/timeline/timeLineItems';
const claim = namespace('claim');
let TimeLine = class TimeLine extends Vue {
    constructor() {
        super(...arguments);
        this.filters = [];
    }
    get claim() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim) !== null && _b !== void 0 ? _b : null;
    }
    get timeLineItems() {
        const items = getTimeLineItems(this.claim);
        // Separate 'creation' from the items
        const creationItem = items.find((item) => item.type === 'creation');
        const itemsWithoutCreation = items.filter((item) => item.type !== 'creation');
        // Include 'creation' in the filtered items
        const filteredItems = this.filters.length === 0
            ? itemsWithoutCreation
            : itemsWithoutCreation.filter((item) => {
                if (item.type === 'update' && item.data.step) {
                    return this.filters.includes('step');
                }
                return this.filters.includes(item.type);
            });
        // Always include 'creation' at the bottom of the final timeline
        const finalTimeline = [...filteredItems];
        if (creationItem) {
            finalTimeline.push(creationItem);
        }
        return finalTimeline;
    }
    saveFiltersToCookie() {
        Cookies.set('timeline_filters', JSON.stringify(this.filters), { expires: 365 });
    }
    loadFiltersFromCookie() {
        const savedFilters = Cookies.get('timeline_filters');
        if (savedFilters) {
            this.filters = JSON.parse(savedFilters);
        }
    }
    created() {
        this.loadFiltersFromCookie();
    }
    beforeDestroy() {
        this.saveFiltersToCookie();
    }
};
__decorate([
    claim.State('info')
], TimeLine.prototype, "info", void 0);
TimeLine = __decorate([
    Component({ components: { TimeLineItem } })
], TimeLine);
export default TimeLine;
