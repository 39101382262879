var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import FilePreview from '@backoffice/components/file/FilePreview.vue';
import { fetchFile } from '@shared/files/fetchFile';
import { isFileModelField } from '@shared/modelField/modelField';
import { EMPTY } from '@shared/utils/strings';
let ModelFile = class ModelFile extends Vue {
    constructor() {
        super(...arguments);
        this.file = null;
        this.empty = EMPTY;
    }
    mounted() {
        this.$watch('field', async (field) => {
            if (!isFileModelField(field)) {
                return;
            }
            const file = field.displayedValue.file;
            if (!file) {
                this.file = null;
                return;
            }
            if (typeof file === 'string') {
                this.file = await fetchFile(file, this.$axios);
                return;
            }
            if (!file.id) {
                this.file = null;
            }
            this.file = file;
        }, { immediate: true });
    }
};
ModelFile = __decorate([
    Component({
        components: {
            FilePreview,
        },
        props: {
            field: {
                type: Object,
                required: true,
            },
            canEdit: { type: Boolean, default: false },
        },
    })
], ModelFile);
export default ModelFile;
