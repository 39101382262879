var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { SELFCARE_ENDED_STATUSES } from '@backoffice/claim/status';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const claim = namespace('claim');
let SetManagementButton = class SetManagementButton extends Vue {
    get isLoading() {
        return this.isInfoLoading || this.isClaimUpdating;
    }
    get hasManagement() {
        var _a;
        return ((_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.hasManagement) === true;
    }
    get text() {
        var _a;
        return ((_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.hasManagement) === true ? 'Dossier en gestion' : 'Reprise en gestion';
    }
    confirmSetManagement() {
        this.$buefy.dialog.confirm({
            animation: 'fade',
            title: 'Confirmation',
            type: 'is-danger',
            hasIcon: true,
            message: `Cette action est définitive, êtes-vous sûr de la reprise en gestion du dossier ?`,
            cancelText: 'Annuler',
            confirmText: 'Confirmer',
            onConfirm: () => this.setManagement(),
        });
    }
    async setManagement() {
        var _a;
        if ((_a = this.info) === null || _a === void 0 ? void 0 : _a.claim) {
            this.addDraftChanges({ hasManagement: true });
            await this.saveChanges();
        }
    }
    get isDisabledBySelfCareEnded() {
        var _a;
        const hasFeature = insurerHasFeature(InsurerFeatureFlag.SelfCareEndedDisableManagementButton);
        if (((_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.status) &&
            Object.values(SELFCARE_ENDED_STATUSES).includes(this.info.claim.status) &&
            hasFeature) {
            return true;
        }
        return false;
    }
};
__decorate([
    claim.State('info')
], SetManagementButton.prototype, "info", void 0);
__decorate([
    claim.State('isInfoLoading')
], SetManagementButton.prototype, "isInfoLoading", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], SetManagementButton.prototype, "isClaimUpdating", void 0);
__decorate([
    claim.Action('addDraftChanges')
], SetManagementButton.prototype, "addDraftChanges", void 0);
__decorate([
    claim.Action('saveChanges')
], SetManagementButton.prototype, "saveChanges", void 0);
SetManagementButton = __decorate([
    Component
], SetManagementButton);
export default SetManagementButton;
