var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
let ClaimIndicator = class ClaimIndicator extends Vue {
};
__decorate([
    Prop({ type: String, required: true })
], ClaimIndicator.prototype, "status", void 0);
__decorate([
    Prop({ type: String, required: true })
], ClaimIndicator.prototype, "icon", void 0);
__decorate([
    Prop({ type: String, required: true })
], ClaimIndicator.prototype, "title", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ClaimIndicator.prototype, "disabled", void 0);
__decorate([
    Prop({ type: String, default: null })
], ClaimIndicator.prototype, "ariaLabel", void 0);
ClaimIndicator = __decorate([
    Component
], ClaimIndicator);
export default ClaimIndicator;
