var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Subject } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { uuidFromIri } from '@shared/utils/iri';
import formatAmount from '@shared/utils/formatAmount';
import { EMPTY } from '@shared/utils/strings';
const claim = namespace('claim');
const SEARCH_QUERY_MIN_LENGTH = 3;
let UpptecModelField = class UpptecModelField extends Vue {
    constructor() {
        super(...arguments);
        this.modalOpen = false;
        this.searchQuery = '';
        this.searching = false;
        this.loadingProduct = false;
        this.queryInput$ = new Subject();
        this.query$ = new Subject();
        this.subscriptions = [];
        this.searchResults = [];
        this.suggestions = [];
        this.searchQueryDone = false;
        this.selectedResult = null;
    }
    get displayMissingProduct() {
        return (this.searchQueryDone && this.searchQuery.length >= SEARCH_QUERY_MIN_LENGTH && !this.searching);
    }
    get hasThirdPartyProduct() {
        return !!this.modelFields.model.thirdPartyProductId;
    }
    get upptecModalOpen() {
        return this.modalOpen;
    }
    set upptecModalOpen(upptecModalOpen) {
        this.modalOpen = upptecModalOpen;
        if (!upptecModalOpen) {
            this.searchQuery = '';
            this.selectedResult = null;
            this.suggestions = [];
            this.searchResults = [];
            this.searchQueryDone = false;
            this.clearSubscribers();
        }
        else {
            this.mountSubscribers();
        }
    }
    mounted() {
        this.mountSubscribers();
    }
    destroy() {
        this.clearSubscribers();
    }
    mountSubscribers() {
        if (!this.searchQuery) {
            this.searchResults = [];
        }
        this.$watch('searchQuery', (searchQuery) => {
            this.onQueryInput(searchQuery);
        });
        this.subscriptions.push(this.queryInput$
            .pipe(filter((input) => input.length >= SEARCH_QUERY_MIN_LENGTH), debounceTime(500))
            .subscribe((query) => this.query$.next(query)));
        this.subscriptions.push(this.query$.pipe(switchMap((query) => this.search(query))).subscribe((result) => {
            if (result === null) {
                this.suggestions = [];
                this.searchResults = [];
                return;
            }
            const { suggestions, results } = result;
            this.suggestions = suggestions;
            this.searchResults = results;
            this.searching = false;
        }));
        if (this.searchQuery.length >= SEARCH_QUERY_MIN_LENGTH) {
            this.onQueryInput(this.searchQuery);
        }
    }
    clearSubscribers() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
    onQueryInput(query) {
        this.queryInput$.next(query);
    }
    searchSuggestion(suggestion) {
        this.searchQuery = suggestion;
    }
    async search(query) {
        var _a;
        this.searching = true;
        try {
            let params = { q: query };
            if ((_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.id) {
                params = {
                    ...params,
                    c: uuidFromIri(this.info.claim.id),
                };
            }
            const result = await this.$axios.$get('/products/search', { params });
            this.searchQueryDone = true;
            return result;
        }
        catch (err) {
            this.missingProduct();
            this.searching = false;
            return null;
        }
    }
    selectProduct(product) {
        this.selectedResult = product;
    }
    async validateProduct() {
        var _a;
        if (!((_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.id) || this.selectedResult === null) {
            return;
        }
        if (this.selectedResult === false) {
            return this.missingProduct();
        }
        this.loadingProduct = true;
        try {
            const createdProduct = await this.$axios.$post(`/products/create-for-claim/${uuidFromIri(this.info.claim.id)}`, {
                productId: this.selectedResult.productId,
                groupId: this.selectedResult.groupId,
            });
            this.$emit('product-selected', { product: this.selectedResult, createdProduct });
            this.loadingProduct = false;
            this.upptecModalOpen = false;
        }
        catch (err) {
            this.loadingProduct = false;
            this.missingProduct();
        }
    }
    manualInput() {
        this.$emit('manual-input', this.searchQuery);
    }
    missingProduct() {
        this.$emit('missing-product', this.searchQuery);
        this.upptecModalOpen = false;
    }
    openUpptecModal(searchQuery) {
        if (searchQuery)
            this.searchQuery = searchQuery;
        this.upptecModalOpen = true;
    }
};
__decorate([
    claim.State('info')
], UpptecModelField.prototype, "info", void 0);
UpptecModelField = __decorate([
    Component({
        props: {
            field: {
                type: Object,
            },
            modelFields: {
                type: Object,
                required: true,
            },
        },
        filters: {
            amountFilter(val) {
                return val ? formatAmount(val) : EMPTY;
            },
        },
    })
], UpptecModelField);
export default UpptecModelField;
