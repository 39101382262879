var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let ForcableAmountValue = class ForcableAmountValue extends Vue {
    get baseValue() {
        return this.value.baseValue;
    }
    get forcedValue() {
        return this.value.forcedValue;
    }
    get computedValue() {
        return this.value.computedValue;
    }
    get finalValue() {
        var _a, _b;
        return this.showForced
            ? (_b = (_a = this.forcedValue) !== null && _a !== void 0 ? _a : this.computedValue) !== null && _b !== void 0 ? _b : this.baseValue
            : this.baseValue;
    }
    get classList() {
        const list = [];
        if (this.baseValue !== null && this.forcedValue !== null) {
            list.push('forcable-amount-value--forced');
            if (this.forcedValue > this.baseValue) {
                if (this.colorize === 'gt')
                    list.push('forcable-amount-value--forced--up');
                else if (this.colorize === 'lt')
                    list.push('forcable-amount-value--forced--down');
            }
            else if (this.forcedValue < this.baseValue) {
                if (this.colorize === 'gt')
                    list.push('forcable-amount-value--forced--down');
                else if (this.colorize === 'lt')
                    list.push('forcable-amount-value--forced--up');
            }
        }
        return list;
    }
};
ForcableAmountValue = __decorate([
    Component({
        props: {
            component: {
                type: String,
                default: 'span',
            },
            value: {
                type: Object,
                required: true,
            },
            showForced: {
                type: Boolean,
                default: false,
            },
            colorize: {
                type: String,
                required: false,
            },
            showPrecision: {
                type: Boolean,
                default: false,
            },
        },
    })
], ForcableAmountValue);
export default ForcableAmountValue;
