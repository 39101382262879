var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { EntityChangeType } from '@backoffice/claim/edition/entitiesChanges';
import EditableModelInfo from '@backoffice/components/claim/EditableModelInfo.vue';
import ModelInfo from '@backoffice/components/claim/ModelInfo.vue';
import ApplicableContractConditions from '@backoffice/components/contract/ApplicableContractConditions.vue';
import { PERSONAL_PROPERTY_CATEGORY_XML_LABEL_TRAD_KEYS } from '@backoffice/constants/contractConstants';
import { contractContactFields, contractGeneralFields, contractRiskFields, contractSpecialConditionsFields, } from '@shared/contract/contractFields';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
import { LayoutElementStatus } from '@shared/utils/layout';
const claim = namespace('claim');
let ClaimContract = class ClaimContract extends Vue {
    constructor() {
        super(...arguments);
        this.LayoutElementStatus = LayoutElementStatus;
        this.EntityChangeType = EntityChangeType;
        this.displayApplicableContractCondition = false;
        this.subscribedCapitalsColumns = [
            { field: 'insuranceContractXmlLabel', label: 'Catégorie dans le contrat' },
            { field: 'amount', label: 'Capital souscrit' },
        ];
    }
    get id() {
        return this.$route.params.id;
    }
    get caseNumber() {
        return this.info ? this.info.claim.caseNumber : '';
    }
    get canSave() {
        return !this.isClaimUpdating && this.hasPendingChanges;
    }
    get canCancel() {
        return !this.isClaimUpdating;
    }
    get subscribedCapitalsTable() {
        var _a;
        if (!((_a = this.contract) === null || _a === void 0 ? void 0 : _a.subscribedCapitals)) {
            return [];
        }
        return this.contract.subscribedCapitals.map((subscribedCapital) => {
            let insuranceContractXmlLabel = subscribedCapital.insuranceContractXmlLabel;
            if (subscribedCapital.insuranceContractXmlLabel in
                PERSONAL_PROPERTY_CATEGORY_XML_LABEL_TRAD_KEYS) {
                const tradKey = PERSONAL_PROPERTY_CATEGORY_XML_LABEL_TRAD_KEYS[subscribedCapital.insuranceContractXmlLabel];
                if (this.$te(tradKey))
                    insuranceContractXmlLabel = this.$tc(tradKey);
            }
            return {
                insuranceContractXmlLabel,
                amount: formatOptionalCentsAmount(subscribedCapital.amount),
            };
        });
    }
    get modelFieldsContext() {
        return this.modelFieldsContextGetter(this.$tc.bind(this), false);
    }
    get generalFields() {
        var _a, _b;
        const context = this.modelFieldsContext;
        if (!context) {
            return null;
        }
        return contractGeneralFields((_a = this.contract) !== null && _a !== void 0 ? _a : {}, context, undefined, (_b = this.info) === null || _b === void 0 ? void 0 : _b.claim.insurer);
    }
    get specialConditionsFields() {
        var _a;
        const context = this.modelFieldsContext;
        if (!context) {
            return null;
        }
        return contractSpecialConditionsFields((_a = this.contract) !== null && _a !== void 0 ? _a : {}, context);
    }
    get riskFields() {
        var _a;
        const context = this.modelFieldsContext;
        if (!context) {
            return null;
        }
        return contractRiskFields((_a = this.contract) !== null && _a !== void 0 ? _a : {}, context);
    }
    get contactFields() {
        var _a, _b;
        const context = this.modelFieldsContext;
        if (!context) {
            return null;
        }
        return contractContactFields((_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.contract) !== null && _b !== void 0 ? _b : {}, context);
    }
    async onContactUpdate(update) {
        var _a, _b;
        await this.updateDraftPayload({
            contract: {
                ...update.payload,
                id: (_b = (_a = this.contract) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
            },
        });
    }
};
__decorate([
    claim.State('info')
], ClaimContract.prototype, "info", void 0);
__decorate([
    claim.State('contract')
], ClaimContract.prototype, "contract", void 0);
__decorate([
    claim.State('isContractLoading')
], ClaimContract.prototype, "isContractLoading", void 0);
__decorate([
    claim.State('isEditingClaim')
], ClaimContract.prototype, "isEditingClaim", void 0);
__decorate([
    claim.State('isInfoLoading')
], ClaimContract.prototype, "isInfoLoading", void 0);
__decorate([
    claim.State('error')
], ClaimContract.prototype, "error", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], ClaimContract.prototype, "isClaimUpdating", void 0);
__decorate([
    claim.Getter('modelFieldsContext')
], ClaimContract.prototype, "modelFieldsContextGetter", void 0);
__decorate([
    claim.State('hasPendingChanges')
], ClaimContract.prototype, "hasPendingChanges", void 0);
__decorate([
    claim.Action('edit')
], ClaimContract.prototype, "edit", void 0);
__decorate([
    claim.Action('cancelEdition')
], ClaimContract.prototype, "cancelEdition", void 0);
__decorate([
    claim.Action('saveChanges')
], ClaimContract.prototype, "saveChanges", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], ClaimContract.prototype, "updateDraftPayload", void 0);
ClaimContract = __decorate([
    Component({
        middleware: 'hasAccess',
        components: {
            EditableModelInfo,
            ApplicableContractConditions,
            ModelInfo,
        },
        nuxtI18n: {
            paths: {
                en: '/claims/:id/contract',
            },
        },
    })
], ClaimContract);
export default ClaimContract;
