var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ModelForm from '@backoffice/components/claim/ModelForm.vue';
import ModelFormModal from '@backoffice/components/claim/ModelFormModal.vue';
import ModelInfo from '@backoffice/components/claim/ModelInfo.vue';
import PersonalPropertyThirdPartyProductCandidates from '@backoffice/components/claim/PersonalProperty/PersonalPropertyThirdPartyProductCandidates.vue';
import { SurfaceType } from '@shared/types/api/roomDamage';
import { ClaimFileType } from '@shared/types/file';
import { LayoutElementStatus } from '@shared/utils/layout';
const claim = namespace('claim');
let ModelInfoAndForm = class ModelInfoAndForm extends Vue {
    constructor() {
        super(...arguments);
        this.closeSubscription = null;
        this.isEditing = false;
        this.LayoutElementStatus = LayoutElementStatus;
        this.ClaimFileType = ClaimFileType;
        this.SurfaceType = SurfaceType;
        this.roomDamage = null;
        // Can be used to re-render child components
        // on validation state change
        this.validationKey = 0;
        this.isValid = false;
        this.isTouched = false;
        this.fieldErrors = () => [];
    }
    created() {
        this.$watch('close', (close$) => {
            if (!close$) {
                if (this.closeSubscription === null) {
                    return;
                }
                this.closeSubscription.unsubscribe();
                this.closeSubscription = null;
                return;
            }
            this.closeSubscription = close$.subscribe(() => (this.isEditing = false));
        }, { immediate: true });
    }
    get title() {
        return this.infoModelFields.title;
    }
    get isInEdition() {
        return this.isEditing && this.isEditingClaim;
    }
    get model() {
        return this.getEntity(this.changeType)(this.changeKey);
    }
    get thirdPartyProductInfo() {
        const model = this.infoModelFields.model;
        if ('thirdPartyProductInfo' in model) {
            return model.thirdPartyProductInfo;
        }
    }
    cancelEdit() {
        this.isEditing = false;
        this.$emit('cancel');
    }
    edit() {
        this.$emit('edit');
        this.isEditing = true;
    }
    confirmDeletion() {
        this.$buefy.dialog.confirm({
            title: 'Supprimer',
            message: 'Veuillez confirmer la suppression.',
            onConfirm: () => {
                if (!this.model) {
                    return;
                }
                this.$emit('delete');
            },
            confirmText: 'Supprimer',
            cancelText: 'Annuler',
        });
    }
    fieldType(field) {
        return this.fieldErrors(field).length === 0 ? '' : 'is-danger';
    }
    revealAndValidate() {
        this.validation.revealViolations();
    }
};
__decorate([
    claim.State('info')
], ModelInfoAndForm.prototype, "info", void 0);
__decorate([
    claim.State('entitiesChanges')
], ModelInfoAndForm.prototype, "entitiesChanges", void 0);
__decorate([
    claim.State('isEditingClaim')
], ModelInfoAndForm.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], ModelInfoAndForm.prototype, "isClaimUpdating", void 0);
__decorate([
    claim.Getter('validationConstraintsContext')
], ModelInfoAndForm.prototype, "validationConstraintsContext", void 0);
__decorate([
    claim.State('isInfoLoading')
], ModelInfoAndForm.prototype, "isInfoLoading", void 0);
__decorate([
    claim.State('error')
], ModelInfoAndForm.prototype, "error", void 0);
__decorate([
    claim.Getter('getEntity')
], ModelInfoAndForm.prototype, "getEntity", void 0);
ModelInfoAndForm = __decorate([
    Component({
        components: {
            ModelForm,
            ModelInfo,
            ModelFormModal,
            PersonalPropertyThirdPartyProductCandidates,
        },
        props: {
            changeKey: {
                type: [String, Number],
                required: true,
            },
            changeType: {
                type: String,
                required: true,
            },
            infoModelFields: {
                type: Object,
                required: true,
            },
            formModelFields: {
                type: Object,
                required: true,
            },
            close: {
                type: Object,
                default: null,
            },
            context: {
                type: Object,
                required: true,
            },
            validation: {
                type: Object,
                required: true,
            },
            isDeleting: {
                type: Boolean,
                required: true,
            },
        },
    })
], ModelInfoAndForm);
export default ModelInfoAndForm;
