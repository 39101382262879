var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { disableBodyScrolling, enableBodyScrolling } from '@shared/utils/scroll';
let Index = class Index extends Vue {
    constructor() {
        super(...arguments);
        this.scrollToTopSubscription = null;
        this.toggleBodyScrollingOnDisplayed = false;
        this.disabledBodyScrolling = false;
    }
    created() {
        this.$watch('isDisplayed', (displayed) => {
            if (!this.toggleBodyScrollingOnDisplayed) {
                return;
            }
            if (displayed) {
                this.disabledBodyScrolling = true;
                return disableBodyScrolling();
            }
            enableBodyScrolling();
        }, { immediate: true });
        this.$watch('scrollToTop', (scrollToTop$) => {
            if (!scrollToTop$) {
                if (this.scrollToTopSubscription === null) {
                    return;
                }
                this.scrollToTopSubscription.unsubscribe();
                this.scrollToTopSubscription = null;
                return;
            }
            this.scrollToTopSubscription = scrollToTop$.subscribe(() => this.executeScrollToTop());
        }, { immediate: true });
    }
    destroyed() {
        if (this.disabledBodyScrolling) {
            enableBodyScrolling();
        }
    }
    close() {
        this.$emit('close', false);
        this.$nextTick(this.executeScrollToTop);
    }
    executeScrollToTop() {
        const container = this.$refs.content;
        if (!container) {
            return;
        }
        container.scrollTop = 0;
    }
};
Index = __decorate([
    Component({
        components: {},
        props: {
            isDisplayed: { type: Boolean, required: true },
            title: {
                type: String,
                default: null,
            },
            scrollToTop: {
                type: Object,
                default: null,
            },
        },
        model: {
            prop: 'isDisplayed',
            event: 'close',
        },
    })
], Index);
export default Index;
