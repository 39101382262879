var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { waterDamageQualificationFields } from '@shared/qualification/waterDamageQualificationFields';
import EditableModelInfo from '@backoffice/components/claim/EditableModelInfo.vue';
const claim = namespace('claim');
let WaterDamageQualification = class WaterDamageQualification extends Vue {
    get context() {
        return this.modelFieldsContextGetter(this.$tc.bind(this), this.isEditingClaim);
    }
    get qualification() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.waterDamageQualification) !== null && _b !== void 0 ? _b : {};
    }
    get modelFields() {
        if (!this.context)
            return null;
        return waterDamageQualificationFields(this.qualification, this.context);
    }
    async onSubmit(update) {
        var _a;
        await this.updateDraftPayload({
            waterDamageQualification: {
                ...update.payload,
                id: (_a = this.qualification.id) !== null && _a !== void 0 ? _a : null,
            },
        });
    }
};
__decorate([
    claim.State('info')
], WaterDamageQualification.prototype, "info", void 0);
__decorate([
    claim.State('isEditingClaim')
], WaterDamageQualification.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Getter('modelFieldsContext')
], WaterDamageQualification.prototype, "modelFieldsContextGetter", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], WaterDamageQualification.prototype, "updateDraftPayload", void 0);
WaterDamageQualification = __decorate([
    Component({ components: { EditableModelInfo } })
], WaterDamageQualification);
export default WaterDamageQualification;
