var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { CLOSING_REASONS_LABELS } from '@backoffice/claim/claimClosingReasons';
import CloseClaimForm from '@backoffice/components/claim/ManagementBar/CloseClaimForm.vue';
const claim = namespace('claim');
let CloseClaimButton = class CloseClaimButton extends Vue {
    get closingReason() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.closingReason) !== null && _b !== void 0 ? _b : null;
    }
    get isClaimOpen() {
        return this.closingReason === null;
    }
    get label() {
        return this.isClaimOpen
            ? this.$tc('close_claim.button.close')
            : this.$tc('close_claim.button.reopen');
    }
    get icon() {
        return this.isClaimOpen ? 'lock' : 'lock-open';
    }
    get disabled() {
        return typeof this.provisionAmount !== 'number' || this.provisionAmount > 0;
    }
    handleClick() {
        if (this.isClaimOpen)
            this.confirmCloseClaim();
        else
            this.confirmOpenClaim();
    }
    confirmCloseClaim() {
        var _a;
        this.$buefy.modal.open({
            parent: this,
            component: CloseClaimForm,
            animation: 'fade',
            hasModalCard: true,
            props: {
                caseNumber: (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.caseNumber,
                onConfirm: async (reason) => {
                    await this.closeClaim(reason);
                    this.notifyCloseClaim(reason);
                },
            },
        });
    }
    notifyCloseClaim(reason) {
        this.$buefy.snackbar.open({
            duration: 5000,
            type: 'is-danger',
            position: 'is-top',
            message: `Dossier clos : ${this.$tc(CLOSING_REASONS_LABELS[reason])}`,
            actionText: 'Annuler',
            onAction: async () => {
                await this.openClaim();
                this.$buefy.toast.open('Cloture du dossier annulée');
            },
        });
    }
    confirmOpenClaim() {
        this.$buefy.dialog.confirm({
            animation: 'fade',
            message: `Vous êtes sur le point de rouvrir un dossier clos, êtes-vous sûr de cette action ?`,
            cancelText: 'Annuler',
            confirmText: 'Confirmer',
            type: 'is-info',
            hasIcon: true,
            onConfirm: async () => {
                const reason = this.closingReason;
                await this.openClaim();
                if (reason)
                    this.notifyOpenClaim(reason);
            },
        });
    }
    notifyOpenClaim(reason) {
        this.$buefy.snackbar.open({
            duration: 5000,
            type: 'is-danger',
            position: 'is-top',
            message: 'Dossier rouvert',
            actionText: 'Annuler',
            onAction: async () => {
                await this.closeClaim(reason);
                this.$buefy.toast.open('Rouverture du dossier annulée');
            },
        });
    }
    async closeClaim(closingReason) {
        this.addDraftChanges({ closingReason });
        await this.saveChanges();
    }
    async openClaim() {
        this.addDraftChanges({ closingReason: null });
        await this.saveChanges();
    }
};
__decorate([
    claim.State('info')
], CloseClaimButton.prototype, "info", void 0);
__decorate([
    claim.Getter('provisionAmount')
], CloseClaimButton.prototype, "provisionAmount", void 0);
__decorate([
    claim.Action('addDraftChanges')
], CloseClaimButton.prototype, "addDraftChanges", void 0);
__decorate([
    claim.Action('saveChanges')
], CloseClaimButton.prototype, "saveChanges", void 0);
CloseClaimButton = __decorate([
    Component
], CloseClaimButton);
export default CloseClaimButton;
