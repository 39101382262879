var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import FileCollectionForm from '@backoffice/components/file/FileCollectionForm.vue';
import { ClaimFileType } from '@shared/types/file';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
const claim = namespace('claim');
let ClaimGED = class ClaimGED extends Vue {
    constructor() {
        super(...arguments);
        this.gedClaimFileType = ClaimFileType.GEDFile;
    }
    get gedFileCollection() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.gedFiles) !== null && _b !== void 0 ? _b : emptyUploadedFileCollection();
    }
    onUploadedAppFileCollectionUpdate(gedFiles) {
        var _a;
        this.updateDraftPayload({
            id: (_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.id,
            gedFiles,
        });
    }
};
__decorate([
    claim.State('info')
], ClaimGED.prototype, "info", void 0);
__decorate([
    claim.State('initialClaim')
], ClaimGED.prototype, "initialClaim", void 0);
__decorate([
    claim.State('isEditingClaim')
], ClaimGED.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], ClaimGED.prototype, "updateDraftPayload", void 0);
ClaimGED = __decorate([
    Component({
        middleware: 'hasAccess',
        components: { FileCollectionForm },
        nuxtI18n: {
            paths: {
                en: '/claims/:id/dms',
            },
        },
    })
], ClaimGED);
export default ClaimGED;
