var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import PersonalPropertyCatalogItem from '@backoffice/components/claim/PersonalProperty/PersonalPropertyCatalogItem.vue';
let PersonalPropertyThirdPartyProductCandidates = class PersonalPropertyThirdPartyProductCandidates extends Vue {
    constructor() {
        super(...arguments);
        this.isExpanded = false;
    }
    get candidates() {
        return this.productInfo.candidates.filter((candidate) => { var _a; return ((_a = this.productInfo.bestAlternative) === null || _a === void 0 ? void 0 : _a.name) !== candidate.name; });
    }
    get expandedSymbol() {
        return this.isExpanded ? '▲' : '▼';
    }
    get isEmpty() {
        return this.candidates.length === 0;
    }
    get title() {
        if (this.isEmpty) {
            return 'Aucun produit équivalent trouvé dans le catalogue';
        }
        if (this.candidates.length === 1) {
            return `1 produit équivalent`;
        }
        return `${this.candidates.length} produits équivalents`;
    }
    get toggleText() {
        return this.isExpanded ? 'Masquer' : 'Afficher';
    }
    toggle() {
        if (this.isEmpty) {
            return;
        }
        if (this.isExpanded) {
            return this.shrink();
        }
        this.expand();
    }
    expand() {
        this.isExpanded = true;
    }
    shrink() {
        this.isExpanded = false;
    }
};
PersonalPropertyThirdPartyProductCandidates = __decorate([
    Component({
        components: { PersonalPropertyCatalogItem },
        props: {
            productInfo: {
                type: Object,
                required: true,
            },
        },
    })
], PersonalPropertyThirdPartyProductCandidates);
export default PersonalPropertyThirdPartyProductCandidates;
