var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ModelForm from '@backoffice/components/claim/ModelForm.vue';
import ModelInfo from '@backoffice/components/claim/ModelInfo.vue';
import Modal from '@backoffice/components/layout/Modal.vue';
import { SurfaceType } from '@shared/types/api/roomDamage';
import { ClaimFileType } from '@shared/types/file';
import { LayoutElementStatus } from '@shared/utils/layout';
const claim = namespace('claim');
let ModelFormModal = class ModelFormModal extends Vue {
    constructor() {
        super(...arguments);
        this.isEditing = false;
        this.LayoutElementStatus = LayoutElementStatus;
        this.ClaimFileType = ClaimFileType;
        this.SurfaceType = SurfaceType;
        this.roomDamage = null;
        this.updatedModel = null;
        // Can be used to re-render child components
        // on validation state change
        this.validationKey = 0;
        this.isValid = false;
        this.isTouched = false;
        this.fieldErrors = () => [];
    }
    get title() {
        return this.modelFields.title;
    }
    get isSavingButtonDisabled() {
        return !this.updatedModel || !this.validation.isValid;
    }
    revealAndValidate() {
        var _a;
        if (!this.validationConstraintsContext) {
            return;
        }
        const entity = this.modelFields.model;
        const constraintsFactory = (_a = this.modelFields.editable) === null || _a === void 0 ? void 0 : _a.constraints;
        const revealPayload = {
            entity,
            constraints: constraintsFactory
                ? constraintsFactory(entity, this.validationConstraintsContext)
                : {},
        };
        this.$emit('reveal', revealPayload);
    }
    onUpdate(update) {
        this.updatedModel = update.entity;
        this.$emit('update', update);
    }
    onSave() {
        // No-op if no changes
        if (!this.updatedModel) {
            this.$emit('close');
            return;
        }
        this.$emit('save', this.updatedModel);
    }
};
__decorate([
    claim.State('info')
], ModelFormModal.prototype, "info", void 0);
__decorate([
    claim.State('isEditingClaim')
], ModelFormModal.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], ModelFormModal.prototype, "isClaimUpdating", void 0);
__decorate([
    claim.Getter('validationConstraintsContext')
], ModelFormModal.prototype, "validationConstraintsContext", void 0);
__decorate([
    claim.State('isInfoLoading')
], ModelFormModal.prototype, "isInfoLoading", void 0);
__decorate([
    claim.State('error')
], ModelFormModal.prototype, "error", void 0);
ModelFormModal = __decorate([
    Component({
        components: { ModelForm, ModelInfo, Modal },
        props: {
            modelFields: {
                type: Object,
                required: true,
            },
            context: {
                type: Object,
                required: true,
            },
            validation: {
                type: Object,
                required: true,
            },
            isDisplayed: {
                type: Boolean,
                required: true,
            },
        },
    })
], ModelFormModal);
export default ModelFormModal;
