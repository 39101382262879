var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
const expertAppointment = namespace('expertAppointment');
let ExpertAppointmentTable = class ExpertAppointmentTable extends Vue {
    get selectedTimeSlot() {
        return this.payload.selectedTimeSlot;
    }
    get appointments() {
        return this.sortedAppointments.slice(0, this.showDays);
    }
    get maxLength() {
        return this.sortedAppointments.reduce((max, [_date, appointments]) => {
            return appointments.length > max ? appointments.length : max;
        }, 0);
    }
};
__decorate([
    expertAppointment.Getter('sortedAppointments')
], ExpertAppointmentTable.prototype, "sortedAppointments", void 0);
__decorate([
    expertAppointment.State('payload')
], ExpertAppointmentTable.prototype, "payload", void 0);
__decorate([
    expertAppointment.Action('updatePayload')
], ExpertAppointmentTable.prototype, "updatePayload", void 0);
ExpertAppointmentTable = __decorate([
    Component({
        props: {
            appointmentMode: {
                type: String,
                default: null,
            },
            showDays: {
                type: Number,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: true,
            },
        },
    })
], ExpertAppointmentTable);
export default ExpertAppointmentTable;
