var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { uuidFromIri } from '@shared/utils/iri';
const templates = namespace('templates');
let ConfigurationTemplatesPage = class ConfigurationTemplatesPage extends Vue {
    constructor() {
        super(...arguments);
        this.isSearchable = false;
        this.renderedTemplate = '';
    }
    onPageChange(page) {
        this.changePage(page);
    }
    onSort(by, direction) {
        this.sort({ by, direction });
    }
    onClick(template) {
        const uuid = uuidFromIri(template.id);
        this.$goToLocalePath({
            name: 'configuration-templates-id',
            params: { id: uuid },
        });
    }
    createTemplate() {
        this.$goToLocalePath({ name: 'configuration-templates-nouvelle' });
    }
};
__decorate([
    templates.State('templates')
], ConfigurationTemplatesPage.prototype, "templates", void 0);
__decorate([
    templates.State('loading')
], ConfigurationTemplatesPage.prototype, "loading", void 0);
__decorate([
    templates.State('total')
], ConfigurationTemplatesPage.prototype, "total", void 0);
__decorate([
    templates.State('page')
], ConfigurationTemplatesPage.prototype, "page", void 0);
__decorate([
    templates.State('sorting')
], ConfigurationTemplatesPage.prototype, "sorting", void 0);
__decorate([
    templates.Getter('perPage')
], ConfigurationTemplatesPage.prototype, "perPage", void 0);
__decorate([
    templates.Action('loadTemplates')
], ConfigurationTemplatesPage.prototype, "loadTemplates", void 0);
__decorate([
    templates.Action('changePage')
], ConfigurationTemplatesPage.prototype, "changePage", void 0);
__decorate([
    templates.Action('sort')
], ConfigurationTemplatesPage.prototype, "sort", void 0);
ConfigurationTemplatesPage = __decorate([
    Component({
        middleware: 'hasAccess',
        async fetch() {
            await this.loadTemplates();
        },
        async activated() {
            await this.loadTemplates();
        },
    })
], ConfigurationTemplatesPage);
export default ConfigurationTemplatesPage;
