var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ExpertAppointmentForm from '@backoffice/components/claim/ManagementBar/ExpertAppointmentForm.vue';
const claim = namespace('claim');
let ExpertAppointmentButton = class ExpertAppointmentButton extends Vue {
    get expertAppointment() {
        var _a;
        return (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.expertAppointment;
    }
    get isLoading() {
        return this.isInfoLoading || this.isClaimUpdating;
    }
    get isDisabled() {
        return !!this.info && !!this.expertAppointment;
    }
    confirmExpertAppointment() {
        this.$buefy.modal.open({
            parent: this,
            component: ExpertAppointmentForm,
            animation: 'fade',
            hasModalCard: true,
        });
    }
};
__decorate([
    claim.State('info')
], ExpertAppointmentButton.prototype, "info", void 0);
__decorate([
    claim.State('isInfoLoading')
], ExpertAppointmentButton.prototype, "isInfoLoading", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], ExpertAppointmentButton.prototype, "isClaimUpdating", void 0);
ExpertAppointmentButton = __decorate([
    Component
], ExpertAppointmentButton);
export default ExpertAppointmentButton;
