var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { electricalDamageQualificationFields } from '@shared/qualification/electricalDamageQualificationFields';
import { optionalCategoryIri, optionalResourceIri } from '@shared/utils/iri';
import EditableModelInfo from '@backoffice/components/claim/EditableModelInfo.vue';
const claim = namespace('claim');
let ElectricalDamageQualification = class ElectricalDamageQualification extends Vue {
    get context() {
        return this.modelFieldsContextGetter(this.$tc.bind(this), this.isEditingClaim);
    }
    get qualification() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.electricalDamageQualification) !== null && _b !== void 0 ? _b : {};
    }
    get modelFields() {
        if (!this.context)
            return null;
        return electricalDamageQualificationFields(this.qualification, this.context);
    }
    async onSubmit(update) {
        var _a, _b, _c, _d, _e, _f;
        const counting = { ...(_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting };
        const electricalDamageQualification = {
            ...update.payload,
            id: (_b = this.qualification.id) !== null && _b !== void 0 ? _b : null,
        };
        if (electricalDamageQualification.hasRealEstateDamage === false) {
            counting.electricalDamages = [];
        }
        if (electricalDamageQualification.hasFoodDamage === false) {
            counting.personalProperties =
                (_d = (_c = counting.personalProperties) === null || _c === void 0 ? void 0 : _c.filter((pp) => {
                    var _a, _b;
                    return (optionalResourceIri(pp.category) !==
                        optionalCategoryIri('denrees-alimentaires', (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.personalPropertyCategoriesByIri) !== null && _b !== void 0 ? _b : {}));
                })) !== null && _d !== void 0 ? _d : [];
        }
        if (electricalDamageQualification.hasPersonalPropertyDamage === false) {
            counting.personalProperties =
                (_f = (_e = counting.personalProperties) === null || _e === void 0 ? void 0 : _e.filter((pp) => {
                    var _a, _b;
                    return optionalResourceIri(pp.category) ===
                        optionalCategoryIri('denrees-alimentaires', (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.personalPropertyCategoriesByIri) !== null && _b !== void 0 ? _b : {});
                })) !== null && _f !== void 0 ? _f : [];
        }
        await this.updateDraftPayload({
            electricalDamageQualification,
            counting,
        });
    }
};
__decorate([
    claim.State('info')
], ElectricalDamageQualification.prototype, "info", void 0);
__decorate([
    claim.State('isEditingClaim')
], ElectricalDamageQualification.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Getter('modelFieldsContext')
], ElectricalDamageQualification.prototype, "modelFieldsContextGetter", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], ElectricalDamageQualification.prototype, "updateDraftPayload", void 0);
ElectricalDamageQualification = __decorate([
    Component({ components: { EditableModelInfo } })
], ElectricalDamageQualification);
export default ElectricalDamageQualification;
