var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { CHARGE_TYPOLOGY_TRAD_KEYS } from '@shared/charges/chargeConstants';
let PendingChargesModal = class PendingChargesModal extends Vue {
    close() {
        this.$emit('close');
    }
    confirm() {
        this.$emit('confirm');
    }
};
PendingChargesModal = __decorate([
    Component({
        props: {
            pendingCharges: {
                type: Array,
                required: true,
            },
        },
        filters: {
            typology: (typology) => CHARGE_TYPOLOGY_TRAD_KEYS[typology],
        },
    })
], PendingChargesModal);
export default PendingChargesModal;
