var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { DamagesCompensationTable } from '@backoffice/components/claim/compensation/tables';
const claim = namespace('claim');
let ClaimCompensation = class ClaimCompensation extends Vue {
    damagesRows(sectionName) {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.compensationTable) === null || _b === void 0 ? void 0 : _b.sections[sectionName]) !== null && _c !== void 0 ? _c : {};
    }
};
__decorate([
    claim.State('info')
], ClaimCompensation.prototype, "info", void 0);
ClaimCompensation = __decorate([
    Component({
        middleware: 'hasAccess',
        components: {
            DamagesCompensationTable,
        },
        nuxtI18n: {
            paths: {
                en: '/claims/:id/compensation',
            },
        },
    })
], ClaimCompensation);
export default ClaimCompensation;
