var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import MenuForm from '@backoffice/components/menu/Form.vue';
import { UserRole, USER_ROLE_TRAD_KEYS } from '@shared/types/api/user';
import { userRoleToColorClass } from '@shared/user/userFields';
const settings = namespace('settings');
let ConfigurationMenuPage = class ConfigurationMenuPage extends Vue {
    constructor() {
        super(...arguments);
        this.draggable = false;
        this.draggedRow = null;
        this.draggedIndex = null;
        this.hoveredIndex = null;
    }
    addEntry() {
        this.$buefy.modal.open({
            component: MenuForm,
            parent: this,
            canCancel: false,
            hasModalCard: true,
        });
    }
    editEntry(entry) {
        this.$buefy.modal.open({
            component: MenuForm,
            parent: this,
            canCancel: false,
            hasModalCard: true,
            props: { initialData: entry },
        });
    }
    deleteEntry(entry) {
        this.$buefy.dialog.confirm({
            title: `Supprimer l'élément`,
            message: `Vous êtes sur le point de supprimer définitivement l'élement <b>${entry.label}</b> du menu.`,
            confirmText: 'Confirmer',
            cancelText: 'Annuler',
            onConfirm: () => this.deleteMenuEntry(entry.id),
        });
    }
    rowClass(_row, index) {
        return {
            'is-dragover-top': this.hoveredIndex === index && this.draggedIndex !== null && this.draggedIndex > index,
            'is-dragover-bottom': this.hoveredIndex === index && this.draggedIndex !== null && this.draggedIndex < index,
            'has-background-white-bis': this.draggedIndex === index,
        };
    }
    formatRole(role) {
        if (role === UserRole.BACKOFFICE)
            return 'Tout le monde';
        return this.$tc(USER_ROLE_TRAD_KEYS[role]);
    }
    formatActionTitle(action) {
        return /^https?/.test(action) ? 'Lien externe' : 'Page';
    }
    formatActionDescription(action) {
        return /^https?/.test(action)
            ? action
            : new URL(this.localePath({ name: action }), location.origin).href;
    }
    dragstart({ row, event, index }) {
        this.draggedRow = row;
        this.draggedIndex = index;
        if (event.dataTransfer)
            event.dataTransfer.effectAllowed = 'copy';
    }
    dragend() {
        this.draggedRow = null;
        this.draggedIndex = null;
        this.hoveredIndex = null;
        this.draggable = false;
    }
    dragover({ event, index }) {
        event.preventDefault();
        this.hoveredIndex = this.draggedIndex === index ? null : index;
    }
    dragleave({ event }) {
        event.preventDefault();
        this.hoveredIndex = null;
    }
    drop({ index }) {
        if (this.draggedIndex !== null && this.draggedIndex !== index) {
            this.sortMenuEntries({ from: this.draggedIndex, to: index });
        }
    }
};
__decorate([
    settings.State('menuEntries')
], ConfigurationMenuPage.prototype, "menuEntries", void 0);
__decorate([
    settings.Action('updateMenuEntry')
], ConfigurationMenuPage.prototype, "updateMenuEntry", void 0);
__decorate([
    settings.Action('sortMenuEntries')
], ConfigurationMenuPage.prototype, "sortMenuEntries", void 0);
__decorate([
    settings.Action('deleteMenuEntry')
], ConfigurationMenuPage.prototype, "deleteMenuEntry", void 0);
ConfigurationMenuPage = __decorate([
    Component({
        middleware: 'hasAccess',
        filters: { userRoleToColorClass },
        nuxtI18n: {
            paths: {
                fr: '/configuration/menu',
                en: '/configuration/menu',
            },
        },
    })
], ConfigurationMenuPage);
export default ConfigurationMenuPage;
