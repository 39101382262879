var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import FileCollectionForm from '@backoffice/components/file/FileCollectionForm.vue';
import { amountToCents, optionalCentsToAmount } from '@shared/utils/amountToCents';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
import { ClaimFileType } from '@shared/types/file';
const claim = namespace('claim');
let ClaimDeclaration = class ClaimDeclaration extends Vue {
    constructor() {
        super(...arguments);
        this.counterOfferAcceptedStatus = 'no';
        this.counterOfferAmount = '';
        this.counterOfferComment = '';
        this.counterOfferFileCollection = emptyUploadedFileCollection();
        this.counterOfferFileType = ClaimFileType.RENCounterOfferFile;
        this.submitError = null;
        // Will be set to false if needed when updated from initial claim
        this.counterOfferAlreadyAcceptedOrRefused = true;
    }
    get isValid() {
        return (this.counterOfferAcceptedStatus &&
            this.counterOfferAmount &&
            this.counterOfferComment &&
            !this.counterOfferAlreadyAcceptedOrRefused);
    }
    get canEdit() {
        return !this.counterOfferAlreadyAcceptedOrRefused;
    }
    created() {
        this.$watch('initialClaim', () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            this.counterOfferComment =
                (_c = (_b = (_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.certifiedArtisanMission) === null || _b === void 0 ? void 0 : _b.counterOfferComment) !== null && _c !== void 0 ? _c : '';
            this.counterOfferAmount =
                (_g = (_f = optionalCentsToAmount((_e = (_d = this.initialClaim) === null || _d === void 0 ? void 0 : _d.certifiedArtisanMission) === null || _e === void 0 ? void 0 : _e.counterOfferAmount)) === null || _f === void 0 ? void 0 : _f.toString()) !== null && _g !== void 0 ? _g : '';
            this.counterOfferFileCollection =
                (_k = (_j = (_h = this.initialClaim) === null || _h === void 0 ? void 0 : _h.certifiedArtisanMission) === null || _j === void 0 ? void 0 : _j.counterOfferFiles) !== null && _k !== void 0 ? _k : emptyUploadedFileCollection();
            switch ((_m = (_l = this.initialClaim) === null || _l === void 0 ? void 0 : _l.certifiedArtisanMission) === null || _m === void 0 ? void 0 : _m.counterOfferAccepted) {
                case true:
                    this.counterOfferAcceptedStatus = 'yes';
                    this.counterOfferAlreadyAcceptedOrRefused = true;
                    break;
                case false:
                    this.counterOfferAcceptedStatus = 'no';
                    this.counterOfferAlreadyAcceptedOrRefused = true;
                    break;
                default:
                    this.counterOfferAlreadyAcceptedOrRefused = false;
            }
        }, { immediate: true });
    }
    onFileCollectionUpdate(uploadedAppFileCollection) {
        this.counterOfferFileCollection = uploadedAppFileCollection;
    }
    counterOfferAcceptedParser(counterOfferAcceptedState) {
        return counterOfferAcceptedState === 'yes';
    }
    async onSubmit() {
        var _a, _b, _c, _d, _e, _f;
        if (!this.isValid || this.isClaimUpdating || this.counterOfferAlreadyAcceptedOrRefused) {
            return;
        }
        this.submitError = null;
        const counterOfferAccepted = this.counterOfferAcceptedParser(this.counterOfferAcceptedStatus);
        const counterOfferAmount = amountToCents(parseFloat((_a = this.counterOfferAmount) !== null && _a !== void 0 ? _a : ''));
        // Add form changes to payload that will be sent on save
        this.addDraftChanges({
            certifiedArtisanMission: {
                counterOfferAccepted,
                counterOfferAmount,
                counterOfferComment: this.counterOfferComment,
                counterOfferFiles: this.counterOfferFileCollection,
                id: (_d = (_c = (_b = this.initialClaim) === null || _b === void 0 ? void 0 : _b.certifiedArtisanMission) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : null,
                claim: (_f = (_e = this.initialClaim) === null || _e === void 0 ? void 0 : _e.id) !== null && _f !== void 0 ? _f : null,
            },
        });
        try {
            // Saving changes and updating store
            await this.saveChanges();
        }
        catch (error) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
    }
};
__decorate([
    claim.State('initialClaim')
], ClaimDeclaration.prototype, "initialClaim", void 0);
__decorate([
    claim.State('isInfoLoading')
], ClaimDeclaration.prototype, "isInfoLoading", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], ClaimDeclaration.prototype, "isClaimUpdating", void 0);
__decorate([
    claim.Action('addDraftChanges')
], ClaimDeclaration.prototype, "addDraftChanges", void 0);
__decorate([
    claim.Action('saveChanges')
], ClaimDeclaration.prototype, "saveChanges", void 0);
ClaimDeclaration = __decorate([
    Component({
        middleware: 'hasAccess',
        components: { FileCollectionForm },
        nuxtI18n: {
            paths: {
                en: '/claims/:id/rik',
            },
        },
    })
], ClaimDeclaration);
export default ClaimDeclaration;
