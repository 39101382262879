var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import UiIconSelector from '@backoffice/components/ui/IconSelector.vue';
import { translateChoices } from '@shared/choice';
import { UserRole, USER_ROLE_CHOICES } from '@shared/types/api/user';
const settings = namespace('settings');
const excludedRoles = [UserRole.USER, UserRole.BACKOFFICE];
const excludedPageNames = ['admin-menu', 'aide', 'index', 'informations', 'login'];
let MenuForm = class MenuForm extends Vue {
    constructor() {
        super(...arguments);
        this.label = '';
        this.icon = 'link';
        this.action = '';
        this.role = UserRole.BACKOFFICE;
        this.index = 0;
        this.hidden = false;
        this.loading = false;
        this.selectedAction = 'custom:url';
    }
    get isCustomAction() {
        return this.selectedAction.startsWith('custom:');
    }
    get isPublic() {
        return this.role === UserRole.BACKOFFICE;
    }
    set isPublic(isPublic) {
        if (isPublic)
            this.role = UserRole.BACKOFFICE;
        else if (this.role === UserRole.BACKOFFICE)
            this.role = UserRole.MANAGER;
    }
    get formTitle() {
        return this.initialData
            ? this.$tc('pages.menu_editing.item_form.title_editing', undefined, {
                initialData: this.initialData.label,
            })
            : this.$tc('pages.menu_editing.item_form.title_new_item');
    }
    get roleOptions() {
        return translateChoices(USER_ROLE_CHOICES, this.$i18n.tc.bind(this.$i18n)).filter(({ value }) => !excludedRoles.includes(value));
    }
    get actionOptions() {
        return this.$router
            .getRoutes()
            .filter((routeRecord) => routeRecord.path === this.localePath(routeRecord.path))
            .map((routeRecord) => this.localeRoute(routeRecord.path))
            .filter((route) => !!route && Object.keys(route.params).length === 0)
            .map((route) => ({
            path: this.localePath(route.path),
            name: this.getRouteBaseName(route),
        }))
            .filter((route) => {
            return !!route.name && !excludedPageNames.includes(route.name);
        })
            .sort((a, b) => a.path.localeCompare(b.path))
            .map(({ path, name }) => {
            return {
                value: name,
                label: path,
            };
        });
    }
    mounted() {
        if (this.initialData) {
            Object.entries(this.initialData).forEach(([key, value]) => {
                if (typeof value !== 'undefined')
                    this[key] = value;
            });
        }
        else {
            this.index = Math.max(...this.menuEntries.map((entry) => entry.index)) + 1;
        }
        this.selectedAction = this.actionOptions.map((option) => option.value).includes(this.action)
            ? this.action
            : 'custom:url';
    }
    cancel() {
        this.$emit('close');
    }
    async submit() {
        this.loading = true;
        const payload = {
            label: this.label,
            icon: this.icon,
            action: this.action,
            role: this.role,
            index: this.index,
            hidden: this.hidden,
        };
        if (this.initialData) {
            await this.updateMenuEntry({ id: this.initialData.id, ...payload });
        }
        else {
            await this.addMenuEntry(payload);
        }
        this.loading = false;
        this.$emit('close');
    }
};
__decorate([
    settings.State('menuEntries')
], MenuForm.prototype, "menuEntries", void 0);
__decorate([
    settings.Action('addMenuEntry')
], MenuForm.prototype, "addMenuEntry", void 0);
__decorate([
    settings.Action('updateMenuEntry')
], MenuForm.prototype, "updateMenuEntry", void 0);
MenuForm = __decorate([
    Component({
        name: 'MenuForm',
        props: {
            initialData: {
                type: Object,
                default: null,
            },
        },
        watch: {
            selectedAction(value) {
                this.action = value.startsWith('custom:') ? '' : value;
            },
        },
        components: {
            UiIconSelector,
        },
    })
], MenuForm);
export default MenuForm;
