var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { EntityChangeType, newEntityForChangeType } from '@backoffice/claim/edition/entitiesChanges';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { ClaimTypeTrigram, } from '@shared/types/api/claim';
import { LayoutElementStatus } from '@shared/utils/layout';
import { glassBreakageDamageGlassDamageReparationsFields } from '@shared/qualification/glassBreakageDamageQualificationFields';
import DroughtDamages from '@backoffice/components/claim/DroughtDamages.vue';
import ModelListAndEdition from '@backoffice/components/claim/ModelListAndEdition.vue';
import EditableModelInfo from '@backoffice/components/claim/EditableModelInfo.vue';
import { optionalCategoryIri, optionalResourceIri } from '@shared/utils/iri';
import { foodDamagesFields } from '@shared/personalProperty/personalPropertyFields';
const claim = namespace('claim');
let ClaimDamages = class ClaimDamages extends Vue {
    constructor() {
        super(...arguments);
        this.LayoutElementStatus = LayoutElementStatus;
        this.ClaimTypeTrigram = ClaimTypeTrigram;
        this.EntityChangeType = EntityChangeType;
    }
    get trigram() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.type) === null || _b === void 0 ? void 0 : _b.trigram) !== null && _c !== void 0 ? _c : ClaimTypeTrigram.Empty;
    }
    get modelFieldsContext() {
        return this.modelFieldsContextGetter(this.$tc.bind(this), this.isEditingClaim);
    }
    get glassBreakageDamageQualification() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.glassBreakageDamageQualification) !== null && _b !== void 0 ? _b : {};
    }
    get electricalDamageQualification() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.electricalDamageQualification) !== null && _b !== void 0 ? _b : {};
    }
    get foodDamage() {
        var _a, _b, _c, _d, _e;
        return ((_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting.personalProperties.find((pp) => {
            var _a, _b;
            return (optionalResourceIri(pp.category) ===
                optionalCategoryIri('denrees-alimentaires', (_b = (_a = this.modelFieldsContext) === null || _a === void 0 ? void 0 : _a.categories) !== null && _b !== void 0 ? _b : {}));
        })) !== null && _b !== void 0 ? _b : {
            ...newEntityForChangeType(EntityChangeType.PersonalProperty, (_c = this.info) === null || _c === void 0 ? void 0 : _c.claim),
            category: optionalCategoryIri('denrees-alimentaires', (_e = (_d = this.modelFieldsContext) === null || _d === void 0 ? void 0 : _d.categories) !== null && _e !== void 0 ? _e : {}),
        });
    }
    get shouldDisplayRoomDamages() {
        const hasFeature = claimTypeHasFeature(this.trigram, ClaimTypeFeatureFlag.RoomDamages);
        return hasFeature;
    }
    get shouldDisplayEquipments() {
        const hasFeature = claimTypeHasFeature(this.trigram, ClaimTypeFeatureFlag.Equipments);
        return hasFeature;
    }
    get shouldDisplayBreakInDamages() {
        const hasFeature = claimTypeHasFeature(this.trigram, ClaimTypeFeatureFlag.BreakInDamages);
        return hasFeature;
    }
    get shouldDisplayExternalDamages() {
        const hasFeature = claimTypeHasFeature(this.trigram, ClaimTypeFeatureFlag.ExternalDamages);
        return hasFeature;
    }
    get shouldDisplayGlassDamages() {
        const hasFeature = claimTypeHasFeature(this.trigram, ClaimTypeFeatureFlag.GlassDamages);
        return hasFeature;
    }
    get shouldDisplayElectricalDamages() {
        const hasFeature = claimTypeHasFeature(this.trigram, ClaimTypeFeatureFlag.ElectricalDamages);
        if (this.trigram === ClaimTypeTrigram.Electric) {
            return hasFeature && !!this.electricalDamageQualification.hasRealEstateDamage;
        }
        return hasFeature;
    }
    get shouldDisplayRealEstateDamages() {
        return (this.shouldDisplayRoomDamages ||
            this.shouldDisplayEquipments ||
            this.shouldDisplayBreakInDamages ||
            this.shouldDisplayExternalDamages ||
            this.shouldDisplayGlassDamages ||
            this.shouldDisplayElectricalDamages);
    }
    get shouldDisplayPersonalProperties() {
        const hasFeature = claimTypeHasFeature(this.trigram, ClaimTypeFeatureFlag.PersonalProperties);
        if (this.trigram === ClaimTypeTrigram.Electric) {
            return hasFeature && !!this.electricalDamageQualification.hasPersonalPropertyDamage;
        }
        return hasFeature;
    }
    get shouldDisplayFoodDamages() {
        const hasFeature = claimTypeHasFeature(this.trigram, ClaimTypeFeatureFlag.FoodDamages);
        if (this.trigram === ClaimTypeTrigram.Electric) {
            return hasFeature && !!this.electricalDamageQualification.hasFoodDamage;
        }
        return hasFeature;
    }
    get shouldDisplayPersonalPropertyDamages() {
        return this.shouldDisplayPersonalProperties || this.shouldDisplayFoodDamages;
    }
    get shouldDisplayGlassDamageReparations() {
        var _a;
        const hasFeature = claimTypeHasFeature(this.trigram, ClaimTypeFeatureFlag.GlassDamages);
        const hasDamages = !!((_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting.glassDamages.length);
        return hasFeature && hasDamages;
    }
    get glassDamageReparationsFields() {
        const context = this.modelFieldsContext;
        if (!context) {
            return null;
        }
        return glassBreakageDamageGlassDamageReparationsFields(this.glassBreakageDamageQualification, context);
    }
    get foodDamagesFields() {
        const context = this.modelFieldsContext;
        if (!context) {
            return null;
        }
        return foodDamagesFields(this.foodDamage, context);
    }
    async onGlassBreakageDamageGlassDamageReparationsUpdate(update) {
        var _a;
        await this.updateDraftPayload({
            glassBreakageDamageQualification: {
                ...update.payload,
                id: (_a = this.glassBreakageDamageQualification.id) !== null && _a !== void 0 ? _a : null,
            },
        });
    }
    async onFoodDamageUpdate(update) {
        var _a, _b, _c, _d, _e;
        const foodDamage = this.foodDamage;
        const personalProperties = (_c = (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting.personalProperties) === null || _b === void 0 ? void 0 : _b.slice()) !== null && _c !== void 0 ? _c : [];
        const index = personalProperties.findIndex((pp) => !!foodDamage.clientIdentifier && pp.clientIdentifier === foodDamage.clientIdentifier);
        const newFoodDamage = {
            clientIdentifier: foodDamage.clientIdentifier,
            category: foodDamage.category,
            ...update.payload,
        };
        if (index === -1)
            personalProperties.push(newFoodDamage);
        else
            personalProperties.splice(index, 1, newFoodDamage);
        await this.updateDraftPayload({
            counting: {
                id: (_e = (_d = this.info) === null || _d === void 0 ? void 0 : _d.claim.counting.id) !== null && _e !== void 0 ? _e : null,
                personalProperties,
            },
        });
    }
    personalPropertyFilter(e) {
        var _a, _b;
        return (optionalResourceIri(e.modelFields.model.category) !==
            optionalCategoryIri('denrees-alimentaires', (_b = (_a = this.modelFieldsContext) === null || _a === void 0 ? void 0 : _a.categories) !== null && _b !== void 0 ? _b : {}));
    }
};
__decorate([
    claim.State('info')
], ClaimDamages.prototype, "info", void 0);
__decorate([
    claim.State('isEditingClaim')
], ClaimDamages.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Getter('modelFieldsContext')
], ClaimDamages.prototype, "modelFieldsContextGetter", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], ClaimDamages.prototype, "updateDraftPayload", void 0);
ClaimDamages = __decorate([
    Component({
        middleware: 'hasAccess',
        components: {
            ModelListAndEdition,
            EditableModelInfo,
            DroughtDamages,
        },
        nuxtI18n: {
            paths: {
                en: '/claims/:id/damages',
            },
        },
    })
], ClaimDamages);
export default ClaimDamages;
