var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import EditClaimButton from '@backoffice/components/claim/ManagementBar/EditClaimButton.vue';
import ExpertAppointmentButton from '@backoffice/components/claim/ManagementBar/ExpertAppointmentButton.vue';
import SetManagementButton from '@backoffice/components/claim/ManagementBar/SetManagementButton.vue';
import ArtisanAppointmentButton from '@backoffice/components/claim/ManagementBar/ArtisanAppointmentButton.vue';
import CloseClaimButton from '@backoffice/components/claim/ManagementBar/CloseClaimButton.vue';
import { UserRole } from '@shared/types/api/user';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import CloseClaimManagementButton from '@backoffice/components/claim/ManagementBar/CloseClaimManagementButton.vue';
let ManagementBar = class ManagementBar extends Vue {
    get isManagerReadonly() {
        return this.$hasAccessLevel(UserRole.MANAGER_READONLY);
    }
    get canCloseFromCloseManagementButton() {
        return insurerHasFeature(InsurerFeatureFlag.CloseClaimDirectlyFromManagementBar);
    }
};
ManagementBar = __decorate([
    Component({
        components: {
            CloseClaimManagementButton,
            EditClaimButton,
            ExpertAppointmentButton,
            SetManagementButton,
            ArtisanAppointmentButton,
            CloseClaimButton,
        },
        props: {
            canEdit: {
                type: Boolean,
                default: true,
            },
        },
    })
], ManagementBar);
export default ManagementBar;
