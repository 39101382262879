import { render, staticRenderFns } from "./ModelFormField.vue?vue&type=template&id=3435a886"
import script from "./ModelFormField.vue?vue&type=script&lang=ts"
export * from "./ModelFormField.vue?vue&type=script&lang=ts"
import style0 from "./ModelFormField.vue?vue&type=style&index=0&id=3435a886&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports