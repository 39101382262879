var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import EditableModelInfo from '@backoffice/components/claim/EditableModelInfo.vue';
import { glassBreakageDamageOriginFields, glassBreakageDamageQualificationFields, } from '@shared/qualification/glassBreakageDamageQualificationFields';
const claim = namespace('claim');
let GlassBreakageDamageQualification = class GlassBreakageDamageQualification extends Vue {
    get modelFieldsContext() {
        return this.modelFieldsContextGetter(this.$tc.bind(this), this.isEditingClaim);
    }
    get glassBreakageDamageQualification() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.glassBreakageDamageQualification) !== null && _b !== void 0 ? _b : {};
    }
    get qualificationFields() {
        const context = this.modelFieldsContext;
        if (!context) {
            return null;
        }
        return glassBreakageDamageQualificationFields(this.glassBreakageDamageQualification, context);
    }
    get originFields() {
        const context = this.modelFieldsContext;
        if (!context) {
            return null;
        }
        return glassBreakageDamageOriginFields(this.glassBreakageDamageQualification, context);
    }
    get shouldDisplaySecurity() {
        return this.glassBreakageDamageQualification.hasRoomDamages;
    }
    async onSubmit(update) {
        var _a;
        await this.updateDraftPayload({
            glassBreakageDamageQualification: {
                ...update.payload,
                id: (_a = this.glassBreakageDamageQualification.id) !== null && _a !== void 0 ? _a : null,
            },
        });
    }
};
__decorate([
    claim.State('info')
], GlassBreakageDamageQualification.prototype, "info", void 0);
__decorate([
    claim.State('isEditingClaim')
], GlassBreakageDamageQualification.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Getter('modelFieldsContext')
], GlassBreakageDamageQualification.prototype, "modelFieldsContextGetter", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], GlassBreakageDamageQualification.prototype, "updateDraftPayload", void 0);
GlassBreakageDamageQualification = __decorate([
    Component({
        components: {
            EditableModelInfo,
        },
    })
], GlassBreakageDamageQualification);
export default GlassBreakageDamageQualification;
