var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { anomalyScoreStatus } from '@backoffice/anomaly/anomalyScore';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { LayoutElementStatus } from '@shared/utils/layout';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import ClaimAnomaly from '@backoffice/components/claim/ClaimAnomaly.vue';
import ClaimIndicator from '@backoffice/components/claim/ClaimIndicator.vue';
import SidePanel from '@backoffice/components/claim/SidePanel.vue';
import EditClaimButton from '@backoffice/components/claim/ManagementBar/EditClaimButton.vue';
import SetManagementButton from '@backoffice/components/claim/ManagementBar/SetManagementButton.vue';
import ManagementBar from '@backoffice/components/claim/ManagementBar/ManagementBar.vue';
import { DEFAULT_PAGES_SECURITY, validateRole } from '@backoffice/types/security';
import { INSURER_CONFIG } from '@shared/insurer';
import { UserRole } from '@shared/types/api/user';
import CloseClaimManagementButton from '@backoffice/components/claim/ManagementBar/CloseClaimManagementButton.vue';
const claim = namespace('claim');
const settings = namespace('settings');
var TABS;
(function (TABS) {
    TABS["SYNTHESIS"] = "synthese";
    TABS["CONTRACT"] = "contrat";
    TABS["CLAIM"] = "declaration";
    TABS["DAMAGES"] = "dommages";
    TABS["COMPENSATION"] = "indemnisation";
    TABS["MANAGEMENT_FEES"] = "frais-de-gestion";
    TABS["PROVISION"] = "provision";
    TABS["CALL"] = "appel";
    TABS["RIK"] = "ren";
    TABS["DMS"] = "ged";
    TABS["PRIMING"] = "priming";
})(TABS || (TABS = {}));
const NON_EDITABLE_TABS = [TABS.CALL, TABS.RIK];
// Used for layout consistency during loading
const LOADING_TABS = [
    {
        title: ' ',
        page: 'declarations-id-synthese',
        isCurrent: false,
        tab: TABS.SYNTHESIS,
        isEditable: false,
    },
];
const allTabs = Object.values(TABS);
const TABS_FOR_TYPE = {
    [ClaimTypeTrigram.Robbery]: allTabs,
    [ClaimTypeTrigram.Drought]: [TABS.SYNTHESIS, TABS.CONTRACT, TABS.CLAIM, TABS.DAMAGES],
    [ClaimTypeTrigram.Empty]: allTabs,
    [ClaimTypeTrigram.Disaster]: allTabs,
    [ClaimTypeTrigram.Electric]: allTabs,
    [ClaimTypeTrigram.Fire]: allTabs,
    [ClaimTypeTrigram.Glass]: allTabs,
    [ClaimTypeTrigram.StormSnowHail]: allTabs,
    [ClaimTypeTrigram.Vehicle]: allTabs,
    [ClaimTypeTrigram.WaterDamage]: allTabs,
    [ClaimTypeTrigram.Other]: allTabs,
};
let Claim = class Claim extends Vue {
    constructor() {
        super(...arguments);
        this.isAddingReceipt = false;
        this.unregisterNavigationGuard = null;
    }
    get tabs() {
        var _a, _b, _c;
        const trigram = (_c = (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim) === null || _b === void 0 ? void 0 : _b.type) === null || _c === void 0 ? void 0 : _c.trigram;
        if (!trigram)
            return LOADING_TABS;
        const currentPage = this.getRouteBaseName(this.$route);
        const tabs = TABS_FOR_TYPE[trigram].map((tab) => {
            const page = `declarations-id-${tab}`;
            return {
                tab,
                page,
                title: this.$tc(`pages.declarations_id.tabs.${tab}`),
                isCurrent: currentPage === page,
                isEditable: !NON_EDITABLE_TABS.includes(tab),
            };
        });
        return tabs.filter(({ isEditable, tab, page }) => {
            var _a, _b, _c, _d;
            if (this.isEditingClaim && !isEditable)
                return false;
            if (!this.claimHasRen && tab === TABS.RIK)
                return false;
            if (!this.claimHasPriming && tab === TABS.PRIMING)
                return false;
            const security = (_d = (_c = (_b = (_a = this.$config.insurerConfig.backoffice) === null || _a === void 0 ? void 0 : _a.security) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c[page]) !== null && _d !== void 0 ? _d : DEFAULT_PAGES_SECURITY[page];
            if (!this.$auth.loggedIn ||
                ![security].flat().some((role) => validateRole(this.$auth.user.roles, role)))
                return false;
            return true;
        });
    }
    get canCloseClaim() {
        return (this.$hasAccessLevel(UserRole.MANAGER_READONLY) &&
            insurerHasFeature(InsurerFeatureFlag.CloseClaimDirectlyFromManagementBar));
    }
    get currentTab() {
        return this.tabs.find((tab) => tab.isCurrent);
    }
    get claimHasRen() {
        var _a;
        return (_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.counting.hasCertifiedArtisanCompensationMode;
    }
    get claimHasPriming() {
        var _a, _b, _c, _d;
        return !!((_b = (_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.primings) === null || _b === void 0 ? void 0 : _b.length) || !!((_d = (_c = this.initialClaim) === null || _c === void 0 ? void 0 : _c.generaliPrimings) === null || _d === void 0 ? void 0 : _d.length);
    }
    get isOnEditableTab() {
        var _a;
        return (_a = this.currentTab) === null || _a === void 0 ? void 0 : _a.isEditable;
    }
    get id() {
        return this.$route.params.id;
    }
    get caseNumber() {
        return this.info ? this.info.claim.caseNumber : '';
    }
    get canSave() {
        return !this.isClaimUpdating && this.hasPendingChanges;
    }
    get canCancel() {
        return !this.isClaimUpdating;
    }
    get warningIndicatorInfo() {
        var _a;
        const claim = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim;
        const contract = this.contract;
        if (!claim || !contract) {
            return null;
        }
        const warnings = claim.claimWarnings
            .filter((warning) => warning.type === 'warning')
            .map(({ message, context }) => {
            if (this.$root.$te(message)) {
                return this.$root.$tc(message, undefined, context.tradKeyData);
            }
            return message;
        });
        if (warnings.length === 0) {
            return {
                status: LayoutElementStatus.Ok,
                disabled: true,
                title: '0',
                icon: 'alert',
                warnings: [],
            };
        }
        return {
            status: LayoutElementStatus.Warning,
            title: warnings.length.toString(),
            icon: 'alert',
            warnings,
        };
    }
    get anomalyIndicatorInfo() {
        var _a;
        const claim = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim;
        if (!claim) {
            return null;
        }
        const anomalyScore = claim.anomalyScore;
        const anomalyScoreThresholdSetting = this.setting('anomalyScoreThreshold');
        if (anomalyScoreThresholdSetting &&
            typeof anomalyScoreThresholdSetting === 'object' &&
            'value' in anomalyScoreThresholdSetting) {
            const anomalyScoreThreshold = anomalyScoreThresholdSetting.value;
            const status = anomalyScoreStatus(anomalyScore, anomalyScoreThreshold);
            return {
                status,
                title: `${anomalyScore}/${anomalyScoreThreshold}`,
                icon: 'shield-check',
                anomalies: claim.anomalyScoreDetails,
                disabled: claim.anomalyScoreDetails.length === 0,
            };
        }
        else {
            return null;
        }
    }
    get canManage() {
        return this.$hasAccessLevel(UserRole.MANAGER);
    }
    get hasManagementBar() {
        return insurerHasFeature.call(this, InsurerFeatureFlag.ManagementBarBackOffice);
    }
    mounted() {
        if (this.getRouteBaseName(this.$route) === 'declarations-id') {
            this.$goToLocalePath({
                name: 'declarations-id-synthese',
                params: this.$route.params,
                query: this.$route.query,
                hash: this.$route.hash,
            });
        }
        const unregister = this.$router.beforeEach((to, _from, next) => {
            if (to.path.startsWith(`/declarations/${this.id}`))
                return next();
            this.confirmLeaveIfPendingChanges().then((leave) => {
                if (leave)
                    next();
                else
                    next(false);
            });
        });
        this.unregisterNavigationGuard = () => {
            unregister();
        };
        this.$watch('hasPendingChanges', (hasPendingChanges) => {
            if (!window) {
                return;
            }
            if (hasPendingChanges) {
                window.onbeforeunload = () => {
                    return 'Les modifications que vous avez apportées ne seront pas enregistrées.';
                };
                return;
            }
            window.onbeforeunload = null;
        });
    }
    destroyed() {
        if (window.onbeforeunload) {
            window.onbeforeunload = null;
        }
        if (this.unregisterNavigationGuard) {
            this.unregisterNavigationGuard();
        }
    }
    async confirmLeaveIfPendingChanges() {
        if (!this.hasPendingChanges) {
            return true;
        }
        return await new Promise((resolve) => {
            this.$buefy.dialog.confirm({
                title: this.$tc('pages.declarations_id.exit.title'),
                message: this.$tc('pages.declarations_id.exit.message'),
                onCancel: () => resolve(false),
                onConfirm: () => resolve(true),
                type: 'is-danger',
                hasIcon: true,
                confirmText: this.$tc('pages.declarations_id.exit.confirmText'),
                cancelText: this.$tc('pages.declarations_id.exit.cancelText'),
            });
        });
    }
};
__decorate([
    claim.State('info')
], Claim.prototype, "info", void 0);
__decorate([
    claim.State('initialClaim')
], Claim.prototype, "initialClaim", void 0);
__decorate([
    claim.State('contract')
], Claim.prototype, "contract", void 0);
__decorate([
    claim.State('isContractLoading')
], Claim.prototype, "isContractLoading", void 0);
__decorate([
    claim.State('isEditingClaim')
], Claim.prototype, "isEditingClaim", void 0);
__decorate([
    claim.State('isInfoLoading')
], Claim.prototype, "isInfoLoading", void 0);
__decorate([
    claim.State('error')
], Claim.prototype, "error", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], Claim.prototype, "isClaimUpdating", void 0);
__decorate([
    claim.State('hasPendingChanges')
], Claim.prototype, "hasPendingChanges", void 0);
__decorate([
    claim.Action('selectClaim')
], Claim.prototype, "selectClaim", void 0);
__decorate([
    claim.Action('addDraftChanges')
], Claim.prototype, "addDraftChanges", void 0);
__decorate([
    claim.Action('edit')
], Claim.prototype, "edit", void 0);
__decorate([
    claim.Action('cancelEdition')
], Claim.prototype, "cancelEdition", void 0);
__decorate([
    claim.Action('saveChanges')
], Claim.prototype, "saveChanges", void 0);
__decorate([
    settings.Getter('get')
], Claim.prototype, "setting", void 0);
Claim = __decorate([
    Component({
        middleware: 'hasAccess',
        components: {
            CloseClaimManagementButton,
            ClaimIndicator,
            ClaimAnomaly,
            SidePanel,
            EditClaimButton,
            SetManagementButton,
            ManagementBar,
        },
        async asyncData({ route, store, error, localePath, $hasAccessLevel }) {
            var _a;
            const id = route.params.id;
            const edit = route.query.mode === 'edition' && $hasAccessLevel(UserRole.MANAGER);
            const identifier = (_a = INSURER_CONFIG.backoffice) === null || _a === void 0 ? void 0 : _a.claimUrlIdentifier;
            await store.dispatch('claim/selectClaim', { id, edit, identifier });
            if (store.state.claim.error) {
                error({
                    message: store.state.claim.error,
                    statusCode: 404,
                    path: localePath({ name: 'declarations' }),
                });
            }
        },
        nuxtI18n: {
            paths: {
                en: '/claims/:id',
            },
        },
    })
], Claim);
export default Claim;
