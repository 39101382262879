var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { compareOptionalResource } from '@shared/utils/iri';
let TimeLineItem = class TimeLineItem extends Vue {
    constructor() {
        super(...arguments);
        this.open = false;
    }
    get date() {
        return new Date(this.item.date);
    }
    get type() {
        return this.isStep ? 'step' : this.item.type;
    }
    get isStep() {
        return this.item.type === 'update' && 'step' in this.item.data;
    }
    get isInsured() {
        var _a;
        return compareOptionalResource(this.item.updatedBy, (_a = this.claim) === null || _a === void 0 ? void 0 : _a.policyholder);
    }
    get isCurrentUser() {
        return compareOptionalResource(this.item.updatedBy, this.$auth.user);
    }
    get title() {
        return this.$tc(`title.${this.type}`);
    }
    get subtitle() {
        if (this.isStep) {
            return this.updateData.step;
        }
        if (this.type === 'webhook' && typeof this.webhookData === 'object') {
            return this.webhookData.eventName;
        }
        if (this.type === 'message' && typeof this.messageData === 'object') {
            return this.messageData.objet;
        }
    }
    get creationData() {
        return this.item.data;
    }
    get updateData() {
        return this.item.data;
    }
    get callData() {
        return this.item.data;
    }
    get provisionData() {
        return this.item.data;
    }
    get webhookData() {
        return this.item.data;
    }
    get messageData() {
        return this.item.data;
    }
};
__decorate([
    Prop({ type: Object, required: true })
], TimeLineItem.prototype, "item", void 0);
__decorate([
    Prop({ type: Object, default: null })
], TimeLineItem.prototype, "claim", void 0);
TimeLineItem = __decorate([
    Component
], TimeLineItem);
export default TimeLineItem;
