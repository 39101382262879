var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EntityChangeType } from '@backoffice/claim/edition/entitiesChanges';
import ClaimTypeTile from '@backoffice/components/claim/ClaimTypeTile.vue';
import EditableModelInfo from '@backoffice/components/claim/EditableModelInfo.vue';
import ModelInfo from '@backoffice/components/claim/ModelInfo.vue';
import ModelListAndEdition from '@backoffice/components/claim/ModelListAndEdition.vue';
import DroughtDamageQualification from '@backoffice/components/qualification/DroughtDamageQualification.vue';
import ElectricalDamageQualification from '@backoffice/components/qualification/ElectricalDamageQualification.vue';
import RobberyDamageQualification from '@backoffice/components/qualification/RobberyDamageQualification.vue';
import StormSnowHailDamageQualification from '@backoffice/components/qualification/StormSnowHailDamageQualification.vue';
import WaterDamageQualification from '@backoffice/components/qualification/WaterDamageQualification.vue';
import GlassBreakageDamageQualification from '@backoffice/components/qualification/GlassBreakageDamageQualification.vue';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { otherGuaranteeFields, qualificationGeneralFields, } from '@shared/qualification/qualificationFields';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import FireDamageQualification from '@backoffice/components/qualification/FireDamageQualification.vue';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const claim = namespace('claim');
const QUALIFICATION_COMPONENT_FOR_TYPE = {
    [ClaimTypeTrigram.WaterDamage]: 'WaterDamageQualification',
    [ClaimTypeTrigram.Electric]: 'ElectricalDamageQualification',
    [ClaimTypeTrigram.Drought]: 'DroughtDamageQualification',
    [ClaimTypeTrigram.StormSnowHail]: 'StormSnowHailDamageQualification',
    [ClaimTypeTrigram.Robbery]: 'RobberyDamageQualification',
    [ClaimTypeTrigram.Glass]: 'GlassBreakageDamageQualification',
    [ClaimTypeTrigram.Fire]: 'FireDamageQualification',
};
let ClaimDeclaration = class ClaimDeclaration extends Vue {
    constructor() {
        super(...arguments);
        this.EntityChangeType = EntityChangeType;
        this.isSavingOtherGuaranteeComment = false;
        this.otherGuaranteeComment = '';
    }
    formatDate(value) {
        if (!value)
            return '-';
        const date = Date.parse(value);
        const dateTimeFormat = new Intl.DateTimeFormat(this.$i18n.localeProperties.iso, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        });
        return dateTimeFormat.format(date);
    }
    get qualificationComponent() {
        return QUALIFICATION_COMPONENT_FOR_TYPE[this.typeTrigram];
    }
    get shouldDisplayDocuments() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.Receipts);
    }
    get shouldDisplayThirdParties() {
        return !insurerHasFeature(InsurerFeatureFlag.BackofficeHideThirdParties, this.typeTrigram);
    }
    get shouldDisplayDetails() {
        return this.typeTrigram === ClaimTypeTrigram.Other;
    }
    get modelFieldsContext() {
        return this.modelFieldsContextGetter(this.$tc.bind(this), this.isEditingClaim);
    }
    get generalModelToFields() {
        return qualificationGeneralFields;
    }
    get generalFields() {
        var _a, _b;
        const context = this.modelFieldsContext;
        if (!context) {
            return null;
        }
        return qualificationGeneralFields((_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim) !== null && _b !== void 0 ? _b : {}, context);
    }
    get otherGuaranteeFields() {
        var _a, _b;
        const context = this.modelFieldsContext;
        if (!context) {
            return null;
        }
        return otherGuaranteeFields((_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim) !== null && _b !== void 0 ? _b : {}, context);
    }
    async onSubmit(payload) {
        await this.updateDraftPayload(payload);
    }
    get savedOtherGuaranteeComment() {
        var _a;
        if (!this.info) {
            return '';
        }
        return (_a = this.info.claim.otherGuaranteeComment) !== null && _a !== void 0 ? _a : '';
    }
    get otherGuaranteeCommentHasChanged() {
        return this.otherGuaranteeComment.trim() !== this.savedOtherGuaranteeComment.trim();
    }
    revertOtherGuaranteeComment() {
        if (!this.info) {
            return;
        }
        this.otherGuaranteeComment = this.info.claim.otherGuaranteeComment || '';
    }
    async saveCurrentOtherGuaranteeComment() {
        this.isSavingOtherGuaranteeComment = true;
        await this.saveOtherGuaranteeComment(this.otherGuaranteeComment);
        this.isSavingOtherGuaranteeComment = false;
    }
};
__decorate([
    claim.State('info')
], ClaimDeclaration.prototype, "info", void 0);
__decorate([
    claim.State('isEditingClaim')
], ClaimDeclaration.prototype, "isEditingClaim", void 0);
__decorate([
    claim.State('isUpdatingDraft')
], ClaimDeclaration.prototype, "isUpdatingDraft", void 0);
__decorate([
    claim.Getter('typeTrigram')
], ClaimDeclaration.prototype, "typeTrigram", void 0);
__decorate([
    claim.Getter('modelFieldsContext')
], ClaimDeclaration.prototype, "modelFieldsContextGetter", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], ClaimDeclaration.prototype, "updateDraftPayload", void 0);
__decorate([
    claim.Action('saveOtherGuaranteeComment')
], ClaimDeclaration.prototype, "saveOtherGuaranteeComment", void 0);
ClaimDeclaration = __decorate([
    Component({
        middleware: 'hasAccess',
        components: {
            EditableModelInfo,
            ClaimTypeTile,
            WaterDamageQualification,
            GlassBreakageDamageQualification,
            ElectricalDamageQualification,
            FireDamageQualification,
            DroughtDamageQualification,
            StormSnowHailDamageQualification,
            RobberyDamageQualification,
            ModelListAndEdition,
            ModelInfo,
        },
        nuxtI18n: {
            paths: {
                en: '/claims/:id/claim',
            },
        },
    })
], ClaimDeclaration);
export default ClaimDeclaration;
