var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
const claim = namespace('claim');
let DamagesCompensationTableHeader = class DamagesCompensationTableHeader extends Vue {
    headerCellValue(cellData) {
        var _a, _b, _c;
        if ((_a = cellData === null || cellData === void 0 ? void 0 : cellData.data) === null || _a === void 0 ? void 0 : _a.taxType) {
            if (cellData === null || cellData === void 0 ? void 0 : cellData.displayValue.includes('Vétusté')) {
                return `${cellData === null || cellData === void 0 ? void 0 : cellData.displayValue.replace(/(Vétusté)(\s|$)/i, '$1<br />')} ${(_b = cellData === null || cellData === void 0 ? void 0 : cellData.data) === null || _b === void 0 ? void 0 : _b.taxType}`;
            }
            return `${cellData === null || cellData === void 0 ? void 0 : cellData.displayValue}<br />${(_c = cellData === null || cellData === void 0 ? void 0 : cellData.data) === null || _c === void 0 ? void 0 : _c.taxType}`;
        }
        return (cellData === null || cellData === void 0 ? void 0 : cellData.displayValue) || '';
    }
    headerCellClass(cellData) {
        const matchValue = cellData.name.match(/\.([^.]*)$/);
        const cellName = (matchValue === null || matchValue === void 0 ? void 0 : matchValue.length) ? matchValue[1] : 'default';
        const classes = [`damage-table-cell__` + cellName];
        return classes.join(' ');
    }
};
__decorate([
    claim.State('isEditingClaim')
], DamagesCompensationTableHeader.prototype, "isEditingClaim", void 0);
DamagesCompensationTableHeader = __decorate([
    Component({
        inheritAttrs: false,
        props: {
            row: {
                type: Object,
                default: null,
                required: true,
            },
        },
    })
], DamagesCompensationTableHeader);
export default DamagesCompensationTableHeader;
