var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModelFormField from '@backoffice/components/claim/ModelFormField.vue';
import UpptecModelField from '@backoffice/components/claim/PersonalProperty/UpptecModelField.vue';
import { modelFieldsToColumns } from '@shared/modelField/modelFieldsColumns';
import { modelFieldKey, } from '@shared/modelField/modelField';
import { modelFieldValueToPayload } from '@shared/modelField/modelFieldPayload';
import { mergePayload } from '@shared/utils/mergePayload';
let ModelForm = class ModelForm extends Vue {
    get collectionErrors() {
        return (field) => field.editable ? this.validation.fieldErrors(field.editable.modelKey, this) : [];
    }
    get fieldsColumns() {
        var _a, _b;
        const fields = (_a = this.modelFields) === null || _a === void 0 ? void 0 : _a.fields;
        if (!fields) {
            return [];
        }
        return modelFieldsToColumns((_b = this.modelFields) === null || _b === void 0 ? void 0 : _b.fields);
    }
    onFieldUpdate(field, value) {
        if (!field.editable) {
            return;
        }
        const payload = modelFieldValueToPayload(field, this.modelFields.model, value);
        this.update(payload, field.editable.modelKey);
    }
    onCollectionSubFormUpdate(collectionField, index, update) {
        if (!collectionField.editable) {
            return;
        }
        const payload = collectionField.editable.toPayload(update.payload, this.modelFields.model, index, this.context);
        this.update(payload, collectionField.editable.modelKey + '.' + index);
    }
    onDelete() {
        this.$emit('delete');
    }
    onCollectionAdd(collectionField) {
        const payload = collectionField.editable.add(this.modelFields.model);
        this.update(payload, collectionField.editable.modelKey);
    }
    onCollectionSubFormDelete(collectionField, index) {
        var _a;
        const payload = (_a = collectionField.editable) === null || _a === void 0 ? void 0 : _a.delete(this.modelFields.model, index);
        if (!payload || !collectionField.editable) {
            return;
        }
        this.update(payload, collectionField.editable.modelKey);
    }
    update(payload, modelKey) {
        var _a;
        const entity = mergePayload(this.modelFields.model, payload);
        const constraints = ((_a = this.modelFields.editable) === null || _a === void 0 ? void 0 : _a.constraints)
            ? this.modelFields.editable.constraints(entity, this.validationConstraintsContext)
            : {};
        const update = {
            payload: this.modelFields.defaultPayload
                ? { ...this.modelFields.defaultPayload, ...payload }
                : payload,
            constraints,
            modelKey,
            entity,
        };
        this.$emit('update', update);
    }
    isRequired(field) {
        var _a;
        if ('separator' in field)
            return false;
        if ('group' in field)
            return false;
        if ('columns' in field)
            return false;
        if ('upptec' in field)
            return false;
        const constraintsFactory = (_a = this.modelFields.editable) === null || _a === void 0 ? void 0 : _a.constraints;
        if (constraintsFactory) {
            const allConstraints = constraintsFactory(this.modelFields.model, this.validationConstraintsContext);
            const fieldKey = modelFieldKey(field);
            const fieldConstraints = allConstraints[fieldKey];
            if (fieldConstraints) {
                /* Nested model form */
                if ('modelFields' in field) {
                    if ('length' in fieldConstraints) {
                        const constraint = fieldConstraints.length;
                        if (typeof constraint !== 'object')
                            return false;
                        if (typeof (constraint === null || constraint === void 0 ? void 0 : constraint.minimum) === 'number') {
                            return constraint.minimum > 0;
                        }
                    }
                    return field.modelFields.some((childModelFields) => childModelFields.fields.some((childModelField) => this.isRequired(childModelField)));
                }
                /* Presence */
                if ('presence' in fieldConstraints) {
                    let constraint = fieldConstraints.presence;
                    if (typeof constraint === 'function')
                        constraint = constraint(field.value);
                    if (typeof constraint === 'object' && (constraint === null || constraint === void 0 ? void 0 : constraint.allowEmpty) !== true)
                        return true;
                    if (constraint === true)
                        return true;
                }
                /* Presence if empty */
                if ('presenceIfEmpty' in fieldConstraints) {
                    let constraint = fieldConstraints.presenceIfEmpty;
                    if (typeof constraint === 'function')
                        constraint = constraint(field.value);
                    if (typeof constraint !== 'object')
                        return false;
                    if (typeof (constraint === null || constraint === void 0 ? void 0 : constraint.attribute) === 'string') {
                        return !constraint.attribute
                            .split('.')
                            .reduce((obj, key) => obj === null || obj === void 0 ? void 0 : obj[key], this.modelFields.model);
                    }
                }
                /* Presence if present */
                if ('presenceIfPresent' in fieldConstraints) {
                    let constraint = fieldConstraints.presenceIfPresent;
                    if (typeof constraint === 'function')
                        constraint = constraint(field.value);
                    if (typeof constraint !== 'object')
                        return false;
                    if (typeof (constraint === null || constraint === void 0 ? void 0 : constraint.attribute) === 'string') {
                        return !!constraint.attribute
                            .split('.')
                            .reduce((obj, key) => obj === null || obj === void 0 ? void 0 : obj[key], this.modelFields.model);
                    }
                }
                /* Presence if value */
                if ('presenceIfValue' in fieldConstraints) {
                    let constraint = fieldConstraints.presenceIfValue;
                    if (typeof constraint === 'function')
                        constraint = constraint(field.value);
                    if (typeof constraint !== 'object')
                        return false;
                    if (typeof (constraint === null || constraint === void 0 ? void 0 : constraint.attribute) === 'string' &&
                        Array.isArray(constraint === null || constraint === void 0 ? void 0 : constraint.triggeringValue)) {
                        return constraint.triggeringValue.includes(constraint.attribute
                            .split('.')
                            .reduce((obj, key) => obj === null || obj === void 0 ? void 0 : obj[key], this.modelFields.model));
                    }
                }
                /* Pictures collection */
                if ('requiredFileCollection' in fieldConstraints) {
                    let constraint = fieldConstraints.requiredFileCollection;
                    if (typeof constraint === 'function')
                        constraint = constraint(field.value);
                    if (constraint) {
                        return true;
                    }
                }
                /* Length */
                if ('length' in fieldConstraints) {
                    let constraint = fieldConstraints.length;
                    if (typeof constraint === 'function')
                        constraint = constraint(field.value);
                    if (typeof constraint !== 'object')
                        return false;
                    if (typeof (constraint === null || constraint === void 0 ? void 0 : constraint.minimum) === 'number') {
                        return constraint.minimum > 0;
                    }
                }
            }
        }
        return false;
    }
    onProductSelected({ product, createdProduct, }) {
        var _a, _b;
        const payload = {
            designation: product.name,
            description: product.brand,
            thirdPartyProductId: createdProduct.itemId,
            thirdPartyProductInfo: { ...product, ...createdProduct },
            category: createdProduct.personalPropertyCategory,
            amount: ((_a = product.amount) !== null && _a !== void 0 ? _a : 0) * 100,
            userKnowPrice: null,
        };
        const entity = mergePayload(this.modelFields.model, payload);
        const constraints = ((_b = this.modelFields.editable) === null || _b === void 0 ? void 0 : _b.constraints)
            ? this.modelFields.editable.constraints(entity, this.validationConstraintsContext)
            : {};
        const update = {
            payload,
            constraints,
            modelKey: [
                'designation',
                'description',
                'thirdPartyProductId',
                'thirdPartyProductInfo',
                'category',
                'userKnowPrice',
            ],
            entity,
        };
        this.$emit('update', update);
    }
    onMissingProduct(productName) {
        var _a;
        const payload = {
            designation: productName,
            description: null,
            thirdPartyProductId: null,
            thirdPartyProductInfo: null,
            category: null,
            userKnowPrice: null,
        };
        const entity = mergePayload(this.modelFields.model, payload);
        const constraints = ((_a = this.modelFields.editable) === null || _a === void 0 ? void 0 : _a.constraints)
            ? this.modelFields.editable.constraints(entity, this.validationConstraintsContext)
            : {};
        const update = {
            payload,
            constraints,
            modelKey: [
                'description',
                'designation',
                'thirdPartyProductId',
                'thirdPartyProductInfo',
                'category',
                'userKnowPrice',
            ],
            entity,
        };
        this.$emit('update', update);
    }
    onManualInput() {
        var _a;
        const payload = {
            thirdPartyProductId: null,
            thirdPartyProductInfo: null,
        };
        const entity = mergePayload(this.modelFields.model, payload);
        const constraints = ((_a = this.modelFields.editable) === null || _a === void 0 ? void 0 : _a.constraints)
            ? this.modelFields.editable.constraints(entity, this.validationConstraintsContext)
            : {};
        const update = {
            payload,
            constraints,
            modelKey: ['thirdPartyProductId', 'thirdPartyProductInfo'],
            entity,
        };
        this.$emit('update', update);
    }
};
__decorate([
    Prop({ type: Object, required: true })
], ModelForm.prototype, "modelFields", void 0);
__decorate([
    Prop({ type: Object, required: true })
], ModelForm.prototype, "context", void 0);
__decorate([
    Prop({ type: Object, required: true })
], ModelForm.prototype, "validation", void 0);
__decorate([
    Prop({ type: Object, required: true })
], ModelForm.prototype, "validationConstraintsContext", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], ModelForm.prototype, "displayTitle", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ModelForm.prototype, "canDelete", void 0);
ModelForm = __decorate([
    Component({
        name: 'ModelForm',
        components: { ModelFormField, UpptecModelField },
    })
], ModelForm);
export default ModelForm;
