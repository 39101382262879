var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import debounce from 'lodash/debounce';
import ClaimTypeTile from '@backoffice/components/claim/ClaimTypeTile.vue';
import ContractCard from '@backoffice/components/contract/ContractCard.vue';
import { ApiResourceName } from '@shared/types/api/api';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
import { uuidFromIri } from '@shared/utils/iri';
import { Step } from '@shared/claim/steps';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { fetchResourceCollection } from '@backoffice/utils/api';
import { INSURER_CONFIG } from '@shared/insurer';
const claimTypes = namespace('claimTypes');
let FormDeclarationGenerique = class FormDeclarationGenerique extends Vue {
    constructor() {
        super(...arguments);
        this.users = [];
        this.contracts = [];
        this.selectedUser = null;
        this.selectedContract = null;
        this.selectedClaimType = null;
        this.isFindingUsers = false;
        this.isFindingContracts = false;
        this.isCreatingClaim = false;
        this.isModalOpen = false;
    }
    get hasExternalContractSearch() {
        return insurerHasFeature.call(this, InsurerFeatureFlag.ExternalContractSearch);
    }
    async canSearchExternalContractForCurrentEnv() {
        return await this.$axios
            .get(apiResourcePath(ApiResourceName.ExternalSearchEnabled))
            .then((response) => response.data.enabled);
    }
    get isValid() {
        return !!this.selectedUser && !!this.selectedContract && !!this.selectedClaimType;
    }
    onTyping(value) {
        this.users = [];
        this.contracts = [];
        this.findUsers(value);
    }
    onSelect(option, _$event) {
        this.selectUser(option);
    }
    onSelectFooter(_$event) {
        this.openModal();
    }
    selectUser(user) {
        this.contracts = [];
        this.selectedUser = user;
        this.selectedContract = null;
        this.selectedClaimType = null;
        this.findContracts(user);
    }
    unselectUser() {
        this.contracts = [];
        this.selectedUser = null;
        this.selectedContract = null;
        this.selectedClaimType = null;
    }
    selectContract(contract) {
        this.selectedContract = contract;
        this.selectedClaimType = null;
    }
    unselectContract() {
        this.selectedContract = null;
        this.selectedClaimType = null;
    }
    selectClaimType(type) {
        this.selectedClaimType = type;
    }
    unSelectClaimType() {
        this.selectedClaimType = null;
    }
    openModal() {
        this.isModalOpen = true;
        // this.searchContracts(null)
    }
    closeModal() {
        this.isModalOpen = false;
    }
    async createClaim() {
        var _a;
        if (!this.selectedUser ||
            !this.selectedContract ||
            !this.selectedClaimType ||
            this.isCreatingClaim)
            return;
        this.isCreatingClaim = true;
        const payload = {
            policyholder: this.selectedUser.id,
            contract: this.selectedContract.id,
            type: this.selectedClaimType.id,
        };
        let claim = null;
        try {
            claim = await this.$axios.$post(apiResourcePath(ApiResourceName.Claims), payload);
            await this.$axios.$post(apiResourcePath(ApiResourceName.Countings), {
                title: 'Chiffrage',
                claim: claim.id,
            });
            await this.$axios.$put(`/claims-brms/${uuidFromIri(claim.id)}`, {
                step: Step.ClaimDate,
                type: this.selectedClaimType.id,
                isCreationFromBackoffice: true,
            });
            const routeId = ((_a = INSURER_CONFIG.backoffice) === null || _a === void 0 ? void 0 : _a.claimUrlIdentifier)
                ? claim[INSURER_CONFIG.backoffice.claimUrlIdentifier]
                : uuidFromIri(claim.id);
            this.$goToLocalePath({
                name: 'declarations-id-declaration',
                params: { id: routeId },
                query: { mode: 'edition' },
            });
        }
        catch (_b) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue',
                type: 'is-danger',
            });
        }
        finally {
            this.isCreatingClaim = false;
        }
    }
};
__decorate([
    claimTypes.Getter('availableClaimTypes')
], FormDeclarationGenerique.prototype, "availableClaimTypes", void 0);
FormDeclarationGenerique = __decorate([
    Component({
        components: {
            ClaimTypeTile,
            ContractCard,
        },
        async fetch() {
            await this.$store.dispatch('claimTypes/loadClaimTypes');
        },
        methods: {
            findUsers: debounce(async function (search) {
                if (search.length > 2) {
                    this.isFindingUsers = true;
                    this.users = await fetchResourceCollection(ApiResourceName.Users, this.$axios, {
                        search,
                    })
                        .then(({ data }) => data)
                        .catch(() => []);
                    this.isFindingUsers = false;
                }
            }, 500),
            async findContracts(user) {
                this.isFindingContracts = true;
                this.contracts = await fetchResourceCollection(ApiResourceName.Contracts, this.$axios, { user: user.id })
                    .then(({ data }) => data)
                    .catch(() => []);
                this.isFindingContracts = false;
            },
        },
    })
], FormDeclarationGenerique);
export default FormDeclarationGenerique;
