var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let DocumentCheckTable = class DocumentCheckTable extends Vue {
    get preparedChecks() {
        return this.checks.map(({ slug, displayValue, passed, blocking }) => {
            return {
                passed: passed ? '✅' : blocking ? '❌' : '⚠️',
                label: this.$tc(`backoffice.document.${slug}`),
                value: displayValue,
            };
        });
    }
};
DocumentCheckTable = __decorate([
    Component({
        props: {
            checks: {
                type: Array,
                default: () => [],
            },
            hideStatus: {
                type: Boolean,
                default: false,
            },
        },
    })
], DocumentCheckTable);
export default DocumentCheckTable;
