var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.email = '';
        this.password = '';
        this.isLoading = false;
    }
    mounted() {
        if (typeof this.oidc === 'object' && !this.oidc.allowLocal) {
            this.oidcLogin();
        }
    }
    get validEmail() {
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/i.test(this.email);
    }
    get validPassword() {
        return this.password.length > 2;
    }
    get canLogin() {
        return this.validEmail && this.validPassword;
    }
    get oidc() {
        var _a, _b, _c, _d;
        return (_d = (_c = (_b = (_a = this.$config.insurerConfig.backoffice) === null || _a === void 0 ? void 0 : _a.security) === null || _b === void 0 ? void 0 : _b.auth) === null || _c === void 0 ? void 0 : _c.oidc) !== null && _d !== void 0 ? _d : false;
    }
    get isOpenid() {
        return typeof this.oidc === 'boolean' ? this.oidc : this.oidc.active;
    }
    get showLoginForm() {
        return typeof this.oidc === 'boolean' ? !this.oidc : !this.oidc.active || !!this.oidc.allowLocal;
    }
    async localLogin() {
        try {
            this.isLoading = true;
            await this.$auth.loginWith('local', {
                data: {
                    email: this.email.toLocaleLowerCase().trim(),
                    password: this.password.trim(),
                },
            });
        }
        catch (_a) {
            const message = this.$tc('pages.login.error.invalid_credentials');
            this.toastError(message);
        }
        finally {
            this.isLoading = false;
        }
    }
    async oidcLogin() {
        try {
            await this.$auth.loginWith('oidc');
        }
        catch (e) {
            if (e instanceof Error)
                this.toastError(e.message);
            else if (this.$auth.error)
                this.toastError(this.$auth.error.message);
            else
                throw e;
        }
    }
    toastError(message) {
        this.$buefy.toast.open({
            message,
            type: 'is-danger',
            position: 'is-bottom',
        });
    }
};
Login = __decorate([
    Component({ layout: 'fullscreen', auth: 'guest' })
], Login);
export default Login;
