var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Subject } from 'rxjs';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EntityChangeType, newEntityForChangeType } from '@backoffice/claim/edition/entitiesChanges';
import ModelFormModal from '@backoffice/components/claim/ModelFormModal.vue';
import ModelInfoAndForm from '@backoffice/components/claim/ModelInfoAndForm.vue';
import { MODEL_TO_FIELDS_FOR_TYPE, } from '@shared/modelField/modelField';
import { LayoutElementStatus } from '@shared/utils/layout';
import { ValidationContext } from '@shared/validator/validationContext';
import { newValidator, revealViolations, touch, validate } from '@shared/validator/validator';
import { RobberyEventType, } from '@shared/types/api/claim';
import { GlassBreakagePersonalPropertyDamagesOrigin, GlassBreakageRoomDamagesOrigin, } from '@shared/types/api/glassBreakageDamage';
const claim = namespace('claim');
const settings = namespace('settings');
const newValidationContext = () => new ValidationContext(newValidator({}));
let ModelListAndEdition = class ModelListAndEdition extends Vue {
    constructor() {
        super(...arguments);
        this.LayoutElementStatus = LayoutElementStatus;
        this.deletingEntity = null;
        this.newEntity = null;
        this.newEntityValidation = newValidationContext();
        this.EntityChangeType = EntityChangeType;
    }
    get personalPropertyCountLimit() {
        return this.settings('volPersonalPropertyMaxCountBeforeExpertise');
    }
    get personalPropertyLimitExceedLabel() {
        return this.$tc('personal-property-limit-exceed-label', undefined, {
            limit: this.personalPropertyCountLimit,
        });
    }
    get isEmpty() {
        return this.entities.length === 0 && !this.shouldDisplayPersonalPropertyLimitExceedLabel;
    }
    get fieldsContext() {
        const context = this.modelFieldsContext(this.$tc.bind(this), true);
        return context && { ...context, edition: true };
    }
    get entities() {
        const translate = this.$tc.bind(this);
        return (this.entitiesAndFields(this.type, translate)
            // .filter((entityAndFields) => !isEmpty(entityAndFields.changed))
            .filter((e) => {
            if (this.entityFilter !== null)
                return this.entityFilter(e);
            return true;
        })
            .map((entityAndFields) => ({
            ...entityAndFields,
            close: new Subject(),
            isDeleting: this.deletingEntity === entityAndFields.changeKey,
        })));
    }
    get newEntityModelFields() {
        var _a;
        const context = this.fieldsContext;
        if (!context) {
            return { fields: [], model: {}, title: '' };
        }
        return MODEL_TO_FIELDS_FOR_TYPE[this.type]((_a = this.newEntity) !== null && _a !== void 0 ? _a : {}, context);
    }
    onEntityEdit(_changeKey) {
        // TODO snapshot on new edit (see undo action)
        // this.snapshotEntityChange({ changeKey, type: this.type })
    }
    onEntityCancel(_changeKey) {
        // this.undoEntityChange({ changeKey, type: this.type })
        // TODO pass constraints and data for validate(validator) after undo change
    }
    onEntityUpdate(changeKey, update) {
        const actionPayload = { type: this.type, changeKey, ...update };
        this.updateEntity(actionPayload);
    }
    onEntityReveal(changeKey, { entity, constraints }) {
        if (!entity) {
            return;
        }
        const actionPayload = {
            type: this.type,
            changeKey,
            entity,
            constraints,
        };
        this.validateAndRevealEntity(actionPayload);
    }
    onAdd() {
        var _a, _b;
        this.newEntity = newEntityForChangeType(this.type, (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim);
        const validator = newValidator(this.newEntity);
        this.newEntityValidation = new ValidationContext(validator);
        const constraintsFactory = (_b = this.newEntityModelFields.editable) === null || _b === void 0 ? void 0 : _b.constraints;
        if (!this.validationConstraintsContext) {
            return;
        }
        validate(validator, this.newEntity, constraintsFactory
            ? constraintsFactory(this.newEntity, this.validationConstraintsContext)
            : {});
    }
    onCancelAdd() {
        this.newEntity = null;
        this.newEntityValidation = newValidationContext();
    }
    async onEntitySave(close) {
        await this.draftChangeAndHandleError();
        close.next();
    }
    async onEntityDelete(changeKey) {
        this.deletingEntity = changeKey;
        this.deleteEntity({ type: this.type, changeKey });
        try {
            await this.draftChangeAndHandleError();
        }
        catch (err) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
            throw err;
        }
        finally {
            this.deletingEntity = null;
        }
    }
    onNewEntityUpdate(update) {
        var _a;
        this.newEntity = update.entity;
        const validator = this.newEntityValidation.validator;
        if (!validator || !this.validationConstraintsContext || !this.newEntity) {
            return;
        }
        const constraintsFactory = (_a = this.newEntityModelFields.editable) === null || _a === void 0 ? void 0 : _a.constraints;
        touch(validator, update.modelKey);
        validate(validator, this.newEntity, constraintsFactory
            ? constraintsFactory(this.newEntity, this.validationConstraintsContext)
            : {});
    }
    async onNewEntitySave() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        if (!this.newEntity)
            return;
        const newKey = this.createEntity({ type: this.type, payload: this.newEntity });
        try {
            if (this.type === EntityChangeType.BreakInDamage) {
                const initialBreakInDamages = [
                    ...((_d = (_b = (((_a = this.draftPayload) === null || _a === void 0 ? void 0 : _a.counting) &&
                        this.draftPayload.counting.breakInDamages)) !== null && _b !== void 0 ? _b : (_c = this.initialClaim) === null || _c === void 0 ? void 0 : _c.counting.breakInDamages) !== null && _d !== void 0 ? _d : []),
                ];
                if (this.newEntity) {
                    initialBreakInDamages.push(this.newEntity);
                }
                await this.updateDraftPayload({
                    id: (_e = this.initialClaim) === null || _e === void 0 ? void 0 : _e.id,
                    counting: {
                        id: (_f = this.initialClaim) === null || _f === void 0 ? void 0 : _f.counting.id,
                        breakInDamages: initialBreakInDamages,
                    },
                });
            }
            else if (this.type === EntityChangeType.RoomDamage) {
                const initialRoomDamages = [
                    ...((_k = (_h = (((_g = this.draftPayload) === null || _g === void 0 ? void 0 : _g.counting) &&
                        this.draftPayload.counting.roomDamages)) !== null && _h !== void 0 ? _h : (_j = this.initialClaim) === null || _j === void 0 ? void 0 : _j.counting.roomDamages) !== null && _k !== void 0 ? _k : []),
                ];
                if (this.newEntity) {
                    initialRoomDamages.push(this.newEntity);
                }
                await this.updateDraftPayload({
                    id: (_l = this.initialClaim) === null || _l === void 0 ? void 0 : _l.id,
                    counting: {
                        id: (_m = this.initialClaim) === null || _m === void 0 ? void 0 : _m.counting.id,
                        roomDamages: initialRoomDamages,
                    },
                });
            }
            else if (this.type === EntityChangeType.ExternalDamage) {
                const initialExternalDamages = [
                    ...((_r = (_p = (((_o = this.draftPayload) === null || _o === void 0 ? void 0 : _o.counting) &&
                        this.draftPayload.counting.externalDamages)) !== null && _p !== void 0 ? _p : (_q = this.initialClaim) === null || _q === void 0 ? void 0 : _q.counting.externalDamages) !== null && _r !== void 0 ? _r : []),
                ];
                if (this.newEntity) {
                    initialExternalDamages.push(this.newEntity);
                }
                await this.updateDraftPayload({
                    id: (_s = this.initialClaim) === null || _s === void 0 ? void 0 : _s.id,
                    counting: {
                        id: (_t = this.initialClaim) === null || _t === void 0 ? void 0 : _t.counting.id,
                        externalDamages: initialExternalDamages,
                    },
                });
            }
            else if (this.type === EntityChangeType.GlassDamage) {
                const initialGlassDamages = [
                    ...((_x = (_v = (((_u = this.draftPayload) === null || _u === void 0 ? void 0 : _u.counting) &&
                        this.draftPayload.counting.glassDamages)) !== null && _v !== void 0 ? _v : (_w = this.initialClaim) === null || _w === void 0 ? void 0 : _w.counting.glassDamages) !== null && _x !== void 0 ? _x : []),
                ];
                if (this.newEntity) {
                    initialGlassDamages.push(this.newEntity);
                }
                await this.updateDraftPayload({
                    id: (_y = this.initialClaim) === null || _y === void 0 ? void 0 : _y.id,
                    counting: {
                        id: (_z = this.initialClaim) === null || _z === void 0 ? void 0 : _z.counting.id,
                        glassDamages: initialGlassDamages,
                    },
                });
            }
            else {
                // CIA-1945 Don't rely on this method if you add a new damage type !
                // If you add a new damage type, make it work with updateDraftPayload or else the draft won't be updated and CIA-1945 bug
                // will occur for your new damage type, meaning if you force the compensation type
                // it will just 'delete' the damage instead (it was never correctly saved)
                await this.draftChanges();
            }
            this.newEntity = null;
            this.newEntityValidation = newValidationContext();
        }
        catch (err) {
            this.deleteEntity({ type: this.type, changeKey: newKey });
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
    }
    onNewEntityReveal(_reveal) {
        var _a;
        const validator = this.newEntityValidation.validator;
        if (!validator ||
            !this.newEntity ||
            !this.validationConstraintsContext ||
            !this.newEntityValidation.validator) {
            return;
        }
        const constraintsFactory = (_a = this.newEntityModelFields.editable) === null || _a === void 0 ? void 0 : _a.constraints;
        validate(validator, this.newEntity, constraintsFactory
            ? constraintsFactory(this.newEntity, this.validationConstraintsContext)
            : {});
        revealViolations(this.newEntityValidation.validator);
    }
    async draftChangeAndHandleError() {
        try {
            await this.draftChanges();
        }
        catch (err) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
    }
    get shouldDisplayPersonalPropertyLimitExceedLabel() {
        return this.isPersonalPropertyLimitExceeded && this.type === EntityChangeType.PersonalProperty;
    }
    get buttonShouldBeDisabled() {
        if (this.type === EntityChangeType.ThirdParty) {
            return !this.canAddThirdParty;
        }
        return false;
    }
    get canAddThirdParty() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if ((_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim) === null || _b === void 0 ? void 0 : _b.robberyDamageQualification) {
            const qualification = this.info.claim.robberyDamageQualification;
            return qualification.eventType === RobberyEventType.Vandalism;
        }
        if ((_d = (_c = this.info) === null || _c === void 0 ? void 0 : _c.claim) === null || _d === void 0 ? void 0 : _d.stormSnowHailDamageQualification) {
            const qualification = this.info.claim.stormSnowHailDamageQualification;
            return qualification.isThirdPartyInvolved === true;
        }
        if ((_f = (_e = this.info) === null || _e === void 0 ? void 0 : _e.claim) === null || _f === void 0 ? void 0 : _f.glassBreakageDamageQualification) {
            const qualification = this.info.claim.glassBreakageDamageQualification;
            return (qualification.roomDamagesOrigin === GlassBreakageRoomDamagesOrigin.ThirdParty ||
                qualification.roomDamagesOrigin === GlassBreakageRoomDamagesOrigin.Professionnal ||
                qualification.personalPropertyDamagesOrigin ===
                    GlassBreakagePersonalPropertyDamagesOrigin.ThirdParty);
        }
        if ((_h = (_g = this.info) === null || _g === void 0 ? void 0 : _g.claim) === null || _h === void 0 ? void 0 : _h.fireDamageQualification) {
            const qualification = this.info.claim.fireDamageQualification;
            return (qualification.hasProfessionalInfo ||
                qualification.hasThirdPartyInfo ||
                qualification.fireElectricalCauseCommonParts);
        }
        return true;
    }
};
__decorate([
    Prop({ type: String, default: null })
], ModelListAndEdition.prototype, "title", void 0);
__decorate([
    Prop({ type: String, default: null })
], ModelListAndEdition.prototype, "emptyLabel", void 0);
__decorate([
    Prop({ type: String, default: null })
], ModelListAndEdition.prototype, "addLabel", void 0);
__decorate([
    Prop({ type: String, required: true })
], ModelListAndEdition.prototype, "type", void 0);
__decorate([
    Prop({ type: Function, default: null })
], ModelListAndEdition.prototype, "entityFilter", void 0);
__decorate([
    claim.State('isEditingClaim')
], ModelListAndEdition.prototype, "isEditingClaim", void 0);
__decorate([
    claim.State('initialClaim')
], ModelListAndEdition.prototype, "initialClaim", void 0);
__decorate([
    claim.State('draftPayload')
], ModelListAndEdition.prototype, "draftPayload", void 0);
__decorate([
    claim.Getter('modelFieldsContext')
], ModelListAndEdition.prototype, "modelFieldsContext", void 0);
__decorate([
    claim.Getter('entitiesAndFields')
], ModelListAndEdition.prototype, "entitiesAndFields", void 0);
__decorate([
    claim.Getter('validationConstraintsContext')
], ModelListAndEdition.prototype, "validationConstraintsContext", void 0);
__decorate([
    claim.Action('updateEntity')
], ModelListAndEdition.prototype, "updateEntity", void 0);
__decorate([
    claim.Action('undoEntityChange')
], ModelListAndEdition.prototype, "undoEntityChange", void 0);
__decorate([
    claim.Action('validateAndRevealEntity')
], ModelListAndEdition.prototype, "validateAndRevealEntity", void 0);
__decorate([
    claim.Action('createEntity')
], ModelListAndEdition.prototype, "createEntity", void 0);
__decorate([
    claim.Action('draftChanges')
], ModelListAndEdition.prototype, "draftChanges", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], ModelListAndEdition.prototype, "updateDraftPayload", void 0);
__decorate([
    claim.Action('deleteEntity')
], ModelListAndEdition.prototype, "deleteEntity", void 0);
__decorate([
    claim.Getter('isPersonalPropertyLimitExceeded')
], ModelListAndEdition.prototype, "isPersonalPropertyLimitExceeded", void 0);
__decorate([
    settings.Getter('get')
], ModelListAndEdition.prototype, "settings", void 0);
__decorate([
    claim.State('info')
], ModelListAndEdition.prototype, "info", void 0);
ModelListAndEdition = __decorate([
    Component({ components: { ModelFormModal, ModelInfoAndForm } })
], ModelListAndEdition);
export default ModelListAndEdition;
