var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
const settings = namespace('settings');
let Menu = class Menu extends Vue {
    get security() {
        var _a, _b;
        return (_b = (_a = this.$config.insurerConfig.backoffice) === null || _a === void 0 ? void 0 : _a.security) !== null && _b !== void 0 ? _b : {};
    }
    get entries() {
        return this.menuEntries.filter((entry) => !entry.hidden);
    }
    get hasAccessToMenuConfig() {
        return this.$hasAccessToRoute('configuration-menu');
    }
    get hasAccessToTemplateConfig() {
        return this.$hasAccessToRoute('configuration-templates');
    }
    isExternalLink(link) {
        return /^https?/.test(link);
    }
    close() {
        this.$emit('update:opened', false);
    }
};
__decorate([
    settings.State('menuEntries')
], Menu.prototype, "menuEntries", void 0);
Menu = __decorate([
    Component({
        props: {
            opened: {
                type: Boolean,
                default: false,
            },
        },
    })
], Menu);
export default Menu;
