var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { userRoleToString, userRoleToColorClass } from '@shared/user/userFields';
import { uuidFromIri } from '@shared/utils/iri';
const users = namespace('users');
let Index = class Index extends Vue {
    constructor() {
        super(...arguments);
        this.isSearchable = false;
    }
    get table() {
        return this.$refs.table;
    }
    get searchable() {
        return this.isSearchable;
    }
    set searchable(value) {
        this.isSearchable = value;
        if (!value && Object.values(this.table.filters).some((val) => typeof val !== 'undefined')) {
            this.$set(this.table, 'filters', {});
            this.resetFilters();
        }
    }
    onPageChange(page) {
        this.changePage(page);
    }
    onFilterChange(filter) {
        this.filter(filter);
    }
    onSort(by, direction) {
        this.sort({ by, direction });
    }
    onClick(user) {
        const uuid = uuidFromIri(user.id);
        this.$goToLocalePath({ name: 'utilisateurs-id', params: { id: uuid } });
    }
    createUser() {
        this.$goToLocalePath({ name: 'utilisateurs-nouveau' });
    }
};
__decorate([
    users.State('users')
], Index.prototype, "users", void 0);
__decorate([
    users.State('loading')
], Index.prototype, "loading", void 0);
__decorate([
    users.State('total')
], Index.prototype, "total", void 0);
__decorate([
    users.State('page')
], Index.prototype, "page", void 0);
__decorate([
    users.State('filters')
], Index.prototype, "filters", void 0);
__decorate([
    users.State('sorting')
], Index.prototype, "sorting", void 0);
__decorate([
    users.Getter('perPage')
], Index.prototype, "perPage", void 0);
__decorate([
    users.Action('loadUsers')
], Index.prototype, "loadUsers", void 0);
__decorate([
    users.Action('changePage')
], Index.prototype, "changePage", void 0);
__decorate([
    users.Action('sort')
], Index.prototype, "sort", void 0);
__decorate([
    users.Action('filter')
], Index.prototype, "filter", void 0);
__decorate([
    users.Mutation('RESET_FILTERS')
], Index.prototype, "resetFilters", void 0);
Index = __decorate([
    Component({
        middleware: 'hasAccess',
        filters: { userRoleToString, userRoleToColorClass },
        async fetch() {
            await this.loadUsers();
        },
        async activated() {
            await this.loadUsers();
        },
    })
], Index);
export default Index;
