var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import PendingChargesModal from '../compensation/PendingChargesModal.vue';
const claim = namespace('claim');
let EditClaimButton = class EditClaimButton extends Vue {
    get canSave() {
        return !this.isClaimUpdating && this.hasPendingChanges;
    }
    get charges() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting.charges) !== null && _b !== void 0 ? _b : [];
    }
    get pendingCharges() {
        return this.charges.filter((chargeA) => {
            var _a;
            return !((_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.counting.charges.some((chargeB) => chargeA.clientIdentifier === chargeB.clientIdentifier));
        });
    }
    get hasPendingCharges() {
        return this.pendingCharges.length > 0;
    }
    async save() {
        if (this.hasPendingCharges) {
            const modal = this.$buefy.modal.open({
                parent: this,
                component: PendingChargesModal,
                props: {
                    pendingCharges: this.pendingCharges,
                },
                events: {
                    confirm: async () => {
                        modal.close();
                        await this.saveChanges();
                        this.$buefy.toast.open(`Dossier mis à jour`);
                    },
                },
            });
        }
        else {
            await this.saveChanges();
            this.$buefy.toast.open(`Dossier mis à jour`);
        }
    }
};
__decorate([
    claim.State('info')
], EditClaimButton.prototype, "info", void 0);
__decorate([
    claim.State('initialClaim')
], EditClaimButton.prototype, "initialClaim", void 0);
__decorate([
    claim.State('isEditingClaim')
], EditClaimButton.prototype, "isEditingClaim", void 0);
__decorate([
    claim.State('hasPendingChanges')
], EditClaimButton.prototype, "hasPendingChanges", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], EditClaimButton.prototype, "isClaimUpdating", void 0);
__decorate([
    claim.Action('edit')
], EditClaimButton.prototype, "edit", void 0);
__decorate([
    claim.Action('cancelEdition')
], EditClaimButton.prototype, "cancelEdition", void 0);
__decorate([
    claim.Action('saveChanges')
], EditClaimButton.prototype, "saveChanges", void 0);
EditClaimButton = __decorate([
    Component({
        props: {
            canEdit: {
                type: Boolean,
                default: true,
            },
        },
    })
], EditClaimButton);
export default EditClaimButton;
