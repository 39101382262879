var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import moment from 'moment';
import BPagination from 'buefy/src/components/pagination/Pagination.vue';
import { uuidFromIri } from '@shared/utils/iri';
import { EMPTY } from '@shared/utils/strings';
import { CLAIM_STATUS_TRAD_KEYS } from '@backoffice/claim/status';
import { UserRole } from '@shared/types/api/user';
import { INSURER_CONFIG } from '@shared/insurer';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import ClaimsDeclarationsFilter from '@backoffice/components/ui/ClaimsDeclarationsFilter.vue';
const claims = namespace('claims');
const settings = namespace('settings');
let DeclarationsIndex = class DeclarationsIndex extends Vue {
    get dynamicTotalPages() {
        return this.filtersActivated()
            ? Math.ceil(this.totalFilteredClaims / this.itemsPerPage)
            : this.totalPages;
    }
    get canCreateClaim() {
        return this.$hasAccessLevel(UserRole.MANAGER);
    }
    get hideCreateClaimButton() {
        return (!!insurerHasFeature(InsurerFeatureFlag.HideNewClaimButtonInBOClaimList) && this.canCreateClaim);
    }
    async loadClaimsOnPageChange(page) {
        await this.changePage(page);
        await this.loadClaims();
    }
    onPageChange(page) {
        this.changePage(page);
    }
    onSort(by, direction) {
        this.sort({ by, direction });
    }
    onClick(claim) {
        var _a;
        const identifier = (_a = INSURER_CONFIG.backoffice) === null || _a === void 0 ? void 0 : _a.claimUrlIdentifier;
        const id = identifier ? claim[identifier] : uuidFromIri(claim.id);
        this.$goToLocalePath({ name: 'declarations-id', params: { id } });
    }
    createClaim() {
        this.$goToLocalePath({ name: 'declarations-nouvelle' });
    }
    filtersActivated() {
        const filters = this.filters;
        return Object.values(filters).some((val) => typeof val !== 'undefined');
    }
    paginationNote() {
        if (this.filtersActivated()) {
            return `Dossiers: ${this.totalFilteredClaims} /  ${this.total}`;
        }
        else {
            return `Dossiers: ${this.total}`;
        }
    }
};
__decorate([
    claims.State('data')
], DeclarationsIndex.prototype, "data", void 0);
__decorate([
    claims.State('loading')
], DeclarationsIndex.prototype, "loading", void 0);
__decorate([
    claims.State('total')
], DeclarationsIndex.prototype, "total", void 0);
__decorate([
    claims.State('page')
], DeclarationsIndex.prototype, "page", void 0);
__decorate([
    claims.State('filters')
], DeclarationsIndex.prototype, "filters", void 0);
__decorate([
    claims.State('sorting')
], DeclarationsIndex.prototype, "sorting", void 0);
__decorate([
    claims.Getter('totalPages')
], DeclarationsIndex.prototype, "totalPages", void 0);
__decorate([
    claims.Getter('totalFilteredClaims')
], DeclarationsIndex.prototype, "totalFilteredClaims", void 0);
__decorate([
    settings.Getter('get')
], DeclarationsIndex.prototype, "setting", void 0);
__decorate([
    claims.Action('loadClaims')
], DeclarationsIndex.prototype, "loadClaims", void 0);
__decorate([
    claims.Action('changePage')
], DeclarationsIndex.prototype, "changePage", void 0);
__decorate([
    claims.Action('sort')
], DeclarationsIndex.prototype, "sort", void 0);
__decorate([
    claims.State('itemsPerPage')
], DeclarationsIndex.prototype, "itemsPerPage", void 0);
__decorate([
    claims.Action('changeItemsPerPage')
], DeclarationsIndex.prototype, "changeItemsPerPage", void 0);
DeclarationsIndex = __decorate([
    Component({
        middleware: 'hasAccess',
        components: { ClaimsDeclarationsFilter, BPagination },
        filters: {
            anomalyScoreColor(value, anomalyScoreThreshold) {
                if (value < anomalyScoreThreshold / 3)
                    return 'is-success';
                else if (value < anomalyScoreThreshold)
                    return 'is-warning';
                else
                    return 'is-danger';
            },
            createdAt(value) {
                const date = moment(value);
                return date.format('DD/MM/YYYY');
            },
            claimType(value) {
                var _a;
                return (_a = value === null || value === void 0 ? void 0 : value.name) !== null && _a !== void 0 ? _a : EMPTY;
            },
            claimTypeColor(value) {
                var _a, _b;
                return `is-${(_b = (_a = value === null || value === void 0 ? void 0 : value.trigram) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : 'transparent'}`;
            },
            contactName(value) {
                return value.firstName + ' ' + value.lastName;
            },
            statusTradKey(value) {
                return CLAIM_STATUS_TRAD_KEYS[value];
            },
        },
        async fetch() {
            await this.loadClaims();
        },
        async activated() {
            await this.loadClaims();
        },
        nuxtI18n: {
            paths: {
                fr: '/declarations',
                en: '/claims',
            },
        },
    })
], DeclarationsIndex);
export default DeclarationsIndex;
