var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import Modal from '@backoffice/components/layout/Modal.vue';
import { DamageRouteName } from '@backoffice/damagesValuation/helpers';
import { uuidFromIri } from '@shared/utils/iri';
const damagesValuation = namespace('damagesValuation');
let DamagesValuationView = class DamagesValuationView extends Vue {
    constructor() {
        super(...arguments);
        this.DamageRouteName = DamageRouteName;
        this.isDeleting = false;
        this.showDamageCreationModal = false;
        this.title = '';
        this.selectedDepartment = '';
        this.selectedProductLine = '';
    }
    created() {
        this.selectDamagesValuation(this.$route.params.valuation);
        this.$watch('valuation', (valuation) => {
            var _a, _b, _c;
            if (!valuation) {
                return;
            }
            this.selectedDepartment = (_a = valuation.department) !== null && _a !== void 0 ? _a : '';
            this.selectedProductLine = (_b = valuation.productLine) !== null && _b !== void 0 ? _b : '';
            this.title = (_c = valuation.title) !== null && _c !== void 0 ? _c : '';
        }, { immediate: true });
    }
    get hasChangedInfos() {
        var _a, _b, _c, _d, _e, _f;
        return (this.selectedDepartment !== ((_b = (_a = this.valuation) === null || _a === void 0 ? void 0 : _a.department) !== null && _b !== void 0 ? _b : '') ||
            this.selectedProductLine !== ((_d = (_c = this.valuation) === null || _c === void 0 ? void 0 : _c.productLine) !== null && _d !== void 0 ? _d : '') ||
            this.title !== ((_f = (_e = this.valuation) === null || _e === void 0 ? void 0 : _e.title) !== null && _f !== void 0 ? _f : ''));
    }
    get hasDamages() {
        return false;
    }
    get canSave() {
        return !this.isSaving && !this.isLoading;
    }
    async saveInfo() {
        try {
            await this.save({
                title: this.title,
                department: this.selectedDepartment,
                productLine: this.selectedProductLine,
            });
        }
        catch (err) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
    }
    cancelInfoChanges() {
        var _a, _b, _c, _d, _e, _f;
        this.selectedDepartment = (_b = (_a = this.valuation) === null || _a === void 0 ? void 0 : _a.department) !== null && _b !== void 0 ? _b : '';
        this.selectedProductLine = (_d = (_c = this.valuation) === null || _c === void 0 ? void 0 : _c.productLine) !== null && _d !== void 0 ? _d : '';
        this.title = (_f = (_e = this.valuation) === null || _e === void 0 ? void 0 : _e.title) !== null && _f !== void 0 ? _f : '';
    }
    onCreateDamage(resource) {
        if (!this.valuation) {
            return;
        }
        this.$router.push(`/chiffrages/${uuidFromIri(this.valuation.id)}/${resource}/create`);
    }
    onEditDamage(resource, id) {
        if (!this.valuation) {
            return;
        }
        this.$router.push(`/chiffrages/${uuidFromIri(this.valuation.id)}/${resource}/${id}`);
    }
    confirmDeletion() {
        this.$buefy.dialog.confirm({
            title: 'Supprimer le chiffrage ?',
            message: 'Les données de ce chiffrage seront définitivement effacées.',
            onConfirm: async () => {
                if (!this.valuation) {
                    return;
                }
                this.isDeleting = true;
                try {
                    await this.$axios.$delete(this.valuation.id);
                    await this.$router.push('/chiffrages');
                    this.$buefy.toast.open({
                        message: 'Le chiffrage a bien été supprimé',
                        type: 'is-info',
                        position: 'is-bottom',
                    });
                }
                catch (err) {
                    this.isDeleting = false;
                    this.$buefy.toast.open({
                        message: 'Une erreur est survenue, merci de réessayer.',
                        type: 'is-danger',
                        position: 'is-bottom',
                    });
                }
            },
            confirmText: 'Supprimer',
            cancelText: 'Annuler',
        });
    }
};
__decorate([
    damagesValuation.State('valuation')
], DamagesValuationView.prototype, "valuation", void 0);
__decorate([
    damagesValuation.State('departments')
], DamagesValuationView.prototype, "departments", void 0);
__decorate([
    damagesValuation.State('productLines')
], DamagesValuationView.prototype, "productLines", void 0);
__decorate([
    damagesValuation.State('id')
], DamagesValuationView.prototype, "id", void 0);
__decorate([
    damagesValuation.State('isLoading')
], DamagesValuationView.prototype, "isLoading", void 0);
__decorate([
    damagesValuation.State('error')
], DamagesValuationView.prototype, "error", void 0);
__decorate([
    damagesValuation.State('isSaving')
], DamagesValuationView.prototype, "isSaving", void 0);
__decorate([
    damagesValuation.Action('selectDamagesValuation')
], DamagesValuationView.prototype, "selectDamagesValuation", void 0);
__decorate([
    damagesValuation.Action('update')
], DamagesValuationView.prototype, "update", void 0);
__decorate([
    damagesValuation.Action('save')
], DamagesValuationView.prototype, "save", void 0);
DamagesValuationView = __decorate([
    Component({
        middleware: ['hasAccess', 'departments', 'productLines'],
        components: { Modal },
    })
], DamagesValuationView);
export default DamagesValuationView;
