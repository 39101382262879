var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import PersonalPropertyCatalogItem from '@backoffice/components/claim/PersonalProperty/PersonalPropertyCatalogItem.vue';
import { isProductModelField } from '@shared/modelField/modelField';
import { EMPTY } from '@shared/utils/strings';
let ModelProduct = class ModelProduct extends Vue {
    constructor() {
        super(...arguments);
        this.empty = EMPTY;
    }
    get candidate() {
        if (!isProductModelField(this.field)) {
            return null;
        }
        return this.field.displayedValue.candidate;
    }
};
ModelProduct = __decorate([
    Component({
        components: {
            PersonalPropertyCatalogItem,
        },
        props: {
            field: {
                type: Object,
                required: true,
            },
            canEdit: { type: Boolean, default: false },
        },
    })
], ModelProduct);
export default ModelProduct;
