var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import mdi from '@mdi/svg/meta.json';
const mdiIcons = mdi.map((icon) => icon.name);
let UiIconSelector = class UiIconSelector extends Vue {
    constructor() {
        super(...arguments);
        this.offset = 50;
    }
    get icon() {
        return this.value;
    }
    set icon(icon) {
        this.$emit('input', icon);
    }
    get filteredData() {
        return mdiIcons.filter((icon) => icon.includes(this.value)).slice(0, this.offset);
    }
    onInfiniteScroll() {
        this.offset += 50;
    }
    onSelect() {
        this.offset = 50;
    }
};
UiIconSelector = __decorate([
    Component({
        props: {
            value: {
                type: String,
                required: true,
            },
        },
    })
], UiIconSelector);
export default UiIconSelector;
