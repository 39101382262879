var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let TemplateEditor = class TemplateEditor extends Vue {
    mounted() {
        const elem = this.$refs.editor;
        this.editor = this.$jsonEditor.create(elem, {
            schema: {
                title: 'Template du message',
                type: 'object',
                properties: {
                    title: {
                        title: 'Titre',
                        type: 'string',
                    },
                    html: {
                        title: 'Template',
                        type: 'string',
                        format: 'twig',
                        options: {
                            ace: {
                                theme: 'ace/theme/vibrant_ink',
                                tabSize: 2,
                                useSoftTabs: true,
                                wrap: true,
                            },
                        },
                    },
                },
            },
            startval: this.value,
            disable_collapse: true,
            disable_properties: true,
            disable_edit_json: true,
            required_by_default: true,
            no_additional_properties: true,
        });
        this.editor.on('change', () => {
            const value = this.editor.getValue();
            this.$emit('input', { ...this.value, ...value });
        });
        this.editor.on('ready', () => {
            this.editor.validate();
        });
    }
    beforeDestroy() {
        this.editor.destroy();
    }
};
TemplateEditor = __decorate([
    Component({
        props: {
            value: {
                type: Object,
                required: true,
            },
        },
    })
], TemplateEditor);
export default TemplateEditor;
