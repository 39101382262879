var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import EditableModelInfo from '@backoffice/components/claim/EditableModelInfo.vue';
import { fireDamageQualificationFields } from '@shared/qualification/fireDamageQualificationFields';
const claim = namespace('claim');
let FireDamageQualification = class FireDamageQualification extends Vue {
    get context() {
        return this.modelFieldsContextGetter(this.$tc.bind(this), this.isEditingClaim);
    }
    get fireDamageQualification() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.fireDamageQualification) !== null && _b !== void 0 ? _b : {};
    }
    get modelFields() {
        if (!this.context)
            return null;
        return fireDamageQualificationFields(this.fireDamageQualification, this.context);
    }
    async onSubmit(update) {
        var _a;
        await this.updateDraftPayload({
            fireDamageQualification: {
                ...update.payload,
                id: (_a = this.fireDamageQualification.id) !== null && _a !== void 0 ? _a : null,
            },
        });
    }
};
__decorate([
    claim.State('info')
], FireDamageQualification.prototype, "info", void 0);
__decorate([
    claim.Getter('modelFieldsContext')
], FireDamageQualification.prototype, "modelFieldsContextGetter", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], FireDamageQualification.prototype, "updateDraftPayload", void 0);
__decorate([
    claim.State('isEditingClaim')
], FireDamageQualification.prototype, "isEditingClaim", void 0);
FireDamageQualification = __decorate([
    Component({
        components: {
            EditableModelInfo,
        },
    })
], FireDamageQualification);
export default FireDamageQualification;
