var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModelField from '@backoffice/components/claim/ModelField.vue';
import ModelFile from '@backoffice/components/claim/ModelFile.vue';
import ModelFileCollection from '@backoffice/components/claim/ModelFileCollection.vue';
import ModelProduct from '@backoffice/components/claim/ModelProduct.vue';
import ModelInfo from '@backoffice/components/claim/ModelInfo.vue';
import { isFileCollectionModelField, isFileModelField, isProductModelField, } from '@shared/modelField/modelField';
let ModelInfoLayout = class ModelInfoLayout extends Vue {
    isFileCollection(field) {
        if ('upptec' in field)
            return false;
        return 'displayedValue' in field && isFileCollectionModelField(field);
    }
    isFile(field) {
        if ('upptec' in field)
            return false;
        return 'displayedValue' in field && isFileModelField(field);
    }
    isProduct(field) {
        if ('upptec' in field)
            return false;
        return 'displayedValue' in field && isProductModelField(field);
    }
    onFieldUpdate(field, value) {
        if (!('editable' in field) || !field.editable)
            return;
        this.$emit('update', { field, value });
    }
    onFieldSubmit(field, value) {
        if (!('editable' in field) || !field.editable)
            return;
        this.$emit('submit', { field, value });
    }
};
__decorate([
    Prop({ type: Object, required: true })
], ModelInfoLayout.prototype, "layout", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ModelInfoLayout.prototype, "isChild", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ModelInfoLayout.prototype, "canEdit", void 0);
__decorate([
    Prop({ type: Object, default: null })
], ModelInfoLayout.prototype, "validation", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ModelInfoLayout.prototype, "disableFormFields", void 0);
__decorate([
    Prop({ type: Object, default: null })
], ModelInfoLayout.prototype, "context", void 0);
ModelInfoLayout = __decorate([
    Component({
        name: 'ModelInfoLayout',
        components: {
            ModelProduct,
            ModelFileCollection,
            ModelField,
            ModelFile,
            ModelInfo,
        },
    })
], ModelInfoLayout);
export default ModelInfoLayout;
