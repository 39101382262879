var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { optionalAmountToCents, optionalCentsToAmount } from '@shared/utils/amountToCents';
const claim = namespace('claim');
let ProvisionModal = class ProvisionModal extends Vue {
    constructor() {
        super(...arguments);
        this.value = null;
    }
    mounted() {
        this.value = optionalCentsToAmount(this.initialProvision);
    }
    confirm() {
        this.$emit('confirm', optionalAmountToCents(this.value));
    }
    close() {
        this.$emit('close');
    }
};
__decorate([
    claim.Getter('provisionAmount')
], ProvisionModal.prototype, "initialProvision", void 0);
ProvisionModal = __decorate([
    Component
], ProvisionModal);
export default ProvisionModal;
