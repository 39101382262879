var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { modelFieldsToLayout } from '@shared/modelField/modelFieldsLayout';
import { modelFieldValueToPayload } from '@shared/modelField/modelFieldPayload';
let ModelInfo = class ModelInfo extends Vue {
    get fieldsLayout() {
        var _a, _b;
        return modelFieldsToLayout((_a = this.modelFields) === null || _a === void 0 ? void 0 : _a.fields, (_b = this.modelFields) === null || _b === void 0 ? void 0 : _b.title);
    }
    onFieldUpdate({ field, value }) {
        if (!('editable' in field) || !field.editable) {
            return;
        }
        const payload = this.payloadFromFieldAndValue(field, value);
        this.$emit('update', { modelKey: field.editable.modelKey, payload });
    }
    onFieldSubmit({ field, value }) {
        if (!('editable' in field) || !field.editable) {
            return;
        }
        const payload = this.payloadFromFieldAndValue(field, value);
        this.$emit('submit', { modelKey: field.editable.modelKey, payload });
    }
    payloadFromFieldAndValue(field, value) {
        if (!this.context || !('editable' in field) || !field.editable || !this.modelFields) {
            throw new Error('No fields context for ModelInfo');
        }
        return modelFieldValueToPayload(field, this.modelFields.model, value);
    }
};
__decorate([
    Prop({ type: Object, default: null })
], ModelInfo.prototype, "modelFields", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ModelInfo.prototype, "isChild", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ModelInfo.prototype, "canEdit", void 0);
__decorate([
    Prop({ type: Object, default: null })
], ModelInfo.prototype, "validation", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ModelInfo.prototype, "disableFormFields", void 0);
__decorate([
    Prop({ type: Object, default: null })
], ModelInfo.prototype, "context", void 0);
ModelInfo = __decorate([
    Component({
        name: 'ModelInfo',
        components: {
            ModelInfoLayout: () => import('@backoffice/components/claim/ModelInfoLayout.vue'),
        },
    })
], ModelInfo);
export default ModelInfo;
