var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import FileViewer from '@backoffice/components/file/FileViewer.vue';
import { DocumentCheckType } from '@shared/types/api/file';
import { FILE_TYPES_LABELS } from '@backoffice/files/labels';
import DocumentCheckTable from '@backoffice/components/file/DocumentCheckTable.vue';
let FileInfoModal = class FileInfoModal extends Vue {
    constructor() {
        super(...arguments);
        this.activeTab = 0;
    }
    get fileTypeDisplay() {
        return this.file.type ? FILE_TYPES_LABELS[this.file.type] : '';
    }
    get allChecks() {
        var _a, _b;
        return (_b = (_a = this.file.documentAnalyse) === null || _a === void 0 ? void 0 : _a.checks) !== null && _b !== void 0 ? _b : [];
    }
    get extracts() {
        return this.allChecks
            .filter((check) => check.type === DocumentCheckType.Extract)
            .sort((a, b) => b.priority - a.priority);
    }
    get checks() {
        return this.allChecks
            .filter((check) => check.type === DocumentCheckType.Check)
            .sort((a, b) => b.priority - a.priority);
    }
    sortBySection(data) {
        const checksBySection = {};
        data.forEach((check) => {
            var _a;
            const section = (_a = check.section) !== null && _a !== void 0 ? _a : 'none';
            if (!(section in checksBySection))
                checksBySection[section] = [];
            checksBySection[section].push(check);
        });
        return checksBySection;
    }
    get extractsBySection() {
        return this.sortBySection(this.extracts);
    }
    get checksBySection() {
        return this.sortBySection(this.checks);
    }
    get extractNoneSection() {
        var _a;
        return ((_a = Object.entries(this.extractsBySection).find((data) => data[0] === 'none')) !== null && _a !== void 0 ? _a : [
            'none',
            [],
        ])[1];
    }
    get checkNoneSection() {
        var _a;
        return ((_a = Object.entries(this.checksBySection).find((data) => data[0] === 'none')) !== null && _a !== void 0 ? _a : [
            'none',
            [],
        ])[1];
    }
    get extractsOtherSections() {
        return Object.fromEntries(Object.entries(this.extractsBySection).filter((property) => property[0] !== 'none'));
    }
    get checksOtherSections() {
        return Object.fromEntries(Object.entries(this.checksBySection).filter((property) => property[0] !== 'none'));
    }
};
FileInfoModal = __decorate([
    Component({
        components: { DocumentCheckTable, FileViewer },
        props: {
            file: {
                type: Object,
                default: null,
            },
        },
    })
], FileInfoModal);
export default FileInfoModal;
