var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { formatDateTime } from '@shared/utils/date';
const claim = namespace('claim');
let ArtisanAppointmentButton = class ArtisanAppointmentButton extends Vue {
    get isLoading() {
        return this.isInfoLoading || this.isClaimUpdating;
    }
    get claimHasRen() {
        var _a;
        return (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting.hasCertifiedArtisanCompensationMode;
    }
    get isDisabled() {
        return !!this.info && !this.claimHasRen;
    }
    get renEmailSent() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.renEmailSent) !== null && _b !== void 0 ? _b : null;
    }
    confirmSendRenEmail() {
        if (this.renEmailSent) {
            this.$buefy.dialog.confirm({
                animation: 'fade',
                title: 'Rdv REN',
                message: `Attention, un email a déjà été envoyé le ${formatDateTime(this.renEmailSent)}, voulez vous réémettre ?`,
                cancelText: 'Annuler',
                confirmText: 'Confirmer',
                onConfirm: () => this.sendRenEmail(),
            });
        }
        else {
            this.sendRenEmail();
        }
    }
    async sendRenEmail() {
        this.addDraftChanges({
            shouldResendRenEmail: true,
        });
        await this.saveChanges();
        this.$buefy.toast.open({
            message: 'Email envoyé avec succès.',
        });
    }
};
__decorate([
    claim.State('info')
], ArtisanAppointmentButton.prototype, "info", void 0);
__decorate([
    claim.State('isInfoLoading')
], ArtisanAppointmentButton.prototype, "isInfoLoading", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], ArtisanAppointmentButton.prototype, "isClaimUpdating", void 0);
__decorate([
    claim.Action('addDraftChanges')
], ArtisanAppointmentButton.prototype, "addDraftChanges", void 0);
__decorate([
    claim.Action('saveChanges')
], ArtisanAppointmentButton.prototype, "saveChanges", void 0);
ArtisanAppointmentButton = __decorate([
    Component
], ArtisanAppointmentButton);
export default ArtisanAppointmentButton;
