var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { PERSONAL_PROPERTY_CATEGORY_XML_LABEL_TRAD_KEYS } from '@backoffice/constants/contractConstants';
import { ContractCategoryConditionsType, RefundCeilingType, } from '@shared/types/api/contract';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
import { formatOptionalPercentage } from '@shared/utils/formatPercentage';
import { LayoutElementStatus } from '@shared/utils/layout';
import { EMPTY } from '@shared/utils/strings';
import formatOptionalAmount from '@shared/utils/formatOptionalAmount';
import formatAmount from '@shared/utils/formatAmount';
const claim = namespace('claim');
function getMostSpecific(condition) {
    if (condition.specificConditionForClaimType) {
        return condition.specificConditionForClaimType;
    }
    if (condition.specificCondition) {
        return condition.specificCondition;
    }
    if (condition.generalConditionForClaimType) {
        return condition.generalConditionForClaimType;
    }
    if (condition.generalCondition) {
        return condition.generalCondition;
    }
    return EMPTY;
}
function ceilingAndDeductibleFromConditions(contractCategoryConditions) {
    const deductible = {
        generalCondition: null,
        generalConditionForClaimType: null,
        specificCondition: null,
        specificConditionForClaimType: null,
    };
    const obsolescenceCeiling = {
        generalCondition: null,
        generalConditionForClaimType: null,
        specificCondition: null,
        specificConditionForClaimType: null,
    };
    const refundCeiling = {
        generalCondition: null,
        generalConditionForClaimType: null,
        specificCondition: null,
        specificConditionForClaimType: null,
    };
    if (!contractCategoryConditions) {
        return { deductible, obsolescenceCeiling, refundCeiling };
    }
    for (const deductibleCondition of contractCategoryConditions.refundDeductibles) {
        if (deductibleCondition.isPerGuarantee && deductibleCondition.isSpecificCondition) {
            deductible.specificConditionForClaimType = formatOptionalCentsAmount(deductibleCondition.amount);
            continue;
        }
        if (!deductibleCondition.isPerGuarantee && deductibleCondition.isSpecificCondition) {
            deductible.specificCondition = formatOptionalCentsAmount(deductibleCondition.amount);
            continue;
        }
        if (deductibleCondition.isPerGuarantee && !deductibleCondition.isSpecificCondition) {
            deductible.generalConditionForClaimType = formatOptionalCentsAmount(deductibleCondition.amount);
            continue;
        }
        deductible.generalCondition = formatOptionalCentsAmount(deductibleCondition.amount);
    }
    for (const obsolescenceCeilingCondition of contractCategoryConditions.refundObsolescenceRatioCeilings) {
        if (obsolescenceCeilingCondition.isPerGuarantee &&
            obsolescenceCeilingCondition.isSpecificCondition) {
            obsolescenceCeiling.specificConditionForClaimType = formatOptionalPercentage(obsolescenceCeilingCondition.value);
            continue;
        }
        if (!obsolescenceCeilingCondition.isPerGuarantee &&
            obsolescenceCeilingCondition.isSpecificCondition) {
            obsolescenceCeiling.specificCondition = formatOptionalPercentage(obsolescenceCeilingCondition.value);
            continue;
        }
        if (obsolescenceCeilingCondition.isPerGuarantee &&
            !obsolescenceCeilingCondition.isSpecificCondition) {
            obsolescenceCeiling.generalConditionForClaimType = formatOptionalPercentage(obsolescenceCeilingCondition.value);
            continue;
        }
        obsolescenceCeiling.generalCondition = formatOptionalPercentage(obsolescenceCeilingCondition.value);
    }
    for (const ceilingCondition of contractCategoryConditions.refundCeilings) {
        const formatValue = ceilingCondition.valueType === RefundCeilingType.Percentage
            ? formatOptionalPercentage
            : formatOptionalCentsAmount;
        if (ceilingCondition.isPerGuarantee && ceilingCondition.isSpecificCondition) {
            refundCeiling.specificConditionForClaimType = formatValue(ceilingCondition.value);
            continue;
        }
        if (!ceilingCondition.isPerGuarantee && ceilingCondition.isSpecificCondition) {
            refundCeiling.specificCondition = formatValue(ceilingCondition.value);
            continue;
        }
        if (ceilingCondition.isPerGuarantee && !ceilingCondition.isSpecificCondition) {
            refundCeiling.generalConditionForClaimType = formatValue(ceilingCondition.value);
            continue;
        }
        refundCeiling.generalCondition = formatValue(ceilingCondition.value);
    }
    return { deductible, obsolescenceCeiling, refundCeiling };
}
function contractCategoryConditions(conditions, categoryType) {
    var _a;
    return ((_a = conditions.find(({ type }) => type === categoryType)) !== null && _a !== void 0 ? _a : null);
}
let ApplicableContractConditions = class ApplicableContractConditions extends Vue {
    get generalContractCategoryConditions() {
        return this.contractCategoriesConditions.find((contractCategoryConditions) => {
            return contractCategoryConditions.type === ContractCategoryConditionsType.General;
        });
    }
    get realEstateGeneralContractCategoryConditions() {
        return this.contractCategoriesConditions.find((contractCategoryConditions) => {
            return contractCategoryConditions.type === ContractCategoryConditionsType.RealEstateGeneral;
        });
    }
    get personalPropertiesGeneralContractCategoryConditions() {
        return this.contractCategoriesConditions.find((contractCategoryConditions) => {
            return (contractCategoryConditions.type === ContractCategoryConditionsType.PersonalPropertyGeneral);
        });
    }
    get deductiblesAndCeilingsLines() {
        const allConditions = this.contractCategoriesConditions;
        const general = ceilingAndDeductibleFromConditions(contractCategoryConditions(allConditions, ContractCategoryConditionsType.General));
        const realEstate = ceilingAndDeductibleFromConditions(contractCategoryConditions(allConditions, ContractCategoryConditionsType.RealEstateGeneral));
        const personalProperties = ceilingAndDeductibleFromConditions(contractCategoryConditions(allConditions, ContractCategoryConditionsType.PersonalPropertyGeneral));
        return [
            {
                label: 'Franchise',
                general: getMostSpecific(general.deductible),
                realEstate: getMostSpecific(realEstate.deductible),
                personalProperties: getMostSpecific(personalProperties.deductible),
            },
            {
                label: 'Valeur à Neuf',
                general: getMostSpecific(general.obsolescenceCeiling),
                realEstate: getMostSpecific(realEstate.obsolescenceCeiling),
                // Personal property "anew" option is not yet handled correctly in backend contract parsing
                personalProperties: EMPTY,
            },
            {
                label: 'Plafond de paiement',
                general: getMostSpecific(general.refundCeiling),
                realEstate: getMostSpecific(realEstate.refundCeiling),
                personalProperties: getMostSpecific(personalProperties.refundCeiling),
            },
        ];
    }
    get contractCategoriesConditions() {
        var _a, _b, _c;
        const claimTypeId = (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.type) === null || _b === void 0 ? void 0 : _b.id;
        if (!this.contract || !claimTypeId) {
            return [];
        }
        const applicableDetails = this.contract.applicableContractDetails.find(({ claimType }) => claimType.id === claimTypeId);
        return (_c = applicableDetails === null || applicableDetails === void 0 ? void 0 : applicableDetails.contractCategoriesConditions) !== null && _c !== void 0 ? _c : [];
    }
    get personalPropertiesContractCategoryConditions() {
        return this.contractCategoriesConditions
            .filter((contractCategoryConditions) => {
            return contractCategoryConditions.type === ContractCategoryConditionsType.PersonalProperty;
        })
            .sort((firstContractCategoryConditions, secondContractCategoryConditions) => {
            return firstContractCategoryConditions.insuranceContractXmlLabel.localeCompare(secondContractCategoryConditions.insuranceContractXmlLabel);
        });
    }
    get personalPropertyCategoryLines() {
        var _a;
        const personalPropertyCategories = (_a = this.contractData) === null || _a === void 0 ? void 0 : _a.contractPersonalPropertyCategories;
        if (!personalPropertyCategories) {
            return [];
        }
        const personalPropertyCategoriesById = personalPropertyCategories.reduce((acc, personalPropertyCategory) => {
            acc[personalPropertyCategory.id] = personalPropertyCategory;
            return acc;
        }, {});
        // Index categories by XML label to compute a list of displayed label
        const personalPropertyCategoriesByXmlLabel = personalPropertyCategories.reduce((result, current) => {
            if (!(current.id in result)) {
                result[current.id] = [current.designation];
                return result;
            }
            result[current.id].push(current.designation);
            return result;
        }, {});
        return (this.personalPropertiesContractCategoryConditions
            // Some condition do not have an associated insuranceContractXmlLabel
            .filter((condition) => !!condition.insuranceContractXmlLabel)
            .map((personalPropertyContractCategoryConditions) => {
            var _a, _b;
            const tradKey = PERSONAL_PROPERTY_CATEGORY_XML_LABEL_TRAD_KEYS[personalPropertyContractCategoryConditions.insuranceContractXmlLabel];
            const contractCategory = tradKey && this.$te(tradKey)
                ? this.$tc(tradKey)
                : personalPropertyContractCategoryConditions.insuranceContractXmlLabel;
            let exclusion;
            let refundCeiling;
            let status;
            if (personalPropertyCategoriesById[personalPropertyContractCategoryConditions.insuranceContractXmlLabel]) {
                exclusion = personalPropertyCategoriesById[personalPropertyContractCategoryConditions.insuranceContractXmlLabel].included
                    ? 'Inclus'
                    : 'Exclus';
                const ceilings = (_a = personalPropertyCategoriesById[personalPropertyContractCategoryConditions.insuranceContractXmlLabel].ceiling) === null || _a === void 0 ? void 0 : _a.values;
                const hasCeiling = ceilings !== undefined && ceilings.length > 0;
                refundCeiling = EMPTY;
                if (hasCeiling) {
                    const refundCeilings = ceilings.map((ceiling) => {
                        if (ceiling.method === 'fixe') {
                            return formatAmount(ceiling.arg);
                        }
                        else {
                            return ceiling.method;
                        }
                    });
                    refundCeiling = refundCeilings.join(', ');
                }
                status = !personalPropertyCategoriesById[personalPropertyContractCategoryConditions.insuranceContractXmlLabel].included
                    ? LayoutElementStatus.Ko
                    : hasCeiling
                        ? LayoutElementStatus.Highlighted
                        : null;
            }
            else {
                exclusion = personalPropertyContractCategoryConditions.isExcluded
                    ? 'Exclus'
                    : 'Inclus';
                const ceilingsAndDeductible = ceilingAndDeductibleFromConditions(personalPropertyContractCategoryConditions);
                refundCeiling = getMostSpecific(ceilingsAndDeductible.refundCeiling);
                status = personalPropertyContractCategoryConditions.isExcluded
                    ? LayoutElementStatus.Ko
                    : refundCeiling !== EMPTY
                        ? LayoutElementStatus.Highlighted
                        : null;
            }
            const claimsCategories = ((_b = personalPropertyCategoriesByXmlLabel[personalPropertyContractCategoryConditions.insuranceContractXmlLabel]) !== null && _b !== void 0 ? _b : [EMPTY]).join(', ');
            return {
                contractCategory,
                claimsCategories,
                exclusion,
                refundCeiling,
                status,
            };
        }));
    }
};
__decorate([
    claim.State('info')
], ApplicableContractConditions.prototype, "info", void 0);
__decorate([
    claim.State('contractData')
], ApplicableContractConditions.prototype, "contractData", void 0);
__decorate([
    claim.State('contract')
], ApplicableContractConditions.prototype, "contract", void 0);
__decorate([
    claim.State('isContractLoading')
], ApplicableContractConditions.prototype, "isContractLoading", void 0);
ApplicableContractConditions = __decorate([
    Component({
        methods: { formatOptionalAmount },
    })
], ApplicableContractConditions);
export default ApplicableContractConditions;
