var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ChargeModal from '@backoffice/components/claim/compensation/ChargeModal.vue';
import ModelForm from '@backoffice/components/claim/ModelForm.vue';
import { CHARGE_TYPOLOGY_TRAD_KEYS } from '@shared/charges/chargeConstants';
import { EMPTY } from '@shared/utils/strings';
const claim = namespace('claim');
let ClaimDamages = class ClaimDamages extends Vue {
    constructor() {
        super(...arguments);
        this.modalOpen = false;
    }
    get charges() {
        return this.currentCharges
            .slice()
            .sort((a, b) => a.clientIdentifier.localeCompare(b.clientIdentifier));
    }
    get initialCharges() {
        var _a, _b;
        return (_b = (_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.counting.charges) !== null && _b !== void 0 ? _b : [];
    }
    get draftCharges() {
        var _a, _b;
        const draftCounting = (_a = this.draftPayload) === null || _a === void 0 ? void 0 : _a.counting;
        return (_b = draftCounting === null || draftCounting === void 0 ? void 0 : draftCounting.charges) !== null && _b !== void 0 ? _b : [];
    }
    get currentCharges() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting.charges) !== null && _b !== void 0 ? _b : [];
    }
    get chargesEntries() {
        return this.currentCharges.concat(this.draftCharges).map((c) => [c.clientIdentifier, c]);
    }
    isNew(charge) {
        return !this.initialCharges.some((c) => c.clientIdentifier === charge.clientIdentifier);
    }
    thAttrs(column) {
        switch (column.field) {
            default:
                return null;
        }
    }
    tdAttrs(_row, column) {
        switch (column.field) {
            case 'typology':
                return { class: 'is-narrow' };
            case 'amount':
                return { class: 'has-text-right' };
            default:
                return null;
        }
    }
    addCharge() {
        const modal = this.$buefy.modal.open({
            parent: this,
            component: ChargeModal,
            hasModalCard: true,
            trapFocus: true,
            events: {
                confirm: (payload) => {
                    this.saveCharge(payload);
                    modal.close();
                },
            },
        });
    }
    editCharge(charge) {
        const modal = this.$buefy.modal.open({
            parent: this,
            component: ChargeModal,
            hasModalCard: true,
            trapFocus: true,
            props: { initialCharge: charge },
            events: {
                confirm: (payload) => {
                    this.saveCharge(payload);
                    modal.close();
                },
            },
        });
    }
    removeCharge(charge) {
        this.deleteCharge(charge);
    }
    get totalCharges() {
        return this.charges.reduce((acc, charge) => { var _a; return acc + ((_a = charge.amount) !== null && _a !== void 0 ? _a : 0); }, 0);
    }
    async deleteCharge(charge) {
        const charges = new Map(this.chargesEntries);
        charges.delete(charge.clientIdentifier);
        await this.updateCharges(charges.values());
    }
    async saveCharge(charge) {
        const charges = new Map(this.chargesEntries);
        charges.set(charge.clientIdentifier, charge);
        await this.updateCharges(charges.values());
    }
    async updateCharges(charges) {
        var _a, _b;
        try {
            await this.updateDraftPayload({
                id: (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.id,
                counting: {
                    id: (_b = this.info) === null || _b === void 0 ? void 0 : _b.claim.counting.id,
                    charges: Array.from(charges),
                },
            });
        }
        catch (err) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
    }
};
__decorate([
    claim.State('info')
], ClaimDamages.prototype, "info", void 0);
__decorate([
    claim.State('draftPayload')
], ClaimDamages.prototype, "draftPayload", void 0);
__decorate([
    claim.State('initialClaim')
], ClaimDamages.prototype, "initialClaim", void 0);
__decorate([
    claim.State('isEditingClaim')
], ClaimDamages.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Getter('modelFieldsContext')
], ClaimDamages.prototype, "modelFieldsContext", void 0);
__decorate([
    claim.Getter('validationConstraintsContext')
], ClaimDamages.prototype, "validationConstraintsContext", void 0);
__decorate([
    claim.Action('createEntity')
], ClaimDamages.prototype, "createEntity", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], ClaimDamages.prototype, "updateDraftPayload", void 0);
__decorate([
    claim.Action('draftChanges')
], ClaimDamages.prototype, "draftChanges", void 0);
ClaimDamages = __decorate([
    Component({
        middleware: 'hasAccess',
        components: { ModelForm, ChargeModal },
        filters: {
            typologyFilter(typology) {
                return CHARGE_TYPOLOGY_TRAD_KEYS[typology];
            },
            labelFilter(label) {
                return label || EMPTY;
            },
            amountFilter(amount) {
                return typeof amount === 'number' ? (amount / 100).toFixed(2) + '€' : EMPTY;
            },
        },
        nuxtI18n: {
            paths: {
                en: '/claims/:id/management-fees',
            },
        },
    })
], ClaimDamages);
export default ClaimDamages;
