var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import FormDeclarationGenerique from './form-declaration/form-declaration-generique.vue';
import FormDeclarationSimplifieeDpt from './form-declaration/form-declaration-departement.vue';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
let NewClaim = class NewClaim extends Vue {
    get declarationComponent() {
        return insurerHasFeature(InsurerFeatureFlag.CreateClaimByDepartment)
            ? 'FormDeclarationSimplifieeDpt'
            : 'FormDeclarationGenerique';
    }
    get canCloseFromCloseManagementButton() {
        return insurerHasFeature(InsurerFeatureFlag.CloseClaimDirectlyFromManagementBar);
    }
    cancel() {
        this.$router.push(this.localePath({ name: 'declarations' }));
    }
};
NewClaim = __decorate([
    Component({
        middleware: 'hasAccess',
        components: {
            FormDeclarationGenerique,
            FormDeclarationSimplifieeDpt,
        },
    })
], NewClaim);
export default NewClaim;
