import { render, staticRenderFns } from "./PersonalPropertyCatalogItem.vue?vue&type=template&id=4738b351&scoped=true"
import script from "./PersonalPropertyCatalogItem.vue?vue&type=script&lang=ts"
export * from "./PersonalPropertyCatalogItem.vue?vue&type=script&lang=ts"
import style0 from "./PersonalPropertyCatalogItem.vue?vue&type=style&index=0&id=4738b351&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4738b351",
  null
  
)

export default component.exports