var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ModelInfo from '@backoffice/components/claim/ModelInfo.vue';
import { droughtContractFields, droughtEnvironmentFields, droughtHousingFields, } from '@shared/qualification/droughtQualificationFields';
const claim = namespace('claim');
let DroughtDamageQualification = class DroughtDamageQualification extends Vue {
    get modelFieldsContext() {
        return this.modelFieldsContextGetter(this.$tc.bind(this), false);
    }
    get contractFields() {
        const context = this.modelFieldsContext;
        if (!context || !this.heliosDeclaration) {
            return null;
        }
        return droughtContractFields(this.heliosDeclaration, context);
    }
    get housingFields() {
        const context = this.modelFieldsContext;
        if (!context || !this.heliosDeclaration) {
            return null;
        }
        return droughtHousingFields(this.heliosDeclaration, context);
    }
    get environmentFields() {
        const context = this.modelFieldsContext;
        if (!context || !this.heliosDeclaration) {
            return null;
        }
        return droughtEnvironmentFields(this.heliosDeclaration, context);
    }
};
__decorate([
    claim.Getter('modelFieldsContext')
], DroughtDamageQualification.prototype, "modelFieldsContextGetter", void 0);
__decorate([
    claim.State('info')
], DroughtDamageQualification.prototype, "info", void 0);
__decorate([
    claim.State('heliosDeclaration')
], DroughtDamageQualification.prototype, "heliosDeclaration", void 0);
__decorate([
    claim.State('heliosError')
], DroughtDamageQualification.prototype, "heliosError", void 0);
DroughtDamageQualification = __decorate([
    Component({ components: { ModelInfo } })
], DroughtDamageQualification);
export default DroughtDamageQualification;
