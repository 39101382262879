var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
let SynthesisCard = class SynthesisCard extends Vue {
    constructor() {
        super(...arguments);
        this.isLongValueDisplayed = false;
    }
    get nav() {
        return this.cardData.nav;
    }
    toggleLongValue() {
        this.isLongValueDisplayed = true;
    }
    navigate() {
        if (!this.nav)
            return;
        this.$goToLocalePath({
            name: `declarations-id-${this.nav.tab}`,
            params: { id: this.claimId },
            hash: this.nav.anchor,
        });
    }
};
__decorate([
    Prop({ type: Object, required: true })
], SynthesisCard.prototype, "cardData", void 0);
__decorate([
    Prop({ type: String, required: true })
], SynthesisCard.prototype, "claimId", void 0);
SynthesisCard = __decorate([
    Component
], SynthesisCard);
export default SynthesisCard;
