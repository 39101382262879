var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
class ProductSearchService {
    constructor() {
        this.selectedSearchItem = null;
        this.searchLoading = false;
        this.searchError = null;
        this.searchResponse = null;
        this.detailsLoading = false;
        this.detailsError = null;
        this.detailsResponse = null;
    }
    async executeSearch(query, axios) {
        this.searchLoading = true;
        this.searchError = null;
        this.searchResponse = null;
        this.selectedSearchItem = null;
        this.detailsError = null;
        this.detailsResponse = null;
        try {
            this.searchResponse = await this.getSearchResponse(query, axios);
        }
        catch (err) {
            if (err instanceof Error) {
                this.searchError = err.message;
            }
            else {
                throw err;
            }
        }
        finally {
            this.searchLoading = false;
        }
    }
    async selectProduct(searchItem, axios) {
        this.detailsError = null;
        this.selectedSearchItem = searchItem;
        this.detailsLoading = true;
        try {
            this.detailsResponse = await this.getDetailsResponse(searchItem, axios);
        }
        catch (err) {
            if (err instanceof Error) {
                this.detailsError = err.message;
            }
            else {
                throw err;
            }
        }
        finally {
            this.detailsLoading = false;
        }
    }
    backToSearch() {
        this.selectedSearchItem = null;
        this.detailsResponse = null;
    }
}
class MainProductSearchService extends ProductSearchService {
    constructor() {
        super(...arguments);
        this.name = 'Upptec';
    }
    getSearchResponse(query, axios) {
        return axios.$get('/products/search', {
            params: { q: query },
        });
    }
    getDetailsResponse(item, axios) {
        return axios.$get(`/products/details/${item.productId}`);
    }
    get searchItemsInfo() {
        if (!this.searchResponse) {
            return [];
        }
        return this.searchResponse.results.map((item) => ({
            item,
            id: item.productId,
            category: item.groupName,
            image: item.image,
            name: `${item.brand} ${item.name}`,
        }));
    }
    get productInfo() {
        var _a, _b;
        if (!this.detailsResponse || !this.selectedSearchItem) {
            throw new Error('Item not loaded');
        }
        const productInfo = this.detailsResponse.productInfo.rawUpptecCandidates[0];
        const firstAlternativeIsSameProduct = !!this.selectedSearchItem &&
            (productInfo === null || productInfo === void 0 ? void 0 : productInfo.product.product_id) === ((_a = this.selectedSearchItem) === null || _a === void 0 ? void 0 : _a.productId);
        const alternatives = firstAlternativeIsSameProduct
            ? this.detailsResponse.productInfo.rawUpptecCandidates.slice(1)
            : this.detailsResponse.productInfo.rawUpptecCandidates;
        const productMarketInfo = firstAlternativeIsSameProduct
            ? {
                name: `${productInfo.product.brand} ${productInfo.product.name}`,
                prices: productInfo.store_products.map((product) => ({
                    store: product.store_name,
                    storeImage: product.store_image_url,
                    price: product.price.amount,
                    url: product.store_url,
                })),
            }
            : null;
        return {
            id: this.selectedSearchItem.productId,
            category: this.selectedSearchItem.groupName,
            image: this.selectedSearchItem.image,
            name: `${this.selectedSearchItem.brand} ${this.selectedSearchItem.name}`,
            info: [
                {
                    label: 'Marque',
                    value: this.selectedSearchItem.brand,
                },
                {
                    label: 'Catégorie',
                    value: this.selectedSearchItem.groupName,
                },
            ],
            isAvailable: firstAlternativeIsSameProduct
                ? ((_b = this.detailsResponse.productInfo.bestAlternative) === null || _b === void 0 ? void 0 : _b.marketPrice)
                    ? this.detailsResponse.productInfo.bestAlternative.marketPrice / 100
                    : null
                : null,
            isAlternativeAvailable: alternatives.length > 0,
            market: productMarketInfo,
            alternatives: alternatives.map((item) => {
                return {
                    name: `${item.product.brand} ${item.product.name}`,
                    prices: item.store_products.map((product) => ({
                        store: product.store_name,
                        storeImage: product.store_image_url,
                        price: product.price.amount,
                        url: product.store_url,
                    })),
                };
            }),
        };
    }
}
class AlternativeProductSearchService extends ProductSearchService {
    constructor() {
        super(...arguments);
        this.name = 'Schibsted';
    }
    getSearchResponse(query, axios) {
        return axios.$get('/products/third-party-alternative/search', {
            params: { q: query },
        });
    }
    getDetailsResponse(item, axios) {
        return axios.$get(`/products/third-party-alternative/details/${item.id}`);
    }
    get searchItemsInfo() {
        if (!this.searchResponse) {
            return [];
        }
        return this.searchResponse.products.map((item) => ({
            item,
            id: item.id,
            category: item.category.name,
            image: item.media.product_images.first ? item.media.product_images.first['280'] : null,
            name: item.name,
        }));
    }
    get productInfo() {
        if (!this.detailsResponse || !this.selectedSearchItem) {
            throw new Error('Item not loaded');
        }
        const marketPrices = this.detailsResponse.items
            // Exclude second hand items
            .filter((item) => item.type === 'normal' && item.store !== null);
        return {
            id: this.selectedSearchItem.id,
            category: this.selectedSearchItem.category.name,
            image: this.selectedSearchItem.media.product_images.first
                ? this.selectedSearchItem.media.product_images.first['280']
                : null,
            name: this.selectedSearchItem.name,
            info: [
                {
                    label: 'Marque',
                    value: this.selectedSearchItem.brand.name,
                },
                {
                    label: 'Catégorie',
                    value: this.selectedSearchItem.category.path.map((category) => category.name).join(' / '),
                },
            ],
            isAvailable: marketPrices.length > 0 ? marketPrices[0].price.excl_shipping : null,
            isAlternativeAvailable: this.detailsResponse.alternatives.length > 0,
            market: {
                name: this.selectedSearchItem.name,
                prices: marketPrices.map((item) => {
                    var _a, _b, _c;
                    return ({
                        price: item.price.excl_shipping,
                        store: ((_a = item.store) === null || _a === void 0 ? void 0 : _a.name) || '-',
                        url: item.external_uri,
                        storeImage: (_c = (((_b = item.store) === null || _b === void 0 ? void 0 : _b.logo) && item.store.logo['88'])) !== null && _c !== void 0 ? _c : null,
                    });
                }),
            },
            alternatives: this.detailsResponse.alternatives.map((alternative) => ({
                name: alternative.product.name,
                prices: [
                    {
                        price: alternative.product.price.regular,
                        store: `${alternative.product.price.count_regular} offres`,
                        url: alternative.product.price.web_uri,
                        storeImage: alternative.product.media.product_images.first
                            ? alternative.product.media.product_images.first['140']
                            : null,
                    },
                ],
            })),
        };
    }
}
let Products = class Products extends Vue {
    constructor() {
        super(...arguments);
        this.isSearching = false;
        this.searchQuery = '';
        this.main = new MainProductSearchService();
        this.alternative = new AlternativeProductSearchService();
    }
    async searchProduct() {
        if (this.isSearching) {
            return;
        }
        this.isSearching = true;
        try {
            await Promise.all([
                this.main.executeSearch(this.searchQuery, this.$axios),
                this.alternative.executeSearch(this.searchQuery, this.$axios),
            ]).catch();
        }
        finally {
            this.isSearching = false;
        }
    }
};
Products = __decorate([
    Component({
        middleware: 'hasAccess',
    })
], Products);
export default Products;
