var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FilePreview from '@backoffice/components/file/FilePreview.vue';
import { FILE_SIZE_LIMIT10_MBYTES } from '@shared/constants/fileConstants';
import { fetchFile } from '@shared/files/fetchFile';
import { isImage, isPdf } from '@shared/files/mimeTypes';
import { uploadFile } from '@shared/files/uploadFile';
import { resourceIri } from '@shared/utils/iri';
import { removeArrayItem } from '@shared/utils/removeArrayItem';
const claim = namespace('claim');
let FileCollectionForm = class FileCollectionForm extends Vue {
    constructor() {
        super(...arguments);
        this.uploadedAppFiles = null;
        this.isUploading = false;
        this.isFetching = false;
    }
    async onFileCollectionChange(fileCollection) {
        this.isFetching = false;
        this.isUploading = false;
        if (!fileCollection) {
            this.uploadedAppFiles = null;
            return;
        }
        this.uploadedAppFiles = fileCollection.uploadedAppFiles;
        // Some uploaded app file can be IRI (for lazy loading)
        const hasFilesToFetch = fileCollection.uploadedAppFiles.some((file) => typeof file === 'string');
        if (!hasFilesToFetch) {
            return;
        }
        this.isFetching = true;
        // Fetch lazily loaded files
        this.uploadedAppFiles = await Promise.all(fileCollection.uploadedAppFiles.map((uploadedAppFileValue) => typeof uploadedAppFileValue === 'string'
            ? fetchFile(uploadedAppFileValue, this.$axios)
            : uploadedAppFileValue));
        this.isFetching = false;
    }
    get hasFiles() {
        return !!this.uploadedAppFiles && this.uploadedAppFiles.length > 0;
    }
    get files() {
        if (!this.uploadedAppFiles) {
            return [];
        }
        return this.uploadedAppFiles.map((file) => typeof file === 'string'
            ? {
                key: file,
                isFetching: true,
                uploaded: null,
            }
            : {
                key: file.id,
                isFetching: false,
                uploaded: file,
            });
    }
    get mergedFile() {
        var _a;
        return ((_a = this.fileCollection) === null || _a === void 0 ? void 0 : _a.mergedFile)
            ? typeof this.fileCollection.mergedFile === 'string'
                ? {
                    key: this.fileCollection.mergedFile,
                    isFetching: true,
                    uploaded: null,
                }
                : {
                    key: this.fileCollection.mergedFile.id,
                    isFetching: false,
                    uploaded: this.fileCollection.mergedFile,
                }
            : null;
    }
    get fileList() {
        return this.isEditingClaim || !this.mergedFile ? this.files : [this.mergedFile];
    }
    get fileIris() {
        if (!this.uploadedAppFiles) {
            return [];
        }
        return this.uploadedAppFiles.map(resourceIri);
    }
    get uploadButtonLabel() {
        return this.addFileLabel && this.hasFiles ? this.addFileLabel : this.uploadLabel;
    }
    async onUpload(file) {
        var _a;
        const claimId = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.id;
        if (!claimId) {
            return;
        }
        if (file.size >= FILE_SIZE_LIMIT10_MBYTES) {
            this.$buefy.toast.open({
                type: 'is-danger',
                message: this.$tc('error.snackbar.upload.file_size_greater_than_10_mo'),
            });
            return;
        }
        if (!isImage(file.type) && !isPdf(file.type)) {
            this.$buefy.toast.open({
                type: 'is-danger',
                message: this.$tc('error.snackbar.upload.file_image_or_pdf'),
            });
            return;
        }
        this.isUploading = true;
        try {
            const uploaded = await uploadFile(file, claimId, this.claimFileType, this.$axios);
            this.update([...this.fileIris, uploaded.id]);
        }
        catch (_b) {
            this.$buefy.toast.open({
                type: 'is-danger',
                message: this.$tc('error.snackbar.info'),
            });
        }
        this.isUploading = false;
    }
    onDelete(index) {
        this.update(removeArrayItem(this.fileIris, index));
    }
    update(uploadedAppFiles) {
        var _a;
        this.$emit('update', {
            id: (_a = this.fileCollection) === null || _a === void 0 ? void 0 : _a.id,
            uploadedAppFiles,
        });
    }
};
__decorate([
    Prop({ type: Object, default: null })
], FileCollectionForm.prototype, "fileCollection", void 0);
__decorate([
    Prop({ type: String, required: true })
], FileCollectionForm.prototype, "uploadLabel", void 0);
__decorate([
    Prop({ type: String, required: true })
], FileCollectionForm.prototype, "emptyLabel", void 0);
__decorate([
    Prop({ type: String, default: null })
], FileCollectionForm.prototype, "addFileLabel", void 0);
__decorate([
    Prop({ type: String, required: true })
], FileCollectionForm.prototype, "claimFileType", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], FileCollectionForm.prototype, "canEdit", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], FileCollectionForm.prototype, "disabled", void 0);
__decorate([
    claim.State('info')
], FileCollectionForm.prototype, "info", void 0);
__decorate([
    claim.State('isEditingClaim')
], FileCollectionForm.prototype, "isEditingClaim", void 0);
__decorate([
    Watch('fileCollection', { immediate: true })
], FileCollectionForm.prototype, "onFileCollectionChange", null);
FileCollectionForm = __decorate([
    Component({ components: { FilePreview } })
], FileCollectionForm);
export default FileCollectionForm;
