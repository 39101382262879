var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModelFormField from '@backoffice/components/claim/ModelFormField.vue';
import ModelFieldControl from '@backoffice/components/claim/ModelFieldControl.vue';
let ModelField = class ModelField extends Vue {
    get inEdition() {
        var _a;
        return this.canEdit && 'editable' in this.field && !!((_a = this.field.editable) === null || _a === void 0 ? void 0 : _a.input);
    }
    get displayedValue() {
        return 'displayedValue' in this.field ? this.field.displayedValue : this.field.value;
    }
    onFieldUpdate(_field, value) {
        this.$emit('update', value);
    }
    onFieldSubmit(_field, value) {
        this.$emit('submit', value);
    }
};
__decorate([
    Prop({ type: Object, required: true })
], ModelField.prototype, "field", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ModelField.prototype, "canEdit", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ModelField.prototype, "disabled", void 0);
ModelField = __decorate([
    Component({ components: { ModelFormField, ModelFieldControl } })
], ModelField);
export default ModelField;
