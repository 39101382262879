var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import moment from 'moment';
import { EMPTY } from '@shared/utils/strings';
import { CLAIM_STATUS_TRAD_KEYS, getAllStatuses } from '@backoffice/claim/status';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { translateChoices } from '@shared/choice';
const claims = namespace('claims');
let ClaimsDeclarationsFilter = class ClaimsDeclarationsFilter extends Vue {
    constructor() {
        super(...arguments);
        this.isSearchable = false;
    }
    mounted() {
        if (Object.values(this.filters).some((val) => typeof val !== 'undefined')) {
            this.isSearchable = true;
        }
    }
    get searchable() {
        return this.isSearchable;
    }
    set searchable(value) {
        this.isSearchable = value;
        if (!value && Object.values(this.filters).some((val) => typeof val !== 'undefined')) {
            this.resetFilters();
            this.filter(this.filters);
        }
    }
    get from() {
        return this.filters.from ? new Date(this.filters.from.toString()) : undefined;
    }
    set from(from) {
        var _a;
        this.filter({ ...this.filters, from: (_a = from === null || from === void 0 ? void 0 : from.toISOString()) !== null && _a !== void 0 ? _a : undefined });
    }
    get to() {
        return this.filters.to ? new Date(this.filters.to.toString()) : undefined;
    }
    set to(to) {
        var _a;
        this.filter({ ...this.filters, to: (_a = to === null || to === void 0 ? void 0 : to.toISOString()) !== null && _a !== void 0 ? _a : undefined });
    }
    get type() {
        return this.filters.type ? this.filters.type.toString() : undefined;
    }
    set type(type) {
        var _a;
        this.filter({ ...this.filters, type: (_a = type === null || type === void 0 ? void 0 : type.toString()) !== null && _a !== void 0 ? _a : undefined });
    }
    get firstName() {
        return this.filters.firstName ? this.filters.firstName.toString() : undefined;
    }
    set firstName(firstName) {
        this.filter({ ...this.filters, firstName });
    }
    get lastName() {
        return this.filters.lastName ? this.filters.lastName.toString() : undefined;
    }
    set lastName(lastName) {
        this.filter({ ...this.filters, lastName });
    }
    get contract() {
        return this.filters.contract ? this.filters.contract.toString() : undefined;
    }
    set contract(contract) {
        this.filter({ ...this.filters, contract });
    }
    get caseNumber() {
        return this.filters.caseNumber ? this.filters.caseNumber.toString() : undefined;
    }
    set caseNumber(caseNumber) {
        this.filter({ ...this.filters, caseNumber });
    }
    get status() {
        var _a;
        return (_a = this.filters.status) !== null && _a !== void 0 ? _a : [];
    }
    set status(status) {
        this.filter({ ...this.filters, status });
    }
    get insurer() {
        var _a;
        return (_a = this.filters.insurer) !== null && _a !== void 0 ? _a : '';
    }
    set insurer(insurer) {
        this.filter({ ...this.filters, insurer });
    }
    get claimTypeOptions() {
        return Object.values(ClaimTypeTrigram)
            .filter((trigram) => trigram !== ClaimTypeTrigram.Empty)
            .map((trigram) => {
            return { value: trigram, label: this.$tc(`claim.type.${trigram}`) };
        });
    }
    claimStatusFilterLabel() {
        const statusList = this.filters.status;
        if (!(statusList === null || statusList === void 0 ? void 0 : statusList.length))
            return '';
        const statuses = statusList.map((status, i) => {
            const label = this.$tc(CLAIM_STATUS_TRAD_KEYS[status]);
            if (i > 0)
                return label.toLowerCase();
            return label;
        });
        if (statuses.length === 1)
            return statuses[0];
        else
            return `(${statuses.length}) ${statuses.join(', ')}`;
    }
    get claimStatusOptions() {
        return translateChoices(getAllStatuses(this.$config.insurerConfig), this.$i18n.tc.bind(this.$i18n)).sort((a, b) => a.label.localeCompare(b.label));
    }
};
__decorate([
    claims.State('filters')
], ClaimsDeclarationsFilter.prototype, "filters", void 0);
__decorate([
    claims.Action('filter')
], ClaimsDeclarationsFilter.prototype, "filter", void 0);
__decorate([
    claims.Action('loadClaims')
], ClaimsDeclarationsFilter.prototype, "loadClaims", void 0);
__decorate([
    claims.Mutation('RESET_FILTERS')
], ClaimsDeclarationsFilter.prototype, "resetFilters", void 0);
ClaimsDeclarationsFilter = __decorate([
    Component({
        filters: {
            anomalyScoreColor(value, anomalyScoreThreshold) {
                if (value < anomalyScoreThreshold / 3)
                    return 'is-success';
                else if (value < anomalyScoreThreshold)
                    return 'is-warning';
                else
                    return 'is-danger';
            },
            createdAt(value) {
                const date = moment(value);
                return date.format('DD/MM/YYYY');
            },
            claimType(value) {
                var _a;
                return (_a = value === null || value === void 0 ? void 0 : value.name) !== null && _a !== void 0 ? _a : EMPTY;
            },
            claimTypeColor(value) {
                var _a, _b;
                return `is-${(_b = (_a = value === null || value === void 0 ? void 0 : value.trigram) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : 'transparent'}`;
            },
            contactName(value) {
                return value.firstName + ' ' + value.lastName;
            },
            statusTradKey(value) {
                return CLAIM_STATUS_TRAD_KEYS[value];
            },
        },
        async fetch() {
            await this.loadClaims();
        },
    })
], ClaimsDeclarationsFilter);
export default ClaimsDeclarationsFilter;
