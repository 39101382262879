var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import FileCollectionForm from '@backoffice/components/file/FileCollectionForm.vue';
import { isFileCollectionModelField, } from '@shared/modelField/modelField';
import { EMPTY } from '@shared/utils/strings';
let ModelFileCollection = class ModelFileCollection extends Vue {
    constructor() {
        super(...arguments);
        this.empty = EMPTY;
    }
    get displayedValue() {
        if (!isFileCollectionModelField(this.field)) {
            throw new Error('Invalid model field type for ModelFileCollection. Should be "fileCollection"');
        }
        return this.field.displayedValue;
    }
};
ModelFileCollection = __decorate([
    Component({
        components: {
            FileCollectionForm,
        },
        props: {
            field: {
                type: Object,
                required: true,
            },
            canEdit: {
                type: Boolean,
                default: false,
            },
        },
    })
], ModelFileCollection);
export default ModelFileCollection;
