var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import FileViewer from '@backoffice/components/file/FileViewer.vue';
import { downloadFile } from '@backoffice/files/downloadFile';
import { fileObjectUrl } from '@shared/files/fileObjectUrl';
import { getFileExtension } from '@backoffice/files/getFileExtension';
import { isImageFile } from '@shared/files/mimeTypes';
import { DocumentAnalyseStatus } from '@shared/types/api/file';
import { uuidFromIri } from '@shared/utils/iri';
import { LOADING } from '@shared/utils/strings';
import FileInfoModal from '@backoffice/components/file/FileInfoModal.vue';
const claim = namespace('claim');
let FilePreview = class FilePreview extends Vue {
    constructor() {
        super(...arguments);
        this.loading = LOADING;
    }
    onDelete() {
        this.$emit('delete');
    }
    openPreview() {
        if (!this.file)
            return;
        if (isImageFile(this.file)) {
            this.$buefy.modal.open({
                parent: this,
                component: FileViewer,
                canCancel: true,
                props: { file: this.file },
            });
        }
        else {
            fileObjectUrl(this.file.url, this.$axios).then((objectUrl) => {
                window.open(objectUrl, '_blank');
            });
        }
    }
    openInfo() {
        if (this.file && this.file.documentAnalyse) {
            this.$buefy.modal.open({
                parent: this,
                component: FileInfoModal,
                hasModalCard: true,
                canCancel: ['escape', 'outside'],
                props: { file: this.file },
            });
        }
    }
    download() {
        if (!this.file)
            return;
        const uuid = uuidFromIri(this.file.id);
        const extension = getFileExtension(this.file.originalName);
        fileObjectUrl(this.file.url, this.$axios).then((objectUrl) => {
            downloadFile(objectUrl, `Claims-${uuid}${extension}`);
        });
    }
    get analyseStatus() {
        var _a, _b;
        if (this.file === null ||
            !('documentAnalyse' in this.file) ||
            this.file.documentAnalyse === null) {
            return 'none';
        }
        switch ((_b = (_a = this.file) === null || _a === void 0 ? void 0 : _a.documentAnalyse) === null || _b === void 0 ? void 0 : _b.status) {
            case DocumentAnalyseStatus.Uploaded:
            case DocumentAnalyseStatus.Ingested:
            case DocumentAnalyseStatus.ToVerify:
            case DocumentAnalyseStatus.ToRetryUpload:
                return 'processing';
            case DocumentAnalyseStatus.ToRetryCheck:
                return 'incomplete_data';
            case DocumentAnalyseStatus.Verified:
                return 'done';
            case DocumentAnalyseStatus.Rejected:
                return 'rejected';
            case DocumentAnalyseStatus.FailedUpload:
            case DocumentAnalyseStatus.Error:
                return 'error';
            default:
                return 'other';
        }
    }
};
__decorate([
    claim.State('info')
], FilePreview.prototype, "info", void 0);
FilePreview = __decorate([
    Component({
        components: { FileInfoModal, FileViewer },
        props: {
            file: {
                type: Object,
                required: false,
            },
            isFetching: {
                type: Boolean,
                required: true,
            },
            canDelete: {
                type: Boolean,
                default: false,
            },
        },
    })
], FilePreview);
export default FilePreview;
