var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { CONFIG } from '@shared/environment/environment';
let TemplateViewer = class TemplateViewer extends Vue {
    constructor() {
        super(...arguments);
        this.preview = '';
    }
};
TemplateViewer = __decorate([
    Component({
        props: {
            claim: {
                type: String,
                required: true,
            },
            template: {
                type: String,
                required: true,
            },
        },
        async fetch() {
            if (!this.claim)
                return;
            const url = new URL(`/debug/twig/${this.claim}`, CONFIG.apiUrl);
            this.preview = await this.$axios.$post(url.href, this.template);
        },
    })
], TemplateViewer);
export default TemplateViewer;
