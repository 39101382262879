var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { ContractStatus, HousingTypeSlug } from '@shared/types/api/contract';
import { CONTRACT_HOUSING_CAPACITIES_TRAD_KEYS, CONTRACT_STATUSES_TRAD_KEYS, HOUSING_TYPES_TRAD_KEYS, } from '@shared/contract/labels';
let ContractCard = class ContractCard extends Vue {
    constructor() {
        super(...arguments);
        this.statuses = ContractStatus;
        this.CONTRACT_STATUSES_TRAD_KEYS = CONTRACT_STATUSES_TRAD_KEYS;
        this.HOUSING_TYPES_TRAD_KEYS = HOUSING_TYPES_TRAD_KEYS;
        this.CONTRACT_HOUSING_CAPACITIES_TRAD_KEYS = CONTRACT_HOUSING_CAPACITIES_TRAD_KEYS;
        /* get info() {
          return `${this.contract.formula} - n°${this.contract.number}`
        }
      
        get shouldDisplayLivingArea(): boolean {
          return this.contract.specialConditions.livingArea !== null
        }
      
        get shouldDisplayRooms(): boolean {
          return this.contract.specialConditions.rooms !== null
        }
      
        get shouldDisplayRoomsAndLivingArea(): boolean {
          return this.shouldDisplayLivingArea && this.shouldDisplayRooms
        } */
    }
    get image() {
        return this.contract.housingType.slug === HousingTypeSlug.Apartment
            ? require('@declaration/assets/images/housing-type/appartment.svg')
            : require('@declaration/assets/images/housing-type/house.svg');
    }
    get title() {
        return this.nameFirst ? this.fullName : `Référence : ${this.contract.number}`;
    }
    get isSuspended() {
        return this.contract.status === ContractStatus.Suspended;
    }
    get isCancelled() {
        return this.contract.status === ContractStatus.Cancelled;
    }
    get fullName() {
        return `${this.contract.contact.firstName}  ${this.contract.contact.lastName}`;
    }
    get contractNumber() {
        return this.contract.formula;
    }
    get contractFormula() {
        return this.contract.formula;
    }
    get contractStatus() {
        return this.$tc(CONTRACT_STATUSES_TRAD_KEYS[this.contract.status]);
    }
    get address() {
        return this.contract.specialConditions.address;
    }
    get addressAffix() {
        return this.contract.specialConditions.addressAffix;
    }
    get zipCode() {
        return this.contract.specialConditions.zipcode;
    }
    get city() {
        return this.contract.specialConditions.city;
    }
    get housingType() {
        return this.$tc(HOUSING_TYPES_TRAD_KEYS[this.contract.housingType.slug]);
    }
    get livingArea() {
        const livingArea = Number(this.contract.specialConditions.livingArea);
        if (isNaN(livingArea))
            return null;
        return `${livingArea} m²`;
    }
    get rooms() {
        const rooms = Number(this.contract.specialConditions.rooms);
        if (isNaN(rooms))
            return null;
        if (rooms === 1)
            return '1 pièce';
        return `${rooms} pièces`;
    }
    get housingInfo() {
        let housingInfo = this.housingType;
        if (this.rooms) {
            housingInfo += ` ${this.rooms}`;
            if (this.livingArea)
                housingInfo += ' -';
        }
        if (this.livingArea)
            housingInfo += ` ${this.livingArea}`;
        return housingInfo;
    }
};
ContractCard = __decorate([
    Component({
        props: {
            contract: {
                type: Object,
                required: true,
            },
            clickable: {
                type: Boolean,
                default: false,
            },
            nameFirst: {
                type: Boolean,
                default: false,
            },
        },
    })
], ContractCard);
export default ContractCard;
