var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import ContractForm from '@backoffice/contract/ContractForm.vue';
import { UserRole } from '@shared/types/api/user';
import { uuidFromIri } from '@shared/utils/iri';
let NewClaim = class NewClaim extends Vue {
    constructor() {
        super(...arguments);
        this.createContract = false;
        this.userForm = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            roles: [],
        };
        this.contractForm = {
            insuranceContract: '',
            housingCapacity: '',
            housingType: '',
            contractNumber: '',
            formula: '',
            generalDeductibleAmount: 0,
            productLine: 0,
            address: '',
            zipcode: '',
            city: '',
            rooms: 0,
            livingArea: 0,
        };
        this.isCreatingUser = false;
    }
    get roleChoices() {
        return Object.values(UserRole).map((value) => {
            const key = value.toLowerCase().slice(5);
            return {
                value,
                label: this.$tc(`user.role.${key}`),
            };
        });
    }
    cancel() {
        this.$goToLocalePath({ name: 'utilisateurs' });
    }
    async createUser() {
        const payload = { ...this.userForm };
        if (this.createContract) {
            Object.assign(payload, this.contractForm);
        }
        try {
            this.isCreatingUser = true;
            const created = await this.$axios.$post('/user_creation', payload);
            if (created.type === 'validation_error')
                throw new Error(created.errors[0]);
            this.$buefy.toast.open({
                message: 'Le compte utilisateur a bien été créé',
                type: 'is-success',
                position: 'is-bottom',
            });
            const createdId = uuidFromIri(created.result.user.id);
            this.$goToLocalePath({ name: 'utilisateurs-id', params: { id: createdId } });
        }
        catch (err) {
            const message = err instanceof Error ? err.message : 'Une erreur est survenue';
            this.$buefy.toast.open({
                message,
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
        finally {
            this.isCreatingUser = false;
        }
    }
};
NewClaim = __decorate([
    Component({
        middleware: 'hasAccess',
        components: { ContractForm },
    })
], NewClaim);
export default NewClaim;
