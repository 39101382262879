var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let ErrorPage = class ErrorPage extends Vue {
    get statusCode() {
        var _a, _b;
        return (_b = (_a = this.error) === null || _a === void 0 ? void 0 : _a.statusCode) !== null && _b !== void 0 ? _b : 500;
    }
    get message() {
        var _a, _b;
        const message = (_b = (_a = this.error) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : `Une erreur est survenue`;
        if (this.$te(message))
            return this.$tc(message);
        return message;
    }
    get path() {
        var _a, _b;
        return (_b = (_a = this.error) === null || _a === void 0 ? void 0 : _a.path) !== null && _b !== void 0 ? _b : this.localePath({ name: 'index' });
    }
    get icon() {
        switch (this.statusCode) {
            case 404:
                return 'file-document-alert';
            case 403:
                return 'shield-lock';
            default:
                return 'alert';
        }
    }
};
ErrorPage = __decorate([
    Component({
        layout: 'fullscreen',
        props: {
            error: {
                type: Object,
                default: null,
            },
        },
    })
], ErrorPage);
export default ErrorPage;
