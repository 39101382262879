var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { uuidFromIri } from '@shared/utils/iri';
import ProvisionModal from '@backoffice/components/claim/compensation/ProvisionModal.vue';
const claim = namespace('claim');
let ClaimProvision = class ClaimProvision extends Vue {
    constructor() {
        super(...arguments);
        this.modal = false;
    }
    get claim() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim) !== null && _b !== void 0 ? _b : null;
    }
    get timeLineItems() {
        var _a, _b;
        return (_b = (_a = this.claim) === null || _a === void 0 ? void 0 : _a.timeLineItems) !== null && _b !== void 0 ? _b : [];
    }
    get history() {
        const history = this.timeLineItems
            .filter((item) => {
            return item.type === 'provision_update';
        })
            .map((item, index, array) => {
            var _a;
            return ({
                date: new Date(item.date),
                provision: item.data.provision,
                updatedBy: item.updatedBy,
                lastProvision: (_a = array[index - 1]) === null || _a === void 0 ? void 0 : _a.data.provision,
            });
        })
            .filter((obj) => obj.lastProvision !== obj.provision)
            .sort((a, b) => { var _a, _b, _c, _d; return ((_b = (_a = b.date) === null || _a === void 0 ? void 0 : _a.getTime()) !== null && _b !== void 0 ? _b : 0) - ((_d = (_c = a.date) === null || _c === void 0 ? void 0 : _c.getTime()) !== null && _d !== void 0 ? _d : 0); });
        return history;
    }
    get lastProvision() {
        var _a, _b;
        return (_b = (_a = this.history[0]) === null || _a === void 0 ? void 0 : _a.provision) !== null && _b !== void 0 ? _b : null;
    }
    get touched() {
        return this.lastProvision !== null && this.lastProvision !== this.provision;
    }
    editProvision() {
        const modal = this.$buefy.modal.open({
            parent: this,
            component: ProvisionModal,
            hasModalCard: true,
            events: {
                confirm: (provision) => {
                    if (this.provision !== provision) {
                        this.updateDraftPayload({ provision });
                    }
                    modal.close();
                },
            },
        });
    }
    restoreProvision() {
        this.updateDraftPayload({ provision: this.lastProvision });
    }
};
__decorate([
    claim.State('info')
], ClaimProvision.prototype, "info", void 0);
__decorate([
    claim.State('isEditingClaim')
], ClaimProvision.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], ClaimProvision.prototype, "isClaimUpdating", void 0);
__decorate([
    claim.Getter('provisionAmount')
], ClaimProvision.prototype, "provision", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], ClaimProvision.prototype, "updateDraftPayload", void 0);
ClaimProvision = __decorate([
    Component({
        middleware: 'hasAccess',
        nuxtI18n: {
            paths: {
                en: '/claims/:id/provision',
            },
        },
        filters: {
            updatedBy(updatedBy, currentUser) {
                if (!updatedBy)
                    return 'Provision originale';
                if (currentUser && uuidFromIri(updatedBy.id) === currentUser.id)
                    return 'Vous';
                return `${updatedBy.firstname} ${updatedBy.lastname}`;
            },
        },
    })
], ClaimProvision);
export default ClaimProvision;
