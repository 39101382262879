var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ForcableAmountValue from 'backoffice/components/claim/compensation/ForcableAmountValue.vue';
import { ClaimCompensationCellName, ClaimCompensationColumnName, ClaimCompensationSectionType, } from '@backoffice/types/store';
import formatAmount from '@shared/utils/formatAmount';
import { COMPENSATION_MODE_CHOICES } from '@backoffice/constants/refundConstants';
import { createEmptyRefund } from '@shared/transformer/refundAmountTransformer';
import { computeCompensationDetails } from '@backoffice/compensation/compensationDetails';
import { CustomDamageType } from '@shared/types/api/customDamage';
import CustomDamageRowInputs from '@backoffice/components/claim/compensation/tables/CustomDamageRowInputs.vue';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
const claim = namespace('claim');
let DamageTableRow = class DamageTableRow extends Vue {
    constructor() {
        super(...arguments);
        this.active = false;
        this.cells = this.row.cells;
    }
    get counting() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting) !== null && _b !== void 0 ? _b : null;
    }
    get initialCustomDamages() {
        var _a, _b;
        return (_b = (_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.counting.customDamages) !== null && _b !== void 0 ? _b : [];
    }
    initialCustomDamagesFiltered(customDamageType) {
        var _a, _b, _c;
        const damages = (_b = (_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.counting.customDamages) !== null && _b !== void 0 ? _b : [];
        return ((_c = damages.filter((damage) => {
            return damage.type === customDamageType;
        })) !== null && _c !== void 0 ? _c : []);
    }
    draftCustomDamagesFiltered(customDamageType) {
        var _a;
        return ((_a = this.draftCustomDamages.filter((damage) => {
            return damage.type === customDamageType;
        })) !== null && _a !== void 0 ? _a : []);
    }
    get draftCustomDamages() {
        var _a, _b;
        const counting = (_a = this.draftPayload) === null || _a === void 0 ? void 0 : _a.counting;
        return (_b = counting === null || counting === void 0 ? void 0 : counting.customDamages) !== null && _b !== void 0 ? _b : [];
    }
    get filteredCustomDamages() {
        return this.customDamages.filter((damage) => {
            return damage.type === this.tableType;
        });
    }
    get customDamages() {
        // si on a déjà modifié les customDamages mais qu'on les a tous supprimés
        if (this.draftPayload !== null && this.draftCustomDamages.length === 0) {
            return this.draftCustomDamages;
        }
        return this.draftCustomDamages.length > 0 ? this.draftCustomDamages : this.initialCustomDamages;
    }
    get tableType() {
        if (this.sectionType === ClaimCompensationSectionType.REALESTATEDAMAGES) {
            return CustomDamageType.realEstate;
        }
        else if (this.sectionType === ClaimCompensationSectionType.PERSONALPROPERTIESDAMAGES) {
            return CustomDamageType.personalProperties;
        }
        return null;
    }
    get shouldDisplayRow() {
        if (this.isEditingClaim) {
            if (this.isCustomDamageRow || this.rowHasForcedAmountsSubRows) {
                return false;
            }
        }
        return true;
    }
    get compensationDetails() {
        if (!this.info)
            return null;
        return computeCompensationDetails(this.info, this.$tc.bind(this));
    }
    get totalDamagesRefund() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.counting) === null || _a === void 0 ? void 0 : _a.damagesGroup) === null || _b === void 0 ? void 0 : _b.refund) !== null && _c !== void 0 ? _c : createEmptyRefund();
    }
    get isDeductibleForced() {
        var _a, _b;
        return (((_a = this.compensationDetails) === null || _a === void 0 ? void 0 : _a.counting.forcedDeductibleAmount) !== null ||
            ((_b = this.compensationDetails) === null || _b === void 0 ? void 0 : _b.refund.parameters.deductibleAmount.forcedValue) !== null);
    }
    get isImmediateForced() {
        var _a, _b;
        return (((_a = this.compensationDetails) === null || _a === void 0 ? void 0 : _a.counting.forcedImmediateAmount) !== null ||
            ((_b = this.compensationDetails) === null || _b === void 0 ? void 0 : _b.refund.compensation.immediate.forcedValue) !== null);
    }
    get isDelayedForced() {
        var _a, _b;
        return (((_a = this.compensationDetails) === null || _a === void 0 ? void 0 : _a.counting.forcedDelayedAmount) !== null ||
            ((_b = this.compensationDetails) === null || _b === void 0 ? void 0 : _b.refund.compensation.delayed.forcedValue) !== null);
    }
    // ------------------------ DATAS FORCING
    get forcedCompensation() {
        var _a;
        return (_a = this.forcedCompensationMode) !== null && _a !== void 0 ? _a : null;
    }
    set forcedCompensation(compensation) {
        var _a;
        this.$emit('update:forced-compensation-mode', {
            mode: compensation,
            type: (_a = this.row.data) === null || _a === void 0 ? void 0 : _a.damageType,
        });
    }
    get forcedDelayed() {
        var _a, _b, _c;
        return ((_a = this.compensationDetails) === null || _a === void 0 ? void 0 : _a.counting.forcedDelayedAmount)
            ? ((_b = this.compensationDetails) === null || _b === void 0 ? void 0 : _b.counting.forcedDelayedAmount) / 100
            : (_c = this.compensationDetails) === null || _c === void 0 ? void 0 : _c.counting.forcedDelayedAmount;
    }
    set forcedDelayed(amount) {
        this.$emit('forced-delayed-amount', amount !== '' ? Number(amount) * 100 : null);
    }
    get forcedImmediate() {
        var _a, _b, _c;
        return ((_a = this.compensationDetails) === null || _a === void 0 ? void 0 : _a.counting.forcedImmediateAmount)
            ? ((_b = this.compensationDetails) === null || _b === void 0 ? void 0 : _b.counting.forcedImmediateAmount) / 100
            : (_c = this.compensationDetails) === null || _c === void 0 ? void 0 : _c.counting.forcedImmediateAmount;
    }
    set forcedImmediate(amount) {
        this.$emit('forced-immediate-amount', amount !== '' ? Number(amount) * 100 : null);
    }
    get forcedDeductible() {
        var _a, _b, _c;
        return ((_a = this.compensationDetails) === null || _a === void 0 ? void 0 : _a.counting.forcedDeductibleAmount)
            ? ((_b = this.compensationDetails) === null || _b === void 0 ? void 0 : _b.counting.forcedDeductibleAmount) / 100
            : (_c = this.compensationDetails) === null || _c === void 0 ? void 0 : _c.counting.forcedDeductibleAmount;
    }
    set forcedDeductible(amount) {
        this.$emit('forced-deductible-amount', amount !== '' ? Number(amount) * 100 : null);
    }
    resetForcedAmounts() {
        this.$emit('reset-forced-amounts');
    }
    get forcedAmountComment() {
        var _a, _b;
        return (_b = (_a = this.counting) === null || _a === void 0 ? void 0 : _a.forcedAmountComment) !== null && _b !== void 0 ? _b : null;
    }
    set forcedAmountComment(forcedAmountComment) {
        if (!this.initialClaim)
            return;
        this.updateDraftPayload({
            id: this.initialClaim.id,
            counting: {
                id: this.initialClaim.counting.id,
                forcedAmountComment,
            },
        });
    }
    get isDetailsRow() {
        var _a;
        return (_a = this.cells[0]) === null || _a === void 0 ? void 0 : _a.name.endsWith('.details');
    }
    // ------------------------ CELLS CHARACTERISTICS
    isExclusionCell(cell) {
        return (this.rowHasExclusionReason &&
            this.cellType(cell.data.columnName) === ClaimCompensationColumnName.CompensationType);
    }
    isTotalForcedDelayedCell(cell) {
        return cell.name === ClaimCompensationCellName.TotalsSectionCompensationForcedDelayed;
    }
    isTotalForcedDeductibleCell(cell) {
        return cell.name === ClaimCompensationCellName.TotalsSectionDeductibleForcedImmediate;
    }
    isRefundAppliedAmountsCell(cell) {
        return cell.name === ClaimCompensationCellName.TotalsSectionCompensationForcedTotal;
    }
    isCompensationModeCell(cell) {
        var _a;
        return ((_a = cell === null || cell === void 0 ? void 0 : cell.data) === null || _a === void 0 ? void 0 : _a.compensationModeDescription) || null;
    }
    isForcedAmountsCommentCell(cell) {
        return cell.name === 'totals-section.compensation.forced.comment.value' || null;
    }
    isForcedInputCell(cell) {
        return (this.isTotalsForcedImmediateCell(cell) ||
            this.isTotalForcedDelayedCell(cell) ||
            this.isTotalForcedDeductibleCell(cell));
    }
    compensationModeHasBeenForced(cell) {
        var _a;
        return ((_a = cell === null || cell === void 0 ? void 0 : cell.data) === null || _a === void 0 ? void 0 : _a.compensationModeHasBeenForced) || null;
    }
    displayValue(cell) {
        if (cell.type === 'amount' && cell.data.columnName === 'damage.ceiling') {
            return cell.displayValue;
        }
        return cell.displayValue.replace(/\n\n/g, '<br />');
    }
    // ------------------------ ROW CHARACTERISTICS
    get rowHasExclusionReason() {
        var _a;
        for (const cell of this.cells) {
            if ((_a = cell.data) === null || _a === void 0 ? void 0 : _a.exclusionReason) {
                return true;
            }
        }
        return false;
    }
    get rowHasForcedAmountsSubRows() {
        var _a, _b, _c, _d;
        return (_d = !!((_c = (_b = (_a = this.row) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.edit) === null || _c === void 0 ? void 0 : _c.rows)) !== null && _d !== void 0 ? _d : false;
    }
    get isAllowanceSubRow() {
        return this.type === 'applied-allowance-container';
    }
    get isForcedAmountsSubRow() {
        var _a, _b, _c, _d;
        return this.type === 'footer' && ((_d = !!((_c = (_b = (_a = this.row) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.edit) === null || _c === void 0 ? void 0 : _c.rows)) !== null && _d !== void 0 ? _d : false);
    }
    get isCustomDamageRow() {
        var _a, _b;
        return ((_b = (_a = this.row) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.damageType) === ApiEntityType.CustomDamage;
    }
    get isCustomDamagesIntermediateResultsRow() {
        var _a;
        return (((_a = this.row) === null || _a === void 0 ? void 0 : _a.type) === 'intermediate-result' && this.row.cells[0].name.endsWith('sub-total.label'));
    }
    get allowanceData() {
        return this.isAllowanceSubRow ? this.cells[0].data : null;
    }
    get compensationChoices() {
        return COMPENSATION_MODE_CHOICES.map((choice) => ({
            value: choice.value,
            label: this.$tc(choice.tradKey),
            shortLabel: this.$tc(choice.shortTradKey),
        }));
    }
    computedCells(cells, rowType) {
        return cells
            .map((cell, index) => {
            const computedCell = { ...cell };
            if (cell.data.cellSkipable) {
                return null;
            }
            if (index === 0) {
                computedCell.scope = 'row';
            }
            if (this.getCellClasses(cell, rowType)) {
                computedCell.class = this.getCellClasses(cell, rowType);
            }
            return computedCell;
        })
            .filter((cell) => cell !== null);
    }
    isShowDetailsCell(cell) {
        var _a;
        return !!((_a = cell.data) === null || _a === void 0 ? void 0 : _a.showDetails);
    }
    isTotalsForcedImmediateCell(cell) {
        return cell.name === ClaimCompensationCellName.TotalsSectionCompensationForcedImmediate;
    }
    isTotalsSectionCompensationForcedTotalCell(cell) {
        return cell.name === ClaimCompensationCellName.TotalsSectionCompensationForcedTotal;
    }
    isTotalsSectionLaborAndMaterialTotalAmountsCommentCell(cell) {
        return cell.name === 'totals-section.compensation.labor.and.material.amounts.comment.value';
    }
    uniqueKey() {
        return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    }
    addDamageLine() {
        if (!this.initialClaim) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
        else {
            let damagesToDraft;
            if (this.draftCustomDamages.length > 0) {
                damagesToDraft = structuredClone(this.draftCustomDamages);
            }
            else {
                damagesToDraft = structuredClone(this.initialCustomDamages);
            }
            damagesToDraft.push({
                type: this.tableType,
                description: '',
                amount: 0,
                '@id': this.uniqueKey(),
            });
            this.updateDraftPayload({
                id: this.initialClaim.id,
                counting: {
                    id: this.initialClaim.counting.id,
                    customDamages: damagesToDraft,
                },
            });
        }
    }
    removeDamageLine(id) {
        if (!this.initialClaim) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
        else {
            let damagesToDraft;
            if (this.draftCustomDamages.length === 0) {
                const damages = [...this.initialCustomDamages];
                const index = damages.findIndex((damage) => damage.id === id);
                if (index !== -1) {
                    damages.splice(index, 1);
                }
                damagesToDraft = damages;
            }
            else {
                const damages = [...this.draftCustomDamages];
                const index = damages.findIndex((damage) => {
                    var _a;
                    const damageId = (_a = damage.id) !== null && _a !== void 0 ? _a : damage['@id'];
                    return damageId === id;
                });
                if (index !== -1) {
                    damages.splice(index, 1);
                }
                damagesToDraft = [...damages];
            }
            this.updateDraftPayload({
                id: this.initialClaim.id,
                counting: {
                    id: this.initialClaim.counting.id,
                    customDamages: damagesToDraft,
                },
            });
        }
    }
    async handleCustomDamages(id, userInputDamage) {
        if (!this.initialClaim) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
            return;
        }
        if (userInputDamage.description === '' && userInputDamage.amount === 0) {
            return;
        }
        let damagesToDraft;
        const { description, amount } = userInputDamage;
        if (this.draftCustomDamages.length > 0) {
            damagesToDraft = structuredClone(this.draftCustomDamages);
        }
        else {
            damagesToDraft = structuredClone(this.initialCustomDamages);
        }
        const index = damagesToDraft.findIndex((damage) => {
            var _a;
            const damageId = (_a = damage.id) !== null && _a !== void 0 ? _a : damage['@id'];
            return damageId === id;
        });
        if (index !== -1) {
            damagesToDraft[index].description = description;
            damagesToDraft[index].amount = amount;
        }
        await this.updateDraftPayload({
            id: this.initialClaim.id,
            counting: {
                id: this.initialClaim.counting.id,
                customDamages: damagesToDraft,
            },
        });
    }
    get isShowDetailsRow() {
        var _a;
        let isShow = false;
        for (const cell of this.cells) {
            if (((_a = cell.data) === null || _a === void 0 ? void 0 : _a.showDetails) === true) {
                isShow = true;
            }
        }
        return isShow;
    }
    get appliedAllowance() {
        if (this.type === 'applied-allowance-container') {
            return { ...this.cells[0].data };
        }
        return null;
    }
    get toggleIcon() {
        return this.active ? 'chevron-down' : 'chevron-right';
    }
    cellType(columnName = 'default') {
        const shortName = columnName.match(/[^.]+\.(.+)/);
        return shortName ? shortName[1] : columnName;
    }
    getCellClasses(cell, rowType) {
        const { columnName } = cell.data;
        const cssClasses = [];
        /// specific columnName
        const cssType = 'damage-table-column__' + columnName.split('.').pop();
        cssClasses.push(cssType);
        if (cell.data.isRelatedToMinimumCharges) {
            cssClasses.push('compensation-table-cell__isRelatedToMinimumCharges');
        }
        if (this.isShowDetailsCell(cell)) {
            cssClasses.push('is-clickable');
        }
        if (this.rowHasExclusionReason) {
            cssClasses.push('has-text-danger');
        }
        if (this.cellType(columnName) === 'exclusion-reason') {
            cssClasses.push('has-background-white-bis has-shadow-inset has-text-grey-dark pl-5');
        }
        if (rowType === 'intermediate-result' ||
            rowType === 'pre-footer' ||
            rowType === 'footer' ||
            rowType === 'applied-allowance-header' ||
            this.isTotalsSectionLaborAndMaterialTotalAmountsCommentCell(cell)) {
            cssClasses.push('has-text-weight-bold');
        }
        else {
            cssClasses.push('has-text-weight-normal');
        }
        if (rowType === 'footer' && cell.type === 'amount') {
            cssClasses.push('has-text-right');
        }
        if (this.isRefundAppliedAmountsCell(cell)) {
            cssClasses.push('compensation-table__cell compensation-table__cell--right compensation-table__cell--bold compensation-table__cell--bg-blue--strong');
        }
        if (this.isTotalsSectionLaborAndMaterialTotalAmountsCommentCell(cell)) {
            cssClasses.push('has-text-danger');
        }
        return cssClasses.join(' ');
    }
    getRowClasses(row) {
        var _a, _b;
        const cssClasses = [];
        if (this.type === 'intermediate-result') {
            cssClasses.push('has-text-weight-bold has-background-light');
        }
        if (this.type === 'default' || this.type === 'applied-minimum-charge') {
            cssClasses.push('damage-row__default');
        }
        if (this.isAllowanceSubRow) {
            cssClasses.push('has-background-white-bis has-shadow-inset');
        }
        if (this.rowHasExclusionReason) {
            cssClasses.push('has-text-danger');
        }
        if (this.isShowDetailsRow) {
            cssClasses.push('damage-row__has-details');
        }
        if (this.active) {
            cssClasses.push('active');
        }
        if (this.type === 'footer') {
            cssClasses.push('has-text-weight-bold');
        }
        if (this.type === 'post-footer') {
            cssClasses.push('damage-row__post-footer');
        }
        if (this.isDetailsRow) {
            cssClasses.push('has-background-white-bis has-shadow-inset');
        }
        if (this.isForcedAmountsSubRow) {
            if ((_b = (_a = row.data) === null || _a === void 0 ? void 0 : _a.edit) === null || _b === void 0 ? void 0 : _b.rows) {
                cssClasses.push('deductible-amount_row');
            }
            else {
                cssClasses.push('deductible-amount_sub-row');
            }
        }
        return cssClasses;
    }
    toggle(event) {
        this.active = !this.active;
        event.stopImmediatePropagation();
        return event === null || event === void 0 ? void 0 : event.preventDefault();
    }
    async forceAmount(key, amount) {
        if (!this.initialClaim) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
            return;
        }
        await this.updateDraftPayload({
            id: this.initialClaim.id,
            counting: {
                id: this.initialClaim.counting.id,
                [key]: amount,
            },
        });
    }
};
__decorate([
    claim.State('initialClaim')
], DamageTableRow.prototype, "initialClaim", void 0);
__decorate([
    claim.Getter('isClaimUpdating')
], DamageTableRow.prototype, "isClaimUpdating", void 0);
__decorate([
    claim.State('draftPayload')
], DamageTableRow.prototype, "draftPayload", void 0);
__decorate([
    claim.State('info')
], DamageTableRow.prototype, "info", void 0);
__decorate([
    claim.State('isEditingClaim')
], DamageTableRow.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], DamageTableRow.prototype, "updateDraftPayload", void 0);
DamageTableRow = __decorate([
    Component({
        components: { ForcableAmountValue, CustomDamageRowInputs },
        props: {
            row: {
                type: Object,
                required: true,
            },
            sectionType: {
                type: String,
                required: true,
                default: '',
            },
            type: {
                type: String,
                default: null,
            },
            cells: {
                type: Array,
                default: [],
                required: true,
            },
        },
        filters: {
            formatAmount,
        },
    })
], DamageTableRow);
export default DamageTableRow;
