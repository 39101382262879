import { render, staticRenderFns } from "./UpptecModelField.vue?vue&type=template&id=3e807700&scoped=true"
import script from "./UpptecModelField.vue?vue&type=script&lang=ts"
export * from "./UpptecModelField.vue?vue&type=script&lang=ts"
import style0 from "./UpptecModelField.vue?vue&type=style&index=0&id=3e807700&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e807700",
  null
  
)

export default component.exports