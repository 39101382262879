var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import ContractForm from '@backoffice/contract/ContractForm.vue';
import { ApiResourceName } from '@shared/types/api/api';
import { uuidFromIri } from '@shared/utils/iri';
import { fetchResource } from '@backoffice/utils/api';
let NouveauContrat = class NouveauContrat extends Vue {
    constructor() {
        super(...arguments);
        this.contractForm = {
            insuranceContract: '',
            housingCapacity: '',
            housingType: '',
            contractNumber: '',
            formula: '',
            generalDeductibleAmount: 0,
            productLine: 0,
            address: '',
            zipcode: '',
            city: '',
            rooms: 0,
            livingArea: 0,
        };
        this.isCreatingContract = false;
    }
    get title() {
        if (!this.user)
            return this.$tc('pages.contract.title');
        return `${this.$tc('pages.contract.title_custom')} ${this.user.firstname} ${this.user.lastname}`;
    }
    get userId() {
        return uuidFromIri(this.$route.params.id);
    }
    cancel() {
        this.$goToLocalePath({ name: 'utilisateurs-id', params: { id: this.userId } });
    }
    async createContract() {
        const payload = { ...this.contractForm, user: this.userId };
        try {
            this.isCreatingContract = true;
            const created = await this.$axios.$post('/contract_creation', payload);
            if (created.type === 'validation_error')
                throw new Error(created.errors[0]);
            this.$buefy.toast.open({
                message: this.$tc('pages.contract.contract_created'),
                type: 'is-success',
                position: 'is-bottom',
            });
            await this.$router.push(this.localePath({ name: 'utilisateurs-id', params: { id: this.userId } }));
        }
        catch (err) {
            if (err instanceof Error) {
                this.$buefy.toast.open({
                    message: err.message,
                    type: 'is-danger',
                    position: 'is-bottom',
                });
            }
        }
        finally {
            this.isCreatingContract = false;
        }
    }
};
NouveauContrat = __decorate([
    Component({
        middleware: 'hasAccess',
        components: { ContractForm },
        async asyncData({ $axios, params }) {
            const { data: user } = await fetchResource(ApiResourceName.Users, params.id, $axios);
            return { user };
        },
    })
], NouveauContrat);
export default NouveauContrat;
