var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import moment from 'moment';
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { ApiResourceName } from '@shared/types/api/api';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
import { uuidFromIri } from '@shared/utils/iri';
const damagesValuations = namespace('damagesValuations');
const departments = namespace('departments');
const productLines = namespace('productLines');
let ChiffragesIndex = class ChiffragesIndex extends Vue {
    constructor() {
        super(...arguments);
        this.searchable = false;
        this.isModalOpen = false;
        this.isCreating = false;
        this.valuationForm = {
            title: '',
            department: '',
            productLine: '',
        };
    }
    onPageChange(page) {
        this.changePage(page);
    }
    onFilterChange(filter) {
        this.filter(filter);
    }
    onSort(by, direction) {
        this.sort({ by, direction });
    }
    onClick(valuation) {
        const uuid = uuidFromIri(valuation.id);
        this.$router.push(this.localePath({ name: 'chiffrages-valuation', params: { valuation: uuid } }));
    }
    resetForm() {
        this.valuationForm.title = '';
        this.valuationForm.department = '';
        this.valuationForm.productLine = '';
        this.isModalOpen = false;
    }
    async createValuation() {
        this.isCreating = true;
        try {
            const valuation = await this.$axios.$post(apiResourcePath(ApiResourceName.DamagesValuations), this.valuationForm);
            this.$router.push(this.localePath({
                name: 'chiffrages-valuation',
                params: { valuation: uuidFromIri(valuation.id) },
            }));
            this.resetForm();
        }
        catch (err) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
        finally {
            this.isCreating = false;
        }
    }
};
__decorate([
    damagesValuations.State('valuations')
], ChiffragesIndex.prototype, "valuations", void 0);
__decorate([
    damagesValuations.State('loading')
], ChiffragesIndex.prototype, "loading", void 0);
__decorate([
    damagesValuations.State('total')
], ChiffragesIndex.prototype, "total", void 0);
__decorate([
    damagesValuations.State('page')
], ChiffragesIndex.prototype, "page", void 0);
__decorate([
    damagesValuations.State('filters')
], ChiffragesIndex.prototype, "filters", void 0);
__decorate([
    damagesValuations.State('sorting')
], ChiffragesIndex.prototype, "sorting", void 0);
__decorate([
    damagesValuations.Getter('perPage')
], ChiffragesIndex.prototype, "perPage", void 0);
__decorate([
    departments.Getter('departmentsChoices')
], ChiffragesIndex.prototype, "departmentsChoices", void 0);
__decorate([
    productLines.Getter('productLinesChoices')
], ChiffragesIndex.prototype, "productLinesChoices", void 0);
__decorate([
    damagesValuations.Action('changePage')
], ChiffragesIndex.prototype, "changePage", void 0);
__decorate([
    damagesValuations.Action('sort')
], ChiffragesIndex.prototype, "sort", void 0);
__decorate([
    damagesValuations.Action('filter')
], ChiffragesIndex.prototype, "filter", void 0);
ChiffragesIndex = __decorate([
    Component({
        name: 'ChiffragesIndex',
        middleware: ['hasAccess', 'departments', 'productLines'],
        filters: {
            createdAt(value) {
                const date = moment(value);
                return date.format('DD/MM/YYYY');
            },
            creator(value) {
                return value.firstname + ' ' + value.lastname;
            },
        },
        async fetch() {
            await Promise.all([
                this.$store.dispatch('departments/loadDepartments'),
                this.$store.dispatch('productLines/loadProductLines'),
                this.$store.dispatch('damagesValuations/loadValuations'),
            ]);
        },
    })
], ChiffragesIndex);
export default ChiffragesIndex;
