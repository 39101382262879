var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ModelForm from '@backoffice/components/claim/ModelForm.vue';
import Modal from '@backoffice/components/layout/Modal.vue';
import { ApiResourceNameForDamageRouteName, DamageRouteName, } from '@backoffice/damagesValuation/helpers';
import { breakInDamageFields } from '@shared/breakInDamage/breakInDamageFields';
import { externalDamageFields } from '@shared/externalDamage/externalDamageFields';
import { ModelFieldsProjection } from '@shared/modelField/modelField';
import { personalPropertyFields } from '@shared/personalProperty/personalPropertyFields';
import { roomDamageFields } from '@shared/roomDamage/roomDamageFields';
import { EMPTY_COVERING_CATEGORIES_BY_SURFACE_TYPE, } from '@shared/roomDamage/surfaceDamage';
import { ApiResourceName } from '@shared/types/api/api';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import { ClaimTypeTrigram, } from '@shared/types/api/claim';
import { indexBy } from '@shared/utils/indexBy';
import { uuidFromIri } from '@shared/utils/iri';
import { iriFromId } from '@shared/utils/IriFromId';
const damagesValuation = namespace('damagesValuation');
const DAMAGE_FACTORY = {
    [DamageRouteName.PersonalProperty]: () => ({
        '@type': ApiEntityType.PersonalProperty,
        age: null,
    }),
    [DamageRouteName.RoomDamage]: () => ({
        '@type': ApiEntityType.RoomDamage,
    }),
    [DamageRouteName.ExternalDamage]: () => ({
        '@type': ApiEntityType.ExternalDamage,
    }),
    [DamageRouteName.BreakInDamage]: () => ({
        '@type': ApiEntityType.DoorBreakInDamage,
    }),
};
let DamagesValuationDamage = class DamagesValuationDamage extends Vue {
    constructor() {
        super(...arguments);
        this.isDeleting = false;
        this.damage = null;
        this.isLoadingData = true;
        this.iri = null;
        this.apiResourceName = null;
        this.personalPropertyCategories = null;
        this.coveringCategories = null;
        this.coveringCategoriesBySurfaceType = EMPTY_COVERING_CATEGORIES_BY_SURFACE_TYPE;
    }
    async created() {
        const damageRouteName = this.$route.params.damage;
        const valuationUuid = this.$route.params.valuation;
        const damageKey = this.$route.params.key;
        this.apiResourceName = ApiResourceNameForDamageRouteName[damageRouteName];
        this.selectDamagesValuation(valuationUuid);
        this.iri = damageKey === 'create' ? null : damageKey;
        const [damage, personalPropertyCategories, coveringCategories] = await Promise.all([
            this.iri === null
                ? DAMAGE_FACTORY[damageRouteName]()
                : this.$axios.$get(iriFromId(this.apiResourceName, damageKey)),
            this.$axios
                .$get('/personal_property_categories')
                .then((response) => response['hydra:member'].sort())
                .then((categories) => indexBy(categories, 'id')),
            this.$axios
                .$get('/covering_categories')
                .then((response) => Object.fromEntries(response['hydra:member'].map(({ id, label }) => [id, label]))),
        ]);
        this.damage = damage;
        this.personalPropertyCategories = personalPropertyCategories;
        this.coveringCategories = coveringCategories;
        this.isLoadingData = false;
    }
    get canSave() {
        return !this.isSaving && !this.isLoading;
    }
    get modelFieldsAndContext() {
        const baseContext = {
            type: ClaimTypeTrigram.Empty,
            translate: this.$tc.bind(this),
            projection: ModelFieldsProjection.Valuation,
            edition: true,
        };
        if (!this.personalPropertyCategories || !this.coveringCategories) {
            return null;
        }
        /* eslint-disable no-case-declarations */
        switch (this.apiResourceName) {
            case ApiResourceName.PersonalProperties:
                const personalPropertyContext = {
                    ...baseContext,
                    categories: this.personalPropertyCategories,
                };
                return {
                    modelFields: personalPropertyFields(this.damage, personalPropertyContext),
                    context: personalPropertyContext,
                };
            case ApiResourceName.BreakInDamages:
                return {
                    modelFields: breakInDamageFields(this.damage, baseContext),
                    context: baseContext,
                };
            case ApiResourceName.ExternalDamages:
                // TS looses types on external damages
                return {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    modelFields: externalDamageFields(this.damage, {
                        ...baseContext,
                        stormSnowHailDamageCauseType: null,
                    }),
                    context: baseContext,
                };
            case ApiResourceName.RoomDamages:
                const roomDamagesContext = {
                    ...baseContext,
                    coveringCategoryLabels: this.coveringCategories,
                    coveringCategoriesBySurfaceType: this.coveringCategoriesBySurfaceType,
                };
                return {
                    modelFields: roomDamageFields(this.damage, roomDamagesContext),
                    context: roomDamagesContext,
                };
            default:
                throw new Error('Api model fields getter not implemented in damage valuation');
        }
        /* eslint-enable no-case-declarations */
    }
    goBack() {
        if (!this.valuation) {
            this.$router.push('/chiffrages');
            return;
        }
        this.$router.push(`/chiffrages/${uuidFromIri(this.valuation.id)}`);
    }
    onSave() {
        this.save({});
    }
    onCancel() {
        this.goBack();
    }
    confirmDeletion() {
        this.$buefy.dialog.confirm({
            title: 'Supprimer le dommage ?',
            message: 'Les données de ce dommage seront définitivement effacées.',
            onConfirm: async () => {
                this.isDeleting = true;
                try {
                    if (!this.iri) {
                        this.goBack();
                        return;
                    }
                    await this.$axios.$delete(this.iri);
                    this.goBack();
                    this.$buefy.toast.open({
                        message: 'Le dommage a bien été supprimé',
                        type: 'is-info',
                        position: 'is-bottom',
                    });
                }
                catch (err) {
                    this.isDeleting = false;
                    this.$buefy.toast.open({
                        message: 'Une erreur est survenue, merci de réessayer.',
                        type: 'is-danger',
                        position: 'is-bottom',
                    });
                }
            },
            confirmText: 'Supprimer',
            cancelText: 'Annuler',
        });
    }
};
__decorate([
    damagesValuation.State('valuation')
], DamagesValuationDamage.prototype, "valuation", void 0);
__decorate([
    damagesValuation.State('departments')
], DamagesValuationDamage.prototype, "departments", void 0);
__decorate([
    damagesValuation.State('productLines')
], DamagesValuationDamage.prototype, "productLines", void 0);
__decorate([
    damagesValuation.State('id')
], DamagesValuationDamage.prototype, "id", void 0);
__decorate([
    damagesValuation.State('isLoading')
], DamagesValuationDamage.prototype, "isLoading", void 0);
__decorate([
    damagesValuation.State('error')
], DamagesValuationDamage.prototype, "error", void 0);
__decorate([
    damagesValuation.State('isSaving')
], DamagesValuationDamage.prototype, "isSaving", void 0);
__decorate([
    damagesValuation.Action('selectDamagesValuation')
], DamagesValuationDamage.prototype, "selectDamagesValuation", void 0);
__decorate([
    damagesValuation.Action('update')
], DamagesValuationDamage.prototype, "update", void 0);
__decorate([
    damagesValuation.Action('save')
], DamagesValuationDamage.prototype, "save", void 0);
DamagesValuationDamage = __decorate([
    Component({
        middleware: ['hasAccess', 'departments', 'productLines'],
        components: {
            Modal,
            ModelForm,
        },
    })
], DamagesValuationDamage);
export default DamagesValuationDamage;
