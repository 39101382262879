var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
const insuranceContracts = namespace('insuranceContracts');
const housingCapacities = namespace('housingCapacities');
const housingTypes = namespace('housingTypes');
const productLines = namespace('productLines');
let ContractForm = class ContractForm extends Vue {
    get form() {
        return this.contract;
    }
    set form(form) {
        this.$emit('update:contract', { ...this.contract, ...form });
    }
    get isDisabled() {
        return this.disabled || this.$fetchState.pending || this.$fetchState.error;
    }
};
__decorate([
    insuranceContracts.Getter('insuranceContractsChoices')
], ContractForm.prototype, "insuranceContractsChoices", void 0);
__decorate([
    housingCapacities.Getter('housingCapacitiesChoices')
], ContractForm.prototype, "housingCapacitiesChoices", void 0);
__decorate([
    housingTypes.Getter('housingTypesChoices')
], ContractForm.prototype, "housingTypesChoices", void 0);
__decorate([
    productLines.Getter('productLinesChoices')
], ContractForm.prototype, "productLinesChoices", void 0);
ContractForm = __decorate([
    Component({
        props: {
            contract: {
                type: Object,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        async fetch() {
            await Promise.all([
                this.$store.dispatch('insuranceContracts/loadInsuranceContracts'),
                this.$store.dispatch('housingCapacities/loadHousingCapacities'),
                this.$store.dispatch('housingTypes/loadHousingTypes'),
                this.$store.dispatch('productLines/loadProductLines'),
            ]);
        },
    })
], ContractForm);
export default ContractForm;
