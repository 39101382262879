var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ContractHousingCapacityCode } from '@shared/types/api/contract';
import { UserRole } from '@shared/types/api/user';
import { uuidFromIri } from '@shared/utils/iri';
const claim = namespace('claim');
let DeclarationPrimingPage = class DeclarationPrimingPage extends Vue {
    async mounted() {
        await this.fetchPriming();
    }
    get primingId() {
        return this.priming ? uuidFromIri(this.priming.id) : null;
    }
    get isAdmin() {
        return this.$hasAccessLevel(UserRole.ADMIN);
    }
    isDefined(value) {
        return typeof value !== 'undefined' && value !== null;
    }
    translateTrigram(trigram) {
        const key = `entities.claim_type.trigram.${trigram}.label`;
        return this.$t(key);
    }
    translateTitle(title) {
        var _a;
        const realTitle = Number.isNaN(Number(title))
            ? title
            : (_a = {
                '1': 'mister',
                '2': 'miss',
            }[title]) !== null && _a !== void 0 ? _a : title;
        const key = `pages.declarations_id_priming.insured.field.title.value.${realTitle}`;
        return this.$te(key) ? this.$t(key) : realTitle;
    }
    translateStatus(status) {
        var _a;
        const realStatus = Number.isNaN(Number(status))
            ? status
            : (_a = {
                '1': ContractHousingCapacityCode.OwnerOccupier,
                '2': ContractHousingCapacityCode.OwnerNonOccupier,
                '3': ContractHousingCapacityCode.CoOwnerOccupier,
                '4': ContractHousingCapacityCode.CoOwnerNonOccupier,
                '5': ContractHousingCapacityCode.Tenant,
                '7': ContractHousingCapacityCode.Tenant,
                '10': ContractHousingCapacityCode.FreeOfChargeOccupier,
                '14': ContractHousingCapacityCode.Usufruct,
                '15': ContractHousingCapacityCode.BareOwnership,
            }[status]) !== null && _a !== void 0 ? _a : status;
        const key = `entities.housing_capacity.code.${realStatus}.label`;
        return this.$te(key) ? this.$t(key) : realStatus;
    }
    translateHabitation(habitation) {
        const key = `pages.declarations_id_priming.risk.field.habitation.value.${habitation}`;
        return this.$te(key) ? this.$t(key) : habitation;
    }
    translateResidence(residence) {
        const key = `pages.declarations_id_priming.risk.field.residence.value.${residence}`;
        return this.$te(key) ? this.$t(key) : residence;
    }
    translateBoolean(value) {
        return (value ? this.$t('yes') : this.$t('no'));
    }
};
__decorate([
    claim.State('priming')
], DeclarationPrimingPage.prototype, "priming", void 0);
__decorate([
    claim.Action('fetchPriming')
], DeclarationPrimingPage.prototype, "fetchPriming", void 0);
DeclarationPrimingPage = __decorate([
    Component({
        middleware: 'hasAccess',
        nuxtI18n: {
            paths: {
                en: '/claims/:id/priming',
            },
        },
    })
], DeclarationPrimingPage);
export default DeclarationPrimingPage;
