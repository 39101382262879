var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { translateChoices } from '@shared/choice';
import { CHARGE_TYPOLOGY_CHOICES } from '@shared/charges/chargeConstants';
import { newClientIdentifier } from '@shared/utils/clientIdentifier';
import { optionalAmountToCents, optionalCentsToAmount } from '@shared/utils/amountToCents';
let ChargeModal = class ChargeModal extends Vue {
    constructor() {
        super(...arguments);
        this.clientIdentifier = null;
        this.form = {
            typology: null,
            description: null,
            amount: null,
        };
    }
    get typologyChoices() {
        return translateChoices(CHARGE_TYPOLOGY_CHOICES, this.$i18n.tc.bind(this.$i18n));
    }
    mounted() {
        if (this.initialCharge) {
            this.clientIdentifier = this.initialCharge.clientIdentifier;
            this.form.typology = this.initialCharge.typology;
            this.form.description = this.initialCharge.description;
            this.form.amount = optionalCentsToAmount(this.initialCharge.amount);
        }
        else {
            this.clientIdentifier = newClientIdentifier();
        }
    }
    confirm() {
        this.$emit('confirm', {
            clientIdentifier: this.clientIdentifier,
            typology: this.form.typology,
            description: this.form.description,
            amount: optionalAmountToCents(this.form.amount),
        });
    }
    close() {
        this.$emit('close');
    }
};
ChargeModal = __decorate([
    Component({
        props: {
            initialCharge: {
                type: Object,
                default: null,
            },
        },
    })
], ChargeModal);
export default ChargeModal;
