var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import FileCollectionForm from '@backoffice/components/file/FileCollectionForm.vue';
import { isFileCollectionModelField, isFileModelField, ModelFieldInputType, } from '@shared/modelField/modelField';
import { dateToISOLocaleDay, dateToISOLocaleDayAndTime, optionalValueToDate, } from '@shared/utils/date';
import { EMPTY } from '@shared/utils/strings';
let ModelFormField = class ModelFormField extends Vue {
    constructor() {
        super(...arguments);
        this.empty = EMPTY;
        this.ModelFieldInputType = ModelFieldInputType;
        this.internalValue = null;
        this.submittedValue = null;
        // Only way to make multiple checkbox buefy integration work
        this.multipleValue = [];
    }
    created() {
        this.$watch('value', (value) => {
            this.internalValue = value;
            this.submittedValue = value;
            this.multipleValue = value;
        }, { immediate: true });
        this.$watch('multipleValue', (multipleValue) => {
            if (this.multipleValue === this.internalValue) {
                return;
            }
            this.onInput(multipleValue);
            this.onBlur();
        });
    }
    get label() {
        var _a, _b, _c;
        if (this.inline) {
            return null;
        }
        const labelTitle = (_b = (_a = this.editionConfiguration) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : this.field.label;
        if ((_c = this.editionConfiguration) === null || _c === void 0 ? void 0 : _c.unit) {
            return `${labelTitle} (${this.editionConfiguration.unit})`;
        }
        return labelTitle;
    }
    get value() {
        const editionConfiguration = this.editionConfiguration;
        if (!editionConfiguration) {
            return this.field.value;
        }
        if (editionConfiguration.input.type === ModelFieldInputType.Amount) {
            return this.field.value === null ? '' : parseInt(this.field.value) / 100;
        }
        if (editionConfiguration.input.type === ModelFieldInputType.Number) {
            return this.field.value === null ? '' : this.field.value;
        }
        return this.field.value;
    }
    get ageYearsValue() {
        if (typeof this.internalValue !== 'number')
            return 0;
        if (this.ageInYears)
            return this.internalValue;
        else
            return Math.floor(this.internalValue / 12);
    }
    set ageYearsValue(ageYearsValue) {
        if (this.ageInYears)
            this.onInput(ageYearsValue);
        else
            this.onInput(this.ageMonthsValue + ageYearsValue * 12);
    }
    get ageMonthsValue() {
        if (typeof this.internalValue !== 'number' || this.ageInYears)
            return 0;
        else
            return this.internalValue % 12;
    }
    set ageMonthsValue(ageMonthsValue) {
        if (!this.ageInYears)
            this.onInput(ageMonthsValue + this.ageYearsValue * 12);
    }
    get ageInYears() {
        var _a, _b;
        if (((_a = this.editionConfiguration) === null || _a === void 0 ? void 0 : _a.input.type) === ModelFieldInputType.Age) {
            return !!((_b = this.editionConfiguration) === null || _b === void 0 ? void 0 : _b.input.yearsOnly);
        }
        return false;
    }
    get dateValue() {
        if (!this.internalValue) {
            return null;
        }
        return this.dateToHtmlAttr(optionalValueToDate(this.internalValue));
    }
    get dateMin() {
        return ((this.field.editable &&
            'min' in this.field.editable.input &&
            this.dateToHtmlAttr(optionalValueToDate(this.field.editable.input.min))) ||
            null);
    }
    get dateMax() {
        return ((this.field.editable &&
            'max' in this.field.editable.input &&
            this.dateToHtmlAttr(optionalValueToDate(this.field.editable.input.max))) ||
            null);
    }
    dateToHtmlAttr(date) {
        var _a, _b;
        if (!date) {
            return null;
        }
        if (((_a = this.field.editable) === null || _a === void 0 ? void 0 : _a.input.type) === ModelFieldInputType.DateTime) {
            return dateToISOLocaleDayAndTime(date);
        }
        if (((_b = this.field.editable) === null || _b === void 0 ? void 0 : _b.input.type) === ModelFieldInputType.Date) {
            return dateToISOLocaleDay(date);
        }
        return null;
    }
    get inputType() {
        const editionConfiguration = this.editionConfiguration;
        if (!editionConfiguration) {
            return 'text';
        }
        if (editionConfiguration.input.type === ModelFieldInputType.Amount) {
            return 'number';
        }
        return editionConfiguration.input.type;
    }
    get isFile() {
        return isFileModelField(this.field);
    }
    get isFileCollection() {
        return isFileCollectionModelField(this.field);
    }
    get fileCollectionDisplayedValue() {
        return this.field.displayedValue;
    }
    get editionConfiguration() {
        var _a;
        return (_a = this.field.editable) !== null && _a !== void 0 ? _a : null;
    }
    get isDisabled() {
        var _a;
        return this.disabled || !!((_a = this.field.editable) === null || _a === void 0 ? void 0 : _a.disabled);
    }
    get validationFieldType() {
        if (!this.validation ||
            !this.editionConfiguration ||
            this.validation.fieldIsValid(this.editionConfiguration.modelKey)) {
            return '';
        }
        return 'is-danger';
    }
    get validationErrorMessages() {
        if (!this.validation || !this.editionConfiguration) {
            return null;
        }
        return this.validation.fieldErrors(this.editionConfiguration.modelKey, this);
    }
    onCheckboxInput(value) {
        this.onInput(value);
        this.onBlur();
    }
    onSelectInput(value) {
        this.onInput(value);
        this.onBlur();
    }
    onDatepickerInput(value) {
        this.onInput(value);
        this.onBlur();
    }
    onInput(value) {
        var _a;
        const editionConfiguration = this.editionConfiguration;
        if (!editionConfiguration) {
            this.update(value);
            return;
        }
        const castTo = (_a = ('castTo' in editionConfiguration.input && editionConfiguration.input.castTo)) !== null && _a !== void 0 ? _a : null;
        if (editionConfiguration.input.type === ModelFieldInputType.Checkbox) {
            return this.update(!!value);
        }
        if (castTo === 'boolean') {
            if (typeof value === 'string') {
                return this.update(value === 'true');
            }
            return this.update(!!value);
        }
        if (editionConfiguration.input.type === ModelFieldInputType.Number || castTo === 'number') {
            return this.update(value.trim() === '' ? null : parseFloat(value));
        }
        if (editionConfiguration.input.type === ModelFieldInputType.Amount) {
            return this.update(value * 100);
        }
        // TODO Handle date / dateTime / time
        this.update(value);
    }
    onFileCollectionUpdate(event) {
        this.update(event);
        this.submit();
    }
    onBlur() {
        if (this.submittedValue !== this.internalValue) {
            this.submit();
        }
    }
    update(value) {
        this.internalValue = value;
        this.$emit('update', value);
    }
    submit() {
        this.submittedValue = this.internalValue;
        this.$emit('submit', this.internalValue);
    }
};
ModelFormField = __decorate([
    Component({
        components: { FileCollectionForm },
        props: {
            field: {
                type: Object,
                required: true,
            },
            inline: {
                type: Boolean,
                required: false,
                default: false,
            },
            validation: {
                type: Object,
                required: false,
                default: null,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            isRequired: {
                type: Boolean,
                default: false,
            },
        },
    })
], ModelFormField);
export default ModelFormField;
