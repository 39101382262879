var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import formatOptionalAmount from '@shared/utils/formatOptionalAmount';
import { EMPTY } from '@shared/utils/strings';
let Index = class Index extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.result = null;
        this.uploadFile = null;
        this.displayRawData = false;
        this.EMPTY = EMPTY;
    }
    get uploadMessage() {
        return this.isLoading
            ? this.$tc('pages.document.ongoing_analysis')
            : this.$tc('pages.document.prompt_for_analysis');
    }
    get canDisplayResult() {
        var _a;
        return !((_a = this.result) === null || _a === void 0 ? void 0 : _a.analysis.result.error);
    }
    get uploadIcon() {
        return this.isLoading ? 'timer' : 'file';
    }
    get checks() {
        if (!this.result || !('checkResult' in this.result.analysis.result)) {
            return [];
        }
        return this.result.analysis.result.checkResult.checks;
    }
    get dataSections() {
        if (!this.result || !this.result.analysis.result.data) {
            return [];
        }
        const { client, company, date, docRef, refSinistre, totalPrice, type, typeSinistre } = this.result.analysis.result.data;
        return [
            {
                title: 'Général',
                values: [
                    { label: 'Date', value: date },
                    { label: 'Document', value: type },
                    { label: 'Ref. document', value: docRef },
                    { label: 'Type sinistre', value: typeSinistre },
                    { label: 'Ref. sinistre', value: refSinistre },
                    { label: 'Prix total', value: formatOptionalAmount(totalPrice) },
                ],
            },
            {
                title: 'Client',
                values: [
                    { label: 'Nom', value: client.name },
                    { label: 'Adresse', value: client.address },
                    { label: 'Email', value: client.email },
                    { label: 'Téléphone', value: client.phone },
                    { label: 'Code postal', value: client.zipcode },
                ],
            },
            {
                title: 'Entreprise',
                values: [
                    { label: 'Nom', value: company.name },
                    { label: 'Adresse', value: company.address },
                    { label: 'Email', value: company.email },
                    { label: 'Téléphone', value: company.phone },
                    { label: 'Fax', value: company.fax },
                    { label: 'Code postal', value: company.zipcode },
                    { label: 'APE', value: company.ape },
                    { label: 'RCS', value: company.rcs },
                    { label: 'SIRET', value: company.siret },
                    { label: 'Site web', value: company.website },
                ],
            },
        ];
    }
    async onFileInput(file) {
        if (!file || this.isLoading)
            return;
        const formData = new FormData();
        formData.append('file', file);
        try {
            this.isLoading = true;
            const analysisResponse = await this.$axios
                .$post('/analysis/document/polyexpert', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
                .catch((err) => ({
                analysis: {
                    duration: 0,
                    result: { error: err.message, data: null, plainText: null },
                },
            }));
            this.isLoading = false;
            this.result = { file, filename: file.name, analysis: analysisResponse.analysis };
            this.uploadFile = null;
        }
        catch (err) {
            this.isLoading = false;
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer',
                type: 'is-danger',
                position: 'is-bottom',
            });
            this.uploadFile = null;
        }
    }
    onOpenFile() {
        var _a;
        if (!((_a = this.result) === null || _a === void 0 ? void 0 : _a.file))
            return;
        const url = URL.createObjectURL(this.result.file);
        window.open(url, '_blank');
    }
};
Index = __decorate([
    Component({ middleware: 'hasAccess' })
], Index);
export default Index;
