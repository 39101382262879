var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { UserRole } from '@shared/types/api/user';
let Index = class Index extends Vue {
    mounted() {
        if (this.$hasAccessLevel(UserRole.MANAGER_READONLY)) {
            return this.$goToLocalePath({ name: 'declarations' });
        }
        if (this.$hasAccessLevel(UserRole.BACKOFFICE_TOOL_PRODUCTS)) {
            return this.$goToLocalePath({ name: 'services-produits' });
        }
        this.$buefy.toast.open({
            message: this.$tc('snackbar.error.not_granted'),
            type: 'is-danger',
            position: 'is-bottom',
        });
    }
};
Index = __decorate([
    Component
], Index);
export default Index;
